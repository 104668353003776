import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import { Form, Button, Header, Card, Checkbox, Popup, Input, Icon } from 'semantic-ui-react';
import InlineError from '../../messages/InlineError';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { login } from '../../../actions/auth';
import 'react-semantic-toasts/styles/react-semantic-alert.css';
import Toast from '../../messages/Toast';
import './SignIn.css'
import { t } from '../../../lang';

class Login extends Component {
  state = {
    data: {
      email: '',
      password: ''
    },
    loading: false,
    errors: [],
    passwordRules: t('signin.rule'),
    eye: 'eye',
    passwordType: 'password',
    rememberMe: false
  };

  toaster = React.createRef();
  
  onChange = e => 
  this.setState({
    data: { ...this.state.data, [e.target.name]: e.target.value },
    errors: []
  });
  
  onSubmit = () => {
    const errors = this.validate(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({loading: true});
      this.props.login(this.state.data, this.state.rememberMe)
        .then(res => {
          this.setState({loading: false});
          if(res && !res.success) {
            // this.toaster.current.configErrorResponse(res.error)
          }
          if(sessionStorage.pop_url) this.props.history.push(`${sessionStorage.pop_url}`);
          sessionStorage.removeItem('pop_url');
        })
    }
  };

  validate = (data) => {
    const errors = {};
    if (!data.email) {
      errors.email = t('signin.errors.empty.email');
    }
    let emailPattern = new RegExp('^[a-z0-9]+(.[_a-z0-9]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,15})$', 'i');
    if(data.email && !emailPattern.test(data.email.toLowerCase())) {
      errors.email = t('signin.errors.email');
    }
    if (!data.password) {
      errors.password = t('signin.errors.empty.password');
    }
    let passwordPattern = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])", "i");
    if(!passwordPattern.test(this.state.data.password)) {
      errors.password = t('signin.errors.password');
    }
    return errors;
  };

  togglePassword = () => {
    if(this.state.eye === 'eye'){
        this.setState({eye: 'eye slash', passwordType: 'text'})
    } else {
        this.setState({eye: 'eye', passwordType: 'password'})
    }
  };

  onEnter = (event, callback) => event.key === 'Enter' && callback()
    
  render() {
    return (
      <div className='sign-in-container'>
        <Card className='sign-in-card'>
          <Header className='sign-in-card-header'>{t(`signin.header`)}</Header>
            <Card.Content>
            <Form className='sign-in-form' onSubmit={this.onSubmit}>
                <Form.Field error={!!this.state.errors.email}>
                  <label htmlFor="email">{t('signin.email')}</label>
                  <Input 
                    className='form-field-input'
                    type="email" 
                    id="email" 
                    name="email"
                    placeholder="example@example.com"
                    value={this.state.data.email}
                    onChange={this.onChange} />
                    {this.state.errors.email && <InlineError text={this.state.errors.email}/>}
                </Form.Field>
                <Form.Field error={!!this.state.errors.password}>
                  <label htmlFor="password">
                    {t('signin.password')}
                    &nbsp;
                    <Popup
                        content={this.state.passwordRules}
                        position='top center'
                        trigger={<Icon link name='info circle' color='blue' />}
                    />
                  </label>
                  <Input 
                    className='form-field-input'
                    icon={<Icon link name={this.state.eye} onClick={this.togglePassword} />}
                    type={this.state.passwordType}
                    id="password" 
                    name="password" 
                    placeholder="••••••••"
                    value={this.state.data.password}
                    onKeyPress={e => this.onEnter(e, this.onSubmit)}
                    onChange={this.onChange} />
                    {this.state.errors.password && <InlineError text={this.state.errors.password}/>}
                    <div className='link-wrapper'>
                      <Link to='/password_reset'>{t('signin.forgot')}?</Link>
                    </div>
                </Form.Field>
                <Form.Field>
                  <Checkbox onChange={()=>this.setState({rememberMe: !this.state.rememberMe})} checked={this.state.rememberMe} className='form-field-checkbox' label={`${t('signin.remember')}`} />
                </Form.Field>
              </Form>
            </Card.Content>
            <Card.Content extra>
              <Popup className='sign-in-form-sign-up-button' size='small' flowing content={t('signin.popup')} trigger={<Button basic onClick={()=>this.props.history.push('/signup')}>{t('signin.signup')}</Button>} />
              <Button className='sign-in-form-sign-in-button' primary onClick={this.onSubmit} loading={this.state.loading} disabled={this.state.data.email === '' || this.state.data.password === '' || this.state.loading }>{t('signin.signin')}</Button>
            </Card.Content>
        </Card>
        <Toast ref={this.toaster} />
      </div>
    )
  }
}

Login.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  login: PropTypes.func.isRequired
};

export default connect(null, {login})(Login);