export default {
    en: {
        choose: {
            header: `Select the form type`,
            map: `Select the desired area on the map`,
            kml: `Upload the desired area from kml file`,
            coords: `Directly insert coordinates of desired area`
        },
        themes: {
            agriculture: `Agriculture`,
            mapping: `Mapping and land management`,
            forestry: `Forestry`,
            sea: `Sea ​​and coastal zones`,
            resources: `Natural resources and engineering networks`,
            threats: `Emergency threats`,
            planning: `Urban planning`,
            other: `Other`,
        },
        acquisition: `Acquisition mode`,
        mono: `Mono`,
        stereo: `Stereo`,
        resolution: `Resolution`,
        high: `High`,
        medium: `Medium`,
        normal: `Enter directly`,
        map: `Draw polygon`,
        kml: `Upload KML`,
        latitude: `Latitude`,
        longitude: `Longitude`,
        add_point: `Add a point`,
        new_order: `New order`,
        coordinates: `Coordinates`,
        upload_kml: `Upload KML file`,
        date_range: `Date range`,
        add_date_range: `Add date range`,
        address: `Address`,
        address_placeholder: `Country, Region, City, Street`,
        theme: `Application theme`,
        theme_placeholder: `Select application theme`,
        resolution_placeholder: `Select resolution`,
        acquisition_mode: `Acquisition mode`,
        acquisition_placeholder: `Select acquisition mode`,
        level: `Processing level`,
        level_placeholder: `Select processing level`,
        cloud: `Cloud coverage`,
        roll: `Roll`,
        pitch: `Pitch`,
        yaw: `Yaw`,
        order_priority: `Order priority`,
        standard: `Standard`,
        priority: `Priority`,
        express: `Express`,
        wishes: `Clients wishes`,
        price: `Price`,
        calculate: `Calculate`,
        apply: `Confirm`,
        cancel: `Cancel`,
        title: `Select the desired area`
    },
    ru: {
        choose: {
            header: `Выберите тип формы`,
            map: `Нарисовать желаемую область на карте`,
            kml: `Загрузить желаемую область из kml файла`,
            coords: `Прямая вставка координат желаемой области`
        },
        themes: {
            agriculture: `Сельское хозяйство`,
            mapping: `Картография и землеустройство`,
            forestry: `Лесоводство`,
            sea: `Морские и прибрежные зоны`,
            resources: `Природные ресурсы и инженерные сети`,
            threats: `Экстренные угрозы`,
            planning: `Городское планирование`,
            other: `Другое`,
        },
        acquisition: `Режим съемки`,
        mono: `Моно`,
        stereo: `Стерео`,
        resolution: `Разрешение`,
        high: `Высокое`,
        medium: `Среднее`,
        normal: `Ввести напрямую`,
        map: `Рисовать полигон`,
        kml: `Загрузить KML`,
        latitude: `Широта`,
        longitude: `Долгота`,
        add_point: `Добавить точку`,
        new_order: `Новый заказ`,
        coordinates: `Координаты`,
        date_range: `Диапазон дат`,
        add_date_range: `Добавить диапазон дат`,
        address: `Адрес`,
        address_placeholder: `Страна, Регион, Город, Улица`,
        theme: `Тема приложения`,
        theme_placeholder: `Выберите тему приложения`,
        resolution_placeholder: `Выберите разрешение`,
        acquisition_mode: `Режим съемки`,
        acquisition_placeholder: `Выберите режим съемки`,
        level: `Уровень обработки`,
        level_placeholder: `Выберите уровень обработки`,
        cloud: `Облачность`,
        roll: `Крен`,
        pitch: `Тангаж`,
        yaw: `Рыскание`,
        order_priority: `Приоритет заказа`,
        standard: `Стандарт`,
        priority: `Приоритет`,
        express: `Экспресс`,
        wishes: `Пожелание клиента`,
        price: `Цена`,
        calculate: `Рассчитать`,
        apply: `Подтвердить`,
        cancel: `Отмена`,
        title: `Выберите нужную область`
    }
};
