export default {
  ru: {
    header: `Регистрация`,
    email: `Эл. адрес`,
    phone: `Телефон`,
    first_name: `Имя`,
    last_name: `Фамилия`,
    company: `Компания`,
    purpose: `Цель`,
    purpose_placeholder: `Моя цель`,
    password: `Пароль`,
    repeat_password: `Повторить пароль`,
    signin: `Войти`,
    signup: `Регистрация`,
    popup: `Если вы уже зарегистрированы, пожалуйста, нажмите на кнопку входа, чтобы войти.`,
    rule: `Пароль должен содержать как минимум одну цифру, одну строчную букву, одну заглавную букву длиной не менее 8 символов.`,
    errors: {
      valid: {
        email: `Пожалуйста, введите валидную почту`,
        password: `Пожалуйста, введите валидный пароль`,
        phone: `Пожалуйста, введите валидный номер телефон`,
      },
      min_password: `Допускается минимум 8 символов`,
      max: {
        name: `Допускается максимум 32 символа`,
        company: `Допускается максимум 64 символа`,
        purpose: `Допускается максимум 500 символа`,
      },
      match: `Пароли не совпадают`,
    },
    verification: {
      complete: `Закончить верификацию`,
      body: `Поздравляем! Вы успешно зарегистрировались. Ваш регистрация почти завершена. Вам нужно завершить последний шаг. Мы отправим на вашу электронную почту письмо с подтверждением, пожалуйста, проверьте Ваш электронный адрес и завершите регистрацию.`,
      not_received: `Если вы не получили подтверждающее письмо, вы можете отправить его снова.`,
      send: `Отправить`,
    },
    gender: {
      label: `Пол`,
      male: `Мужчина`,
      female: `Женщина`,
      unclear: `Неясный`,
    },
    role: `Роль`,
    roles: {
      admin: `Админ`,
      manager: `Менеджер`,
      handler: `Обработчик`,
      user: `Пользователь`,
    },
    user: `пользователя`,
    add: `Добавить`,
    edit: `Изменить`,
    cancel: `Отменить`,
  },
  en: {
    header: `Sign up`,
    email: `Email`,
    phone: `Phone`,
    first_name: `First Name`,
    last_name: `Last Name`,
    company: `Company`,
    purpose: `Purpose`,
    purpose_placeholder: `My purpose is `,
    password: `Password`,
    repeat_password: `Repeat Password`,
    signin: `Sign In`,
    signup: `Sign Up`,
    popup: `If you have already registered,please click the sign in button to sign in.`,
    rule: `Password must contain at least one digit, one lower case, one upper case symbols with minimum of 8 characters.`,
    errors: {
      valid: {
        email: `Please enter valid email`,
        password: `Please enter valid password"`,
        phone: `Please enter valid phone number`,
      },
      min: {
        password: `Allowed minimum 8 characters`,
      },
      max: {
        first_name: `Allowed maximum 32 characters`,
        last_name: `Allowed maximum 32 characters`,
        company: `Allowed maximum 64 characters`,
        purpose: `Allowed maximum 500 characters`,
      },
      match: `Passwords doesn't match`,
    },
    verification: {
      complete: `Complete verification`,
      body: `Congratulations! You have successfully signed up. Your
                registration almost complete. You need to complete one last
                step. We send to your email verification letter, please check
                your email and complete the registration.`,
      not_received: `If you have not received verification letter, you can send it again.`,
      send: `Send`,
    },
    gender: {
      label: `Gender`,
      male: `Male`,
      female: `Female`,
      unclear: `Unclear`,
    },
    role: `Role`,
    roles: {
      admin: `Admin`,
      manager: `Manager`,
      user: `User`,
      handler: `Handler`,
    },
    user: `user`,
    add: `Add`,
    edit: `Edit`,
    cancel: `Cancel`,
  },
};
