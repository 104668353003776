import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import './index.css';
import 'semantic-ui-css/semantic.min.css';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import App from './App';
import * as serviceWorker from './serviceWorker';
import rootReducer from './rootReducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import {userLoggedIn} from './actions/auth'
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw';
import { Icon } from 'leaflet';
import 'leaflet-draw/dist/leaflet.draw.css';
import * as Sentry from '@sentry/browser';

import axios from 'axios';

Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

if(localStorage.token && localStorage.user) {
  const data = JSON.parse(localStorage.user);
  store.dispatch(userLoggedIn(data));
  axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.token}`;
} else if(sessionStorage.token && sessionStorage.user) {
  const data = JSON.parse(sessionStorage.user);
  store.dispatch(userLoggedIn(data));
  axios.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.token}`;
}

Sentry.init({dsn: "https://bb4b10e23be04bfdbc72ebd58feb3a94@sentry.io/1851926"});

ReactDOM.render(
    <BrowserRouter>
      <Provider store={ store }>
        <Route component={App} />
      </Provider>
    </BrowserRouter>,
  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
