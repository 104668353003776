import React, {Component} from 'react';
import {Card, Icon, List} from "semantic-ui-react";
import './Control.css'
import { t } from '../../../lang';

class Control extends Component {

    itemClicked = (type) => {
        this.props.history.push(type);
    };

    render() {
        return (
            <div style={{padding: '2vw'}}>
                <Card fluid className="card-control">
                    <List divided relaxed>
                        <List.Item onClick={()=>this.itemClicked('/pricelist')}>
                            <List.Content>
                                <Icon name="money bill alternate outline" />
                                &nbsp;
                                <span>{t('control.pricing')}</span>
                            </List.Content>
                        </List.Item>
                        <List.Item onClick={()=>this.itemClicked('/users')}>
                            <List.Content>
                                <Icon name="users" />
                                &nbsp;
                                <span>{t('control.users')}</span>
                            </List.Content>
                        </List.Item>
                        <List.Item disabled onClick={()=>this.itemClicked('/coupons')}>
                            <List.Content>
                                <Icon name="tag" />
                                &nbsp;
                                <span>{t('control.coupons')}</span> [In development]
                            </List.Content>
                        </List.Item>
                        <List.Item disabled onClick={()=>this.itemClicked('/partners')}>
                            <List.Content>
                                <Icon name="handshake outline" />
                                &nbsp;
                                <span>{t('control.partnership')}</span> [In development]
                            </List.Content>
                        </List.Item>
                        <List.Item disabled onClick={()=>this.itemClicked('/logs')}>
                            <List.Content>
                                <Icon name="spy" />
                                &nbsp;
                                <span>{t('control.logs')}</span> [In development]
                            </List.Content>
                        </List.Item>
                    </List>
                </Card>
            </div>
        );
    }
}

export default Control;