import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { saveAs } from "file-saver";
import tokml from "tokml";
import {
  Button,
  Card,
  Form,
  Grid,
  Label,
  List,
  Table,
  TextArea,
  Icon,
  Popup,
} from "semantic-ui-react";
import "./Order.css";
import {
  approveManagerOrder,
  cancelManagerOrder,
  getOrder,
  getUserOrder,
  addDownload,
} from "../../../actions/orders";
import { t } from "../../../lang";
import Confirm from "../../messages/ModalConfirm";

class Order extends Component {
  state = {
    order: null,
    reason: "",
  };

  confirm = React.createRef();

  async componentDidMount() {
    const { isUser, user } = this.props;
    if (isUser) {
      this.props.getUserOrder(this.props.match.params.id).then((res) => {
        console.log(res);
        if (res.success && user.id === res.order.user_id)
          this.setState({ order: res.order });
        if (!res.success) this.props.history.push("/");
      });
    } else {
      this.getOrder();
    }
  }

  getOrder = () => {
    this.props.getOrder(this.props.match.params.id).then((res) => {
      if (res.success) {
        this.setState({ order: res.order });
        res.order.images[0].downloads.reduce((newDownloads, current) => {
          if (!newDownloads.some((x) => x.id === current.id)) {
            newDownloads.push(current);
          }
          return newDownloads;
        }, []);
      }
      if (!res.success) this.props.history.push("/");
    });
  };

  handleChange = (e) => {
    this.setState({ reason: e.target.value });
  };

  handleSubmit = (id, type) => {
    if (type === "cancel") {
      this.props
        .cancelManagerOrder({ id: id, reason: this.state.reason })
        .then((res) => {
          if (res.success) this.setState({ order: res.order });
          this.getOrder();
        });
    } else {
      this.props
        .approveManagerOrder({ id: id, response: this.state.reason })
        .then((res) => {
          if (res.success) this.setState({ order: res.order });
          this.getOrder();
        });
    }
    this.confirm.current.hideModal();
  };

  downloadKML = (image) => {
    console.log(image);
    let geometry = null;
    if (image.geojson.type === "FeatureCollection") {
      geometry = image.geojson.features[0].geometry;
    }
    if (image.geojson.type === "Feature") {
      geometry = image.geojson.geometry;
    }
    if (image.geojson.type === "Polygon") {
      geometry = image.geojson;
    }
    if (geometry) {
      const kml = tokml(geometry, {
        name: image.code,
      });
      const file = new Blob([kml], { type: "text/xml" });
      saveAs(
        file,
        `${
          image.code
            ? image.code
            : this.state.order.user.last_name +
              " " +
              this.state.order.user.first_name
        }.kml`
      );
    }
    this.addToDownload(image.id);
  };

  addToDownload = async (id) => {
    const res = await this.props.addDownload({
      order_id: this.state.order.id,
      image_id: id,
      user_id: this.props.user.id,
    });
    if (res && res.success) this.getOrder();
  };

  render() {
    const { order, reason } = this.state;
    const { isUser, user } = this.props;
    let list = [];
    if (order && order.images) {
      list = order.images.map((image) => {
        return (
          <Table.Row key={image.id}>
            <Table.Cell>
              {image.is_archive ? t("orders.archive") : t("orders.new")}
            </Table.Cell>
            <Table.Cell>{image.acquisition_mode}</Table.Cell>
            <Table.Cell>{image.resolution}</Table.Cell>
            <Table.Cell>{image.processing_level}</Table.Cell>
            <Table.Cell>
              {image.area} km<sup>2</sup>
            </Table.Cell>
            <Table.Cell>
              {parseFloat(image.price_kzt)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}{" "}
              ₸
            </Table.Cell>
            <Table.Cell>
              {parseFloat(image.price_usd)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}{" "}
              $
            </Table.Cell>
            <Table.Cell>
              {image.address ? image.address : t("orders.not_defined")}
            </Table.Cell>
            <Table.Cell>
              {image.theme ? image.theme : t("orders.not_defined")}
            </Table.Cell>
            <Table.Cell>
              {image.wishes ? image.wishes : t("orders.not_defined")}
            </Table.Cell>
            <Table.Cell>
              {new Date(image.created_at).toLocaleDateString()}
            </Table.Cell>
            {this.props.user.role === "handler" && (
              <Table.Cell style={{ textAlign: "center" }}>
                {image && image.downloads && image.downloads.length > 0 && (
                  <Popup
                    className="order-image-popup"
                    inverted
                    trigger={
                      <a>
                        <Icon
                          name="download"
                          onClick={() => this.downloadKML(image)}
                        />
                      </a>
                    }
                    position="left center"
                    content={
                      <List divided inverted relaxed size="mini">
                        {image.downloads
                          .reduce((newDownloads, current) => {
                            if (!newDownloads.some((x) => x.id == current.id)) {
                              newDownloads.push(current);
                            }
                            return newDownloads;
                          }, [])
                          .map((u) => {
                            const count = image.downloads.filter(
                              (d) => d.id === u.id
                            ).length;
                            return (
                              <List.Item
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <span>{u.last_name + "  " + u.first_name}</span>
                                &nbsp;&nbsp;&nbsp;
                                <Label circular color="red" size="mini">
                                  {count}
                                </Label>
                              </List.Item>
                            );
                          })}
                      </List>
                    }
                  ></Popup>
                )}
                {image && image.downloads && image.downloads.length === 0 && (
                  <a>
                    <Icon
                      name="download"
                      onClick={() => this.downloadKML(image)}
                    />
                  </a>
                )}
              </Table.Cell>
            )}
          </Table.Row>
        );
      });
    }
    return (
      <React.Fragment>
        {order && (
          <div id="order-container">
            <Grid className="order-grid">
              <Grid.Row
                columns={isUser && !order.is_canceled && !order.is_done ? 1 : 2}
              >
                <Grid.Column>
                  <Card fluid className="order-card">
                    <List divided relaxed>
                      <List.Item>
                        <List.Content>
                          <strong>{t("orders.order_id")}:</strong>{" "}
                          <span style={{ float: "right" }}>{order.id}</span>
                        </List.Content>
                      </List.Item>
                      <List.Item>
                        <List.Content>
                          <strong>{t("orders.user")}:</strong>{" "}
                          {(order.user || (isUser && user)) && (
                            <span style={{ float: "right" }}>
                              {isUser ? user.last_name : order.user.last_name}{" "}
                              {isUser ? user.first_name : order.user.first_name}
                            </span>
                          )}
                        </List.Content>
                      </List.Item>
                      <List.Item>
                        <List.Content>
                          <strong>{t("orders.area")}:</strong>{" "}
                          <span style={{ float: "right" }}>
                            {order.area} km<sup>2</sup>
                          </span>
                        </List.Content>
                      </List.Item>
                      <List.Item>
                        <List.Content>
                          <strong>{t("orders.price")} (KZT):</strong>{" "}
                          <span style={{ float: "right" }}>
                            {parseFloat(order.price_kzt)
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}{" "}
                            ₸
                          </span>
                        </List.Content>
                      </List.Item>
                      <List.Item>
                        <List.Content>
                          <strong>{t("orders.price")} (USD):</strong>{" "}
                          <span style={{ float: "right" }}>
                            {parseFloat(order.price_usd)
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}{" "}
                            $
                          </span>
                        </List.Content>
                      </List.Item>
                      <List.Item>
                        <List.Content>
                          <strong>{t("orders.status")}:</strong>{" "}
                          <span style={{ float: "right" }}>
                            <Label
                              color={
                                order.is_done
                                  ? "green"
                                  : order.is_canceled
                                  ? "red"
                                  : "orange"
                              }
                            >
                              {order.is_done
                                ? t("orders.done")
                                : order.is_canceled
                                ? t("orders.cancelled")
                                : t("orders.processing")}
                            </Label>
                          </span>
                        </List.Content>
                      </List.Item>
                      <List.Item>
                        <List.Content>
                          <strong>{t("orders.date")}:</strong>{" "}
                          <span style={{ float: "right" }}>
                            {new Date(order.created_at).toLocaleDateString()}
                          </span>
                        </List.Content>
                      </List.Item>
                    </List>
                  </Card>
                </Grid.Column>
                {!(isUser && !order.is_canceled && !order.is_done) && (
                  <Grid.Column>
                    <Card fluid className="order-card">
                      <Card.Content>
                        {!order.is_canceled && !order.is_done && (
                          <Form>
                            <Form.Field>
                              <label>
                                {t("orders.reason")}/{t("orders.response")}:{" "}
                                {isUser}
                              </label>
                              <TextArea
                                maxLength={500}
                                value={reason}
                                onChange={this.handleChange}
                                rows={11}
                                style={{ resize: "none" }}
                                placeholder={`${t("orders.placeholder")}...`}
                              />
                            </Form.Field>
                            <div>
                              <Button
                                type="submit"
                                disabled={!reason}
                                onClick={() =>
                                  this.confirm.current.showModal(
                                    order.id,
                                    `${t("orders.cancel_title")} ${order.id}?`,
                                    `${t("orders.cancel_body")} ${order.id}?`,
                                    null,
                                    null,
                                    "cancel"
                                  )
                                }
                              >
                                {t("orders.cancel_order")}
                              </Button>
                              <Button
                                type="submit"
                                disabled={!reason}
                                onClick={() =>
                                  this.confirm.current.showModal(
                                    order.id,
                                    `${t("orders.approve_title")} ${order.id}?`,
                                    `${t("orders.approve_body")} ${order.id}?`,
                                    null,
                                    null,
                                    "approve"
                                  )
                                }
                                positive
                                style={{ float: "right" }}
                              >
                                {t("orders.approve_order")}
                              </Button>
                            </div>
                          </Form>
                        )}
                        {order.is_done && (
                          <div>
                            <label>
                              <strong>{t("orders.response")}:</strong>{" "}
                            </label>
                            <p>{order.response.response}</p>
                          </div>
                        )}

                        {order.is_canceled && (
                          <div>
                            <label>
                              <strong>{t("orders.reason")}:</strong>{" "}
                            </label>
                            <p>{order.reason.reason}</p>
                          </div>
                        )}
                      </Card.Content>
                    </Card>
                  </Grid.Column>
                )}
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Card fluid className="order-card">
                    <Card.Content>
                      <Form>
                        <Form.Field>
                          <label>{t("orders.images")}:</label>
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                              overflowX: "auto",
                            }}
                          >
                            <Table celled compact style={{ marginTop: "0" }}>
                              <Table.Header>
                                <Table.Row>
                                  <Table.HeaderCell>
                                    {t("orders.type")}
                                  </Table.HeaderCell>
                                  <Table.HeaderCell>
                                    {t("orders.acquisition")}
                                  </Table.HeaderCell>
                                  <Table.HeaderCell>
                                    {t("orders.resolution")}
                                  </Table.HeaderCell>
                                  <Table.HeaderCell>
                                    {t("orders.level")}
                                  </Table.HeaderCell>
                                  <Table.HeaderCell>
                                    {t("orders.area")}
                                  </Table.HeaderCell>
                                  <Table.HeaderCell>
                                    {t("orders.price")} (KZT)
                                  </Table.HeaderCell>
                                  <Table.HeaderCell>
                                    {t("orders.price")} (USD)
                                  </Table.HeaderCell>
                                  <Table.HeaderCell>
                                    {t("orders.address")}
                                  </Table.HeaderCell>
                                  <Table.HeaderCell>
                                    {t("orders.theme")}
                                  </Table.HeaderCell>
                                  <Table.HeaderCell>
                                    {t("orders.wishes")}
                                  </Table.HeaderCell>
                                  <Table.HeaderCell>
                                    {t("orders.date")}
                                  </Table.HeaderCell>
                                  {this.props.user.role === "handler" && (
                                    <Table.HeaderCell>KML</Table.HeaderCell>
                                  )}
                                </Table.Row>
                              </Table.Header>
                              <Table.Body>{list}</Table.Body>
                            </Table>
                          </div>
                        </Form.Field>
                      </Form>
                    </Card.Content>
                  </Card>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Confirm
              ref={this.confirm}
              onCancelClicked={() => this.confirm.current.hideModal()}
              onConfirmClicked={this.handleSubmit}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}

Order.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  getOrder: PropTypes.func.isRequired,
  cancelManagerOrder: PropTypes.func.isRequired,
  approveManagerOrder: PropTypes.func.isRequired,
  getUserOrder: PropTypes.func.isRequired,
  isUser: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    last_name: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: !!state.user.token,
    isUser: state.user.role === "user",
    user: state.user,
  };
};

export default connect(
  mapStateToProps,
  {
    getOrder,
    cancelManagerOrder,
    approveManagerOrder,
    getUserOrder,
    addDownload,
  }
)(Order);
