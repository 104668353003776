import React, { Component } from 'react'
import './Slider.css'
import $ from 'jquery';

export default class Slider extends Component {

    componentDidMount() {
        $(`<style>#angle-${this.props.type}::-webkit-slider-thumb { background-image: url(${require("../../../../../../assets/images/"+this.props.type+".webp")});}<style/>`).appendTo('head');
        $(`<style>#angle-${this.props.type}::-moz-range-thumb { background-image: url(${require("../../../../../../assets/images/"+this.props.type+".webp")});}<style/>`).appendTo('head');
    }

    getAngleValue = (e) => {
        this.props.onAngleChange(e.target.value)
    };
    
    render() {
        return (
            <div className="container">
            <input 
                    onChange={this.getAngleValue}
                    value={this.props.value}
                    type="range"
                    min={this.props.min}
                    max={this.props.max}
                    className="slider"
                    id={"angle-"+this.props.type}/>
            </div>
        )
    }
}
