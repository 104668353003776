export default {
    en: {
        pricing: `Pricing`,
        users: `Users`,
        coupons: `Coupons`,
        partnership: `Partnership`,
        logs: `Logs`,
    },
    ru: {
        pricing: `Ценообразование`,
        users: `Пользователи`,
        coupons: `Купоны`,
        partnership: `Партнерство`,
        logs: `Логи`,
    }
};
