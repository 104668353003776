import React from 'react';
import { Button, Form, Modal, Select, TextArea } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { calculateArchivePrice, addToCartArchive } from "../../../actions/cart";
import {t} from '../../../lang';

class ArchiveForm extends React.Component {
    state = {
        themeOptions: [
            { key: "1", value: "agriculture", text: t('new_form.themes.agriculture') },
            { key: "2", value: "mapping", text: t('new_form.themes.mapping') },
            { key: "3", value: "forestry", text: t('new_form.themes.forestry') },
            { key: "4", value: "sea", text: t('new_form.themes.sea') },
            {
                key: "5",
                value: "resources",
                text: t('new_form.themes.resources')
            },
            { key: "6", value: "threats", text: t('new_form.themes.threats') },
            { key: "7", value: "planning", text: t('new_form.themes.planning') },
            { key: "9", value: "other", text: t('new_form.themes.other') }
        ],
        levels: [
            { key: 1, value: "L1", text: "L1" },
            { key: 2, value: "L2", text: "L2" },
            { key: 3, value: "L3", text: "L3" },
            { key: 4, value: "L4", text: "L4" },
            // { key: 5, value: "L5", text: "L5" },
        ],
        aquisitionMode: [
            { key: 1, value: "mono", text: t('new_form.mono') },
            { key: 2, value: "stereo", text: t('new_form.stereo') }
        ],
        selectedAquisition: 'mono',
        selectedTheme: '',
        comments: '',
        level: 'L1',
        prices: {
            kzt: 0,
            usd: 0
        }
    };

    componentDidUpdate (prevState, prevProps) {
        if(this.props.id && prevProps.id !== this.props.id && this.state.prices.kzt === 0) this.getPrice();
    }

    setAcquisition = async (e, data) => {
        await this.setState({ selectedAquisition: data.value });
        this.getPrice();
    };

    themeSelected = (e, data) => {
        if (data.value === "other") {
            this.setState({ isOtherChosen: true });
        } else {
            this.setState({ isOtherChosen: false });
            this.setState({ selectedTheme: data.value });
        }
    };

    addClicked = () => {
        // this.props.onSubmitClicked();
        let form = {
            area_id: this.props.id,
            application_theme: this.state.selectedTheme,
            acquisition_mode: this.state.selectedAquisition,
            processing_level: this.state.level,
            only_calculate: false,
            wishes: this.state.comments
        };
        this.props.addToCartArchive(form);
        this.closeModal(true);
    };

    closeModal = (type) => {
        this.setState({
            id: null,
            selectedAquisition: 'mono',
            selectedTheme: '',
            comments: '',
            level: 'L1',
            prices: {
                kzt: 0,
                usd: 0
            }
        });
        this.props.onModalClosed(type);
    };
    getPrice = () => {
        let form = {
            area_id: this.props.id,
            application_theme: this.state.selectedTheme,
            acquisition_mode: this.state.selectedAquisition,
            processing_level: this.state.level,
            only_calculate: true,
            wishes: this.state.comments
        };
        this.props.calculateArchivePrice(form)
            .then(res => {
                this.setState({prices: res})
            });
    };

    render() {
        return (
            <Modal
                size='small'
                open={this.props.isModalActive}
                onClose={()=>this.closeModal(false)}>
                <Modal.Header>{t('sidebar.layers.cart')}</Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Field>
                            <label>{t('new_form.acquisition')} <strong style={{color: 'red'}}>*</strong></label>
                            <Select
                                value={this.state.selectedAquisition}
                                placeholder={t('new_form.acquisition_placeholder')}
                                onChange={this.setAcquisition}
                                options={this.state.aquisitionMode}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>{t('new_form.level')} <strong style={{color: 'red'}}>*</strong></label>
                            <Select
                                value={this.state.level}
                                placeholder={t('new_form.level_placeholder')}
                                onChange={async (e, data) => {
                                        await this.setState({level: data.value});
                                        this.getPrice();
                                    }
                                }
                                options={this.state.levels}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>{t('new_form.theme')} </label>
                            <Select
                                placeholder={t('new_form.theme_placeholder')}
                                onChange={this.themeSelected}
                                options={this.state.themeOptions}
                            />
                            {this.state.isOtherChosen && (
                                <TextArea
                                    style={{ marginTop: "10px" }}
                                    onChange={(e, data) =>
                                        this.setState({ selectedTheme: data.value })
                                    }
                                />
                            )}
                        </Form.Field>
                        <Form.Field>
                            <label>{t('new_form.wishes')}</label>
                            <TextArea
                                onInput={e =>
                                    this.setState({ comments: e.target.value })
                                }
                                value={this.state.comments}
                            />
                        </Form.Field>
                        <Form.Field>
                            {this.state.prices.kzt > 0 && <p>Price: {parseFloat(this.state.prices.kzt).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') +" ₸" }</p>}
                            {this.state.prices.usd > 0 && <p>Price: {parseFloat(this.state.prices.usd).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') +" $" }</p>}
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='red' onClick={()=>this.closeModal(false)}>{t('new_form.cancel')}</Button>
                    {/*<Button color='blue'*/}
                    {/*    onClick={this.getPrice}*/}
                    {/*    disabled={*/}
                    {/*        this.props.id === null ||*/}
                    {/*        this.state.selectedAquisition === '' ||*/}
                    {/*        this.state.level === ''*/}
                    {/*    }*/}
                    {/*>{t('new_form.calculate')}</Button>*/}
                    <Button color='green'
                        disabled={
                            this.props.id === null ||
                            this.state.selectedAquisition === '' ||
                            this.state.level === ''
                        }
                        onClick={this.addClicked}
                    >
                        {t('new_form.apply')}
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

ArchiveForm.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    calculateArchivePrice: PropTypes.func.isRequired,
    addToCartArchive: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        isAuthenticated: !!state.user.token,
    };
}

export default connect(mapStateToProps, { calculateArchivePrice, addToCartArchive })(ArchiveForm);