import React, {Component} from 'react';
import {Button, Modal} from 'semantic-ui-react';
import { t } from '../../lang';

class ModalConfirm extends Component {

    state = {
        id: null,
        isModalActive: false,
        title: '',
        description: '',
        cancelBtn: '',
        confirmBtn: '',
        extra: null
    };

    showModal = (id, title, description, confirmBtn, cancelBtn, extra = null) => {
        this.setState({
            isModalActive: true,
            id: id,
            title: title ? title : '',
            description: description ? description : '',
            cancelBtn: cancelBtn ? cancelBtn : t('messages.confirm.cancel'),
            confirmBtn: confirmBtn ? confirmBtn :  t('messages.confirm.confirm'),
            extra: extra ? extra : null
        })
    };

    hideModal = () => {
        this.setState({
            isModalActive: false,
            id: null,
            title: '',
            description: '',
            cancelBtn: '',
            confirmBtn: '',
            extra: null
        })
    };

    render() {
        return (
            <React.Fragment>
                <Modal open={this.state.isModalActive} onClose={this.props.onCancelClicked} size='small'>
                    <Modal.Header>{this.state.title}</Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <p>{this.state.description}</p>
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button content={this.state.cancelBtn} onClick={this.props.onCancelClicked}/>
                        <Button primary content={this.state.confirmBtn}
                                onClick={() => this.props.onConfirmClicked(this.state.id, this.state.extra)}/>
                    </Modal.Actions>
                </Modal>
            </React.Fragment>

        );
    }
}

export default ModalConfirm;