import { GET_CART, DELETE_CART, NEW_CART_ITEM, CARTS_POSTED } from '../types';
import api from '../api';
import { requestHasError } from './error';

export const getCartItems = (data) => ({
    type: GET_CART,
    cart: data
});

export const newCartItem = (data) => ({
    type: NEW_CART_ITEM
});

export const deleteCartItem = (data) => ({
    type: DELETE_CART
});

export const cartPosted = (data) => ({
    type: CARTS_POSTED
});

export const calculateArchivePrice = data => dispatch => {
    return api.cart.cart_archive(data).then(res => {
        if(!res.success) dispatch(requestHasError(res));
        return {
            kzt: res.price_kzt,
            usd: res.price_usd
        }
    });
};

export const calculateNewPrice = data => dispatch => {
    return api.cart.cart_new(data).then(res => {
        if(!res.success) dispatch(requestHasError(res));
        return {
            kzt: res.price_kzt,
            usd: res.price_usd
        }
    });
};

export const addToCartArchive = data => dispatch => {
    api.cart.cart_archive(data).then(res => {
        if(!res.success) dispatch(requestHasError(res));
        if(res.success) dispatch(newCartItem());
        if(res.success) dispatch(getCart());
    });
};

export const addToCartNew = data => dispatch => {
    return api.cart.cart_new(data).then(res => {
        if(!res.success) dispatch(requestHasError(res));
        if(res.success) dispatch(newCartItem());
        if(res.success) dispatch(getCart())
        return res;
    });
};

export const getCart = () => dispatch => {
    return api.cart.cart().then(res => {
        if(!res.success) dispatch(requestHasError(res));
        if(res.success) dispatch(getCartItems(res.cart))
        return res;
    });
};

export const deleteCart = (data) => dispatch => {
    return api.cart.delete_cart(data).then(res => {
        if(!res.success) dispatch(requestHasError(res));
        if(res.success) dispatch(deleteCartItem());
        if(res.success) dispatch(getCart());
        return res;
    });
};

export const submitCart = () => dispatch => {
    return api.cart.submit_cart().then(res => {
        if(!res.success) dispatch(requestHasError(res));
        if(res.success) dispatch(cartPosted());
        if(res.success) dispatch(getCart());
        return res;
    });
};