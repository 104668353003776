import React, { Component } from 'react'
import { DateRange } from 'react-date-range'
import { Button, Modal, Icon } from 'semantic-ui-react'
import './Period.css'
import * as rdrLocales from 'react-date-range/dist/locale';
import moment from 'moment';
import { t, getLanguage } from '../../../../lang';

export default class DatePicker extends Component {

    state = {
        open: false,
        dateRange: {
            selection: {
                startDate: moment(new Date()).toDate(),
                endDate: moment(new Date()).add(1, 'months').toDate(),
                key: 'selection',
            },
          },
        text: t('datepicker.header')
    };

    componentDidMount() {
        this.setState({text: `${moment(this.state.dateRange.selection.startDate).format('DD.MM.YY')} - ${moment(this.state.dateRange.selection.endDate).format('DD.MM.YY')}`});
        this.props.onDateChosen(this.state.dateRange.selection, this.props.dateKey)
    }

    handleRangeChange(which, payload) {
        this.setState({
          [which]: {
            ...this.state[which],
            ...payload,
          },
        });
    }

    showModal = () => {
        this.setState({open: true})
    };

    hideModal = () => {
        this.setState({open: false})
    };

    confirmDate = () => {
        if(this.state.dateRange.selection.startDate && this.state.dateRange.selection.endDate){
            this.props.onDateChosen(this.state.dateRange.selection, this.props.dateKey);
            this.setState({
                text: `${moment(this.state.dateRange.selection.startDate).format('DD.MM.YY')} - ${moment(this.state.dateRange.selection.endDate).format('DD.MM.YY')}`
            });
            this.hideModal()
        }
    };
    render() {
        return (
            <div>
                <Modal
                    style={{width: 'auto'}}
                    open={this.state.open}
                    onClose={this.hideModal}
                    trigger={
                    <div style={{display: 'flex', marginBottom: '10px'}}>
                        <Button 
                            icon labelPosition='left' 
                            className="date-period" 
                            fluid 
                            onClick={this.showModal}
                        >
                            <Icon name="calendar alternate outline"/>
                            {this.state.text}
                        </Button>
                        <Button
                            style={{marginRight: '0'}}
                            icon='minus'
                            onClick={()=>this.props.onRemoveClicked(this.props.dateKey)}
                        />
                    </div>}>
                    <Modal.Header>{t('datepicker.header')}</Modal.Header>
                    <Modal.Content>
                        <DateRange
                            locale={getLanguage() === 'ru' ? rdrLocales.ru : rdrLocales.en}
                            onChange={this.handleRangeChange.bind(this, 'dateRange')}
                            moveRangeOnFirstSelection={false}
                            ranges={[this.state.dateRange.selection]}
                        />
                    </Modal.Content>
                    <Modal.Actions>
                    <Button color='red' onClick={this.hideModal}>
                        <Icon name='remove' />{t('datepicker.cancel')}
                    </Button>
                    <Button color='green' onClick={this.confirmDate}>
                        <Icon name='checkmark' />{t('datepicker.confirm')}
                    </Button>
                    </Modal.Actions>
                </Modal>
            </div>
        )
    }
}
