import React, {Component} from 'react'
import {connect} from "react-redux"
import PropTypes from "prop-types"
import './Cart'
import {Button, Checkbox, Icon, Label, Select, Table} from 'semantic-ui-react';
import {deleteCart, getCart, submitCart} from "../../../actions/cart";
import {logout} from "../../../actions/auth";
import Confirm from '../../messages/ModalConfirm';
import {t} from '../../../lang';

class Cart extends Component {
    state = {
        checked: [],
        totalCheck: true,
        currencies : [
            {key: 1, value: 'kzt', text: 'KZT'},
            {key: 2, value: 'usd', text: 'USD'}
        ],
        currency: 'kzt'
    };

    confirm = React.createRef();

    componentWillMount() {
        if (this.props.isAuthenticated) this.props.getCart()
            .then(res => {
                if (res.success && this.props.cart && this.props.cart.images) {
                    this.props.cart.images.forEach(image => {
                        this.state.checked.push(true);
                    });
                    this.setState({totalCheck: true});
                }
                if (!res.success) this.props.logout(this.props.user.token);
            });
    }

    handleChange = (e, data) => {
        this.setState({currency: data.value});
    };

    getTotalPrice = () => {
        let totalKZT = 0;
        let totalUSD = 0;
        if (this.props.cart && this.props.cart.images) {
            this.props.cart.images.forEach((item, index) => {
                if (this.state.checked[index]) {
                    totalKZT += parseFloat(item.price_kzt);
                    totalUSD += parseFloat(item.price_usd);
                }
            })
        }
        return {
            kzt: totalKZT.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') + " ₸",
            usd: totalUSD.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') + " $"
        };
    };

    toggleAllCheckboxes = () => {

        let list = this.state.checked;
        list = list.map(i => {
            i = !this.state.totalCheck;
            return i;
        });
        this.setState({totalCheck: !this.state.totalCheck});
        this.setState({checked: list});
        this.getTotalPrice();
    };

    toggleCheckbox = i => {
        let list = this.state.checked;
        list[i] = !list[i];
        if (list[i] === false) {
            this.setState({totalCheck: false});
        }
        this.setState({checked: list});
        this.checkAllChecked();
        this.getTotalPrice();
    };

    checkAllChecked = () => {
        let check = true;
        this.state.checked.forEach(e => {
            if (e === false) {
                check = false;
            }
        });
        this.setState({totalCheck: check});
    };

    deleteItem = (id, item) => {
        this.confirm.current.hideModal();
        this.props.deleteCart(item);
    };

    checkoutCart = () => {
        this.props.submitCart()
            .then(res => {
                if (res.success) this.props.history.push('/profile');
            })
    };

    render() {
        let list = [];
        this.props.cart && this.props.cart.images && this.props.cart.images.forEach((item, index) => {
            list.push(
                <Table.Row key={index}>
                    <Table.Cell>
                        <Checkbox
                            onChange={() => this.toggleCheckbox(index)}
                            checked={this.state.checked[index]}
                        />
                    </Table.Cell>
                    <Table.Cell>{item.is_archive ? t('cart.archive') : t('cart.new')}</Table.Cell>
                    <Table.Cell>{item.acquisition_mode}</Table.Cell>
                    <Table.Cell>{item.processing_level}</Table.Cell>
                    <Table.Cell>{item.area} km<sup>2</sup></Table.Cell>
                    <Table.Cell>{item.priority}</Table.Cell>
                    <Table.Cell>{parseFloat(this.state.currency === 'kzt' ? item.price_kzt:item.price_usd).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} {this.state.currency === 'kzt'? '₸':'$'}</Table.Cell>
                    {/*<Table.Cell>{parseFloat(item.price_usd).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} $</Table.Cell>*/}
                    <Table.Cell>{new Date(item.created_at).toLocaleDateString()}</Table.Cell>
                    <Table.Cell selectable textAlign='center'>
                        <Icon onClick={() => {
                            this.confirm.current.showModal(
                                item.id,
                                `${t('cart.header')} ${item.id}?`,
                                `${t('cart.content')}?`,
                                null,
                                null,
                                item
                            );
                        }} className="trash" name="trash"/>
                    </Table.Cell>
                </Table.Row>)
        });
        return (
            <React.Fragment>
                {
                    this.props.isAuthenticated &&
                    (<div>
                        <div>
                            <div id="container" style={{padding: '2vw'}}>
                                <span style={{float: 'right', marginBottom: '2vw'}}>
                                    <label style={{verticalAlign: 'sub'}}><strong>Currency:</strong></label>
                                    &nbsp;&nbsp;
                                    <Select
                                        name='currency'
                                        className="currency"
                                        value={this.state.currency}
                                        onChange={this.handleChange}
                                        options={this.state.currencies}
                                    />
                                </span>
                                <Table celled striped className="cart-wrapper">
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>
                                                <Checkbox
                                                    onChange={this.toggleAllCheckboxes}
                                                    checked={this.state.totalCheck}
                                                />
                                            </Table.HeaderCell>
                                            <Table.HeaderCell>{t('cart.type')}</Table.HeaderCell>
                                            <Table.HeaderCell>{t('cart.acquisition')}</Table.HeaderCell>
                                            <Table.HeaderCell>{t('cart.level')}</Table.HeaderCell>
                                            <Table.HeaderCell>{t('cart.area')}</Table.HeaderCell>
                                            <Table.HeaderCell>{t('cart.priority')}</Table.HeaderCell>
                                            <Table.HeaderCell>{t('cart.price')} ({this.state.currency.toLocaleUpperCase()})</Table.HeaderCell>
                                            <Table.HeaderCell>{t('cart.date')}</Table.HeaderCell>
                                            <Table.HeaderCell textAlign='center'>{t('cart.delete')}</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {list}
                                    </Table.Body>
                                    <Table.Footer>
                                        <Table.Row>
                                            <Table.HeaderCell colSpan='10'>
                                                <Button
                                                    floated='right'
                                                    icon
                                                    labelPosition='left'
                                                    positive
                                                    size='small'
                                                    onClick={this.checkoutCart}
                                                    disabled={this.props.cart && this.props.cart.images && this.props.cart.images.length === 0}
                                                >
                                                    <Icon name='shopping basket'/>{t('cart.checkout')}
                                                </Button>
                                                <div>
                                                    {t('cart.total')}:
                                                    &nbsp;
                                                    {this.getTotalPrice().kzt !== "0.00 ₸" && this.state.currency === 'kzt' &&
                                                    <Label color="red">{this.getTotalPrice().kzt}</Label>}
                                                    {this.getTotalPrice().usd !== "0.00 $" && this.state.currency === 'usd' &&
                                                    <Label color="blue">{this.getTotalPrice().usd}</Label>}
                                                </div>
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Footer>
                                    {this.props.cart && this.props.cart.images && this.props.cart.images.length === 0 && (
                                        <Table.Footer>
                                            <Table.Row>
                                                <Table.Cell colSpan='10' textAlign={'center'}>
                                                    <p>{t('cart.nothing')}.</p>
                                                </Table.Cell>
                                            </Table.Row>
                                        </Table.Footer>)}
                                </Table>
                            </div>
                        </div>
                        <Confirm
                            ref={this.confirm}
                            onCancelClicked={() => this.confirm.current.hideModal()}
                            onConfirmClicked={this.deleteItem}
                        />
                    </div>)}
            </React.Fragment>
        );
    }
}

Cart.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    getCart: PropTypes.func.isRequired,
    deleteCart: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    submitCart: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        isAuthenticated: !!state.user.token,
        user: state.user,
        cart: state.cart
    };
}

export default connect(mapStateToProps, {getCart, deleteCart, submitCart, logout})(Cart);