export const USER_LOGGED_IN = "USER_LOGGED_IN";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";
export const LAYERS_GOTTEN = "LAYERS_GOTTEN";
export const LAYERS_FAILED = "LAYERS_FAILED";
export const QUICKLOOKS_SELECTED = "QUICKLOOKS_SELECTED";
export const NEW_CART_ITEM = "NEW_CART_ITEM";
export const GET_CART = "GET_CART";
export const DELETE_CART = "GET_CART";
export const CARTS_POSTED = "CARTS_POSTED";
export const ORDERS_GOTTEN = "ORDERS_GOTTEN";
export const ORDER_GOTTEN = "ORDER_GOTTEN";
export const ORDER_CANCELLED = "ORDER_CANCELLED";
export const PRICES_GOTTEN = "PRICES_GOTTEN";
export const PRICE_CHANGED = "PRICE_CHANGED";
export const MIN_AREA_CHANGED = "MIN_AREA_CHANGED";
export const USERS_GOTTEN = "USERS_GOTTEN";
export const REQUEST_ERROR = "REQUEST_ERROR";
export const ME_UPDATED = "ME_UPDATED";
export const STORES_GOTTEN = "STORES_GOTTEN";
