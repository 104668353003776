import React, {Component} from 'react';
import {Card, Icon, List} from "semantic-ui-react";
import {connect} from "react-redux";
import { t } from '../../../lang';
import EditUser from "../../modals/UserModal";
import PropTypes from "prop-types";
import {updateMyself, getMyself} from '../../../actions/users';

class Settings extends Component {

    edit = React.createRef();

    itemClicked = (type) => {
        this.props.history.push(type);
    };

    editUser = user => {
        this.edit.current.hideModal();
        const id = user.id;
        delete user.id;
        delete user.image;
        delete user.email;
        delete user.role;
        delete user.purpose;
        delete user.image_url;
        delete user.created_at;
        delete user.updated_at;
        delete user.token;
        if(!user.gender) delete user.gender;
        this.props.updateMyself({id: id, user: user});
    };

    render() {
        const {user} = this.props;
        return (
            <div style={{padding: '2vw'}}>
                <Card fluid className="card-control">
                    <List divided relaxed>
                        <List.Item onClick={()=>this.itemClicked('/password_reset')}>
                            <List.Content>
                                <Icon name="key" />
                                &nbsp;
                                <span>{t('reset.btn_password')}</span>
                            </List.Content>
                        </List.Item>
                        <List.Item onClick={()=>this.edit.current.showModal(user)}>
                            <List.Content>
                                <Icon name="user" />
                                &nbsp;
                                <span>{t('signup.edit')} {t('signup.user')}</span>
                            </List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Content>
                                <Icon name="lock open" />
                                &nbsp;
                                <span>{t('profile.development')} (in development)</span>
                            </List.Content>
                        </List.Item>
                    </List>
                </Card>
                <EditUser
                    ref={this.edit}
                    onCancel={() => this.edit.current.hideModal()}
                    onConfirm={this.editUser}
                    isAdmin={this.props.isAdmin}
                />
            </div>
        );
    }
}

Settings.propTypes = {
    getMyself: PropTypes.func.isRequired,
    updateMyself: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
    return {
        isAuthenticated: !!state.user.token,
        isAdmin: state.user.role === 'admin',
        user: state.user
    }
};

export default connect(mapStateToProps, {updateMyself, getMyself})(Settings);