import React from 'react';
import {Route, Redirect} from 'react-router-dom'
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';


const UserRoute = ({isAuthenticated, props, component: Component, ...rest}) => (

    <Route {...rest} render={props => {
        if(!isAuthenticated && props.history.action === 'POP') {
            sessionStorage.setItem('pop_url', props.history.location.pathname);
        }
        return isAuthenticated ? <Component {...props} />:<Redirect to="/" />
    }}/>
);

UserRoute.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
    return {
        isAuthenticated: !!state.user.token
    }
}

export default connect(mapStateToProps)(UserRoute);