import React from "react";
import { Route, Redirect } from "react-router-dom";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";

const ManagerRoute = ({
  isAuthenticated,
  props,
  isUser,
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      if (!isAuthenticated && props.history.action === "POP") {
        sessionStorage.setItem("pop_url", props.history.location.pathname);
      }
      return isAuthenticated && !isUser ? (
        <Component {...props} />
      ) : (
        <Redirect to="/" />
      );
    }}
  />
);

ManagerRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  isUser: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    isAuthenticated: !!state.user.token,
    isUser: state.user.role === "user",
  };
}

export default connect(mapStateToProps)(ManagerRoute);
