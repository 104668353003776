import React from 'react';
import { SemanticToastContainer, toast } from 'react-semantic-toasts';
import 'react-semantic-toasts/styles/react-semantic-alert.css';
import {Redirect} from 'react-router-dom';
import {t} from '../../lang';

class Toast extends React.Component {
    state = {  
        redirect: false,
        link: ''
    };

    configSuccessResponse = (res) => {
        switch (res.message) {
            case 'VerifyEmailAddress':
                this.fireToast(
                    'success',
                    'envelope',
                    t(`toast.success.title_${res.message}`),
                    t(`toast.success.description_${res.message}`),
                    '/verification'
                );
                break;
            case 'EmailVerified':
                this.fireToast(
                    'success',
                    'envelope open',
                    t(`toast.success.title_${res.message}`),
                    t(`toast.success.description_${res.message}`),
                    '/signin'
                );
                break;
            case 'UserLoggedIn':
                this.fireToast(
                    'success',
                    'hand peace outline',
                    t(`toast.success.title_${res.message}`),
                    t(`toast.success.description_${res.message}`),
                    '/'
                );
                break;
            case 'VerifyEmail':
                this.fireToast(
                    'success',
                    'hand peace outline',
                    t(`toast.success.title_${res.message}`),
                    t(`toast.success.description_${res.message}`),
                    '/signin'
                );
                break;
            case 'EmailSent':
                this.fireToast(
                    'success',
                    'hand peace outline',
                    t(`toast.success.title_${res.message}`),
                    t(`toast.success.description_${res.message}`),
                    ''
                );
                break;
            case 'PasswordSet':
                this.fireToast(
                    'success',
                    'hand peace outline',
                    t(`toast.success.title_${res.message}`),
                    t(`toast.success.description_${res.message}`),
                    ''
                );
                break;
            default:
                break;
        }
    };

    configErrorResponse = error => {
        this.errorMessage(error);
    };

    errorMessage = error => {
        switch (error.message) {
            case 'VerifyEmail':
                this.fireToast(
                    'warning',
                    'envelope',
                    t(`toast.error.title_${error.message}`),
                    t(`toast.error.description_${error.message}`),
                    '/verification'
                );
                break;
            case 'UserNotVerified':
                this.fireToast(
                    'warning',
                    'envelope',
                    t(`toast.error.title_${error.message}`),
                    t(`toast.error.description_${error.message}`),
                    '/verification'
                );
                break;
            case 'UserNotFound':
                this.fireToast(
                    'error',
                    'user secret',
                    t(`toast.error.title_${error.message}`),
                    t(`toast.error.description_${error.message}`),
                    ''
                );
                break;
            case 'InvalidToken':
                this.fireToast(
                    'error',
                    'exclamation triangle',
                    t(`toast.error.title_${error.message}`),
                    t(`toast.error.description_${error.message}`),
                    '/signin'
                );
                break;
            case 'UserAlreadyVerified':
                this.fireToast(
                    'warning',
                    'alarm',
                    t(`toast.error.title_${error.message}`),
                    t(`toast.error.description_${error.message}`),
                    '/signin'
                );
                break;
            case 'UserAlreadyExists':
                this.fireToast(
                    'warning',
                    'alarm',
                    t(`toast.error.title_${error.message}`),
                    t(`toast.error.description_${error.message}`),
                    '/signin'
                );
                break;
            case 'InvalidEmail':
                this.fireToast(
                    'error',
                    'exclamation triangle',
                    t(`toast.error.title_${error.message}`),
                    t(`toast.error.description_${error.message}`),
                    ''
                );
                break;
            case 'InvalidPassword':
                this.fireToast(
                    'error',
                    'exclamation triangle',
                    t(`toast.error.title_${error.message}`),
                    t(`toast.error.description_${error.message}`),
                    ''
                );
                break;
            case 'InvalidEmailToken':
                this.fireToast(
                    'error',
                    'exclamation triangle',
                    t(`toast.error.title_${error.message}`),
                    t(`toast.error.description_${error.message}`),
                    ''
                );
                break;
            case 'NotFoundOrder':
                this.fireToast(
                    'error',
                    'exclamation triangle',
                    t(`toast.error.title_${error.message}`),
                    t(`toast.error.description_${error.message}`),
                    ''
                );
                break;
            case 'InternalServer':
                this.fireToast(
                    'error',
                    'exclamation triangle',
                    t(`toast.error.title_${error.message}`),
                    t(`toast.error.description_${error.message}`),
                    ''
                );
                break;
            case 'TooLargeArea':
                this.fireToast(
                    'error',
                    'exclamation triangle',
                    t(`toast.error.title_${error.message}`),
                    t(`toast.error.description_${error.message}`),
                    ''
                );
                break;
            case 'FormatNotAllowed':
                this.fireToast(
                    'error',
                    'exclamation triangle',
                    t(`toast.error.title_${error.message}`),
                    t(`toast.error.description_${error.message}`),
                    ''
                );
                break;
            default:
                this.fireToast(
                    'error',
                    'exclamation triangle',
                    error.message,
                    t(`toast.error.description_InternalServer`),
                    ''
                );
                break;
        }
    };

    fireToast = (type, icon, title, description, link) => {
        toast({
            type: type,
            icon: icon,
            title: title,
            description: description,
            time: 5000,
            onClose: ()=> {if(link !=='' || link !== null){this.setState({redirect: true, link: link})}}
        });
    };

    render() { 
        return ( 
            <React.Fragment>
                <SemanticToastContainer />
                {this.state.redirect && this.state.link!=='' && <Redirect to={this.state.link}/>}
            </React.Fragment>
        );
    }
}
 
export default Toast;