import { LAYERS_GOTTEN, LAYERS_FAILED} from '../types'
export default function(state = {}, action = {}) {
    switch (action.type) {
        case LAYERS_GOTTEN:
            return action.layers;
        case LAYERS_FAILED:
            return null;
        default:
            return state
    }
}