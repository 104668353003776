export default {
    ru: {
        header: `Выберите диапазон дат`,
        confirm: `Подтвердить`,
        cancel: `Отмена`,
        max90: `Максимум 90 дней`,
        max3: `Максимум 3 дня`
    },
    en: {
        header: `Select date range`,
        confirm: `Confirm`,
        cancel: `Cancel`,
        max90: `Maximum 90 days`,
        max3: `Maximum 3 days`
    }
};
