import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Pagination from 'rc-pagination';
import en_locale from 'rc-pagination/lib/locale/en_US';
import ru_locale from 'rc-pagination/lib/locale/ru_RU';
import {Icon, Table} from 'semantic-ui-react';
import './Users.css';
import {getUsers, deleteUser, getUser, updateUser} from '../../../../actions/users';
import {getLanguage, t} from '../../../../lang';
import Confirm from '../../../messages/ModalConfirm';
import UserModal from '../../../modals/UserModal';

class Users extends Component {

    state = {
        params: {
            page: 1,
            size: 10,
            order_by: 'id',
            order: 'asc'
        },
        total: 0,
        column: null
    };

    confirm = React.createRef();
    edit = React.createRef();

    componentDidMount() {
        if(this.props.isAuthenticated) this.getAllUsers();
    }

    getAllUsers = () => {
        this.props.getUsers(this.state.params)
            .then(res => {
                this.setState({total: res.total});
            });
    };

    onChange = (page) => {
        const params = this.state.params;
        params.page = page;
        this.setState({
            params: params
        });
        this.getAllUsers();
    };

    onFilterChange = (e, data) => {
        const params = this.state.params;
        params.filter = data.value;
        this.setState({params: params});
        this.getOrders();
    };

    handleSort = clickedColumn => {
        let params = this.state.params;
        if(clickedColumn === this.state.params.order_by) {
            params.order = params.order === 'asc' ? 'desc' : 'asc';
        } else {
            params.order = 'desc';
        }
        params.order_by = clickedColumn;
        this.setState( {params: params});
        this.getAllUsers();
    };

    handleDeleteUser = async (id, extra) => {
        await this.props.deleteUser(id);
        await this.confirm.current.hideModal();
        await this.getAllUsers();
    };

    handleEdit = async (e, id) => {
        await e.stopPropagation();
        const {user} = await this.props.getUser(id);
        this.edit.current.showModal(user);
    };

    editUser = user => {
        this.edit.current.hideModal();
        const id = user.id;
        delete user.id;
        delete user.image;
        delete user.image_url;
        delete user.created_at;
        delete user.updated_at;
        this.props.updateUser({id: id, user: user})
            .then(res => {
                this.getAllUsers();
            })
    };

    render() {
        const users = this.props.users;
        return (
            <div className='container'>
                <Table celled sortable selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell
                                sorted={this.state.params.order_by === 'id' ? (this.state.params.order === 'desc' ? 'descending': 'ascending') : null}
                                onClick={() => this.handleSort('id')}
                            >Id</Table.HeaderCell>
                            <Table.HeaderCell
                                sorted={this.state.params.order_by === 'last_name' ? (this.state.params.order === 'desc' ? 'descending': 'ascending') : null}
                                onClick={() => this.handleSort('last_name')}
                            >{t('users.last_name')}</Table.HeaderCell>
                            <Table.HeaderCell
                                sorted={this.state.params.order_by === 'first_name' ? (this.state.params.order === 'desc' ? 'descending': 'ascending') : null}
                                onClick={() => this.handleSort('first_name')}
                            >{t('users.first_name')}</Table.HeaderCell>
                            <Table.HeaderCell
                                sorted={this.state.params.order_by === 'email' ? (this.state.params.order === 'desc' ? 'descending': 'ascending') : null}
                                onClick={() => this.handleSort('email')}
                            >{t('users.email')}</Table.HeaderCell>
                            <Table.HeaderCell disabled
                                sorted={this.state.params.order_by === 'phone' ? (this.state.params.order === 'desc' ? 'descending': 'ascending') : null}
                                onClick={() => this.handleSort('phone')}
                            >{t('users.phone')}</Table.HeaderCell>
                            <Table.HeaderCell
                                sorted={this.state.params.order_by === 'role' ? (this.state.params.order === 'desc' ? 'descending': 'ascending') : null}
                                onClick={() => this.handleSort('role')}
                            >{t('users.role')}</Table.HeaderCell>
                            <Table.HeaderCell
                                sorted={this.state.params.order_by === 'company' ? (this.state.params.order === 'desc' ? 'descending': 'ascending') : null}
                                onClick={() => this.handleSort('company')}
                            >{t('users.company')}</Table.HeaderCell>
                            <Table.HeaderCell
                                sorted={this.state.params.order_by === 'date' ? (this.state.params.order === 'desc' ? 'descending': 'ascending') : null}
                                onClick={() => this.handleSort('date')}
                            >{t('users.date')}</Table.HeaderCell>
                            <Table.HeaderCell/>
                        </Table.Row>
                    </Table.Header>

                    {users.length > 0 && <Table.Body>
                        {users.map(user => {
                            return (
                                <Table.Row key={user.id} onClick={() => this.props.history.push(`/users/${user.id}`)}>
                                    <Table.Cell>{user.id}</Table.Cell>
                                    <Table.Cell>{user.last_name}</Table.Cell>
                                    <Table.Cell>{user.first_name}</Table.Cell>
                                    <Table.Cell>{user.email}</Table.Cell>
                                    <Table.Cell>{user.phone ? user.phone : t('users.not_defined')}</Table.Cell>
                                    <Table.Cell>{user.role}</Table.Cell>
                                    <Table.Cell>{user.company ? user.company : t('users.not_defined')}</Table.Cell>
                                    <Table.Cell>{new Date(user.created_at).toLocaleDateString()}</Table.Cell>
                                    <Table.Cell textAlign='center'>
                                        <a style={{float: 'left'}}>
                                            <Icon
                                                name="edit"
                                                onClick={e => this.handleEdit(e, user.id)}
                                            />
                                        </a>
                                        <a style={{float: 'right'}}>
                                            <Icon name="trash"
                                              onClick={e => {
                                                  e.stopPropagation();
                                                  this.confirm.current.showModal(
                                                      user.id,
                                                      t('users.delete.title'),
                                                      `${t('users.delete.content')} ${user.last_name} ${user.first_name}?`,
                                                      null, null, null
                                                  )}
                                              }/>
                                        </a>
                                    </Table.Cell>
                                </Table.Row>
                            )
                        })}
                    </Table.Body>}
                </Table>
                <Pagination
                    current={this.state.params.page}
                    total={this.state.total}
                    pageSize={this.state.params.size}
                    onChange={this.onChange}
                    showTotal={(total) => `${t('orders.total')} ${total}`}
                    showQuickJumper={{ goButton: true }}
                    locale={getLanguage()==='ru'? ru_locale : en_locale}
                    hideOnSinglePage
                />
                <Confirm
                    ref={this.confirm}
                    onCancelClicked={()=>this.confirm.current.hideModal()}
                    onConfirmClicked={this.handleDeleteUser}
                />
                <UserModal
                    ref={this.edit}
                    onCancel={()=>this.edit.current.hideModal()}
                    onConfirm={this.editUser}
                    isAdmin={this.props.isAdmin}
                />
            </div>
        );
    }
}

Users.propTypes = {
    getUsers: PropTypes.func.isRequired,
    deleteUser: PropTypes.func.isRequired,
    getUser: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
    return {
        isAuthenticated: !!state.user.token,
        users: state.users,
        isAdmin: state.user.role === 'admin'
    }
};

const mapDispatchToProps = {
    getUsers,
    deleteUser,
    getUser,
    updateUser
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
