export default {
    ru: {
        header: `Войти`,
        email: `Эл. адрес`,
        password: `Пароль`,
        forgot: `Забыли пароль`,
        remember: `Запомнить меня`,
        signin: `Войти`,
        signup: `Регистрация`,
        popup: `Если вы еще не присоединились к нам, пожалуйста, нажмите кнопку регистрации.`,
        rule: `Пароль должен содержать как минимум одну цифру, одну строчную букву, одну заглавную букву длиной не менее 8 символов.`,
        errors : {
            email: `Введите валидную почту`,
            password: `Введите валидный пароль`,
            empty: {
                email: `Почта не может быть пустым`,
                password: `Пароль не может быть пустым`,
            }
        }
    },
    en: {
        header: `Sign In`,
        email: `Email`,
        password: `Password`,
        forgot: `Forgot password`,
        remember: `Remember me`,
        signin: `Sign in`,
        signup: `Sign up`,
        popup: `If you have not joined us yet,please click the sign up button.`,
        rule: `Password must contain at least one digit, one lower case, one upper case symbols with minimum of 8 characters.`,
        errors : {
            email: `Enter valid email`,
            password: `Enter valid password`,
            empty: {
                email: `Email is empty`,
                password: `Password is empty`,
            }
        }
    }
};
