import React, { Component } from "react";
import { Button, Checkbox, Image, Label, Table, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { saveAs } from "file-saver";
import { withRouter } from "react-router-dom";
import {
  setQuicklooks,
  getStores,
  createStore,
  addQuicklooksToStore,
} from "../../../../../actions/layer";
import "./Layers.css";
import Info from "./components/Info";
import Link from "./components/Link";
import StoreList from "./components/StoreList";
import ArchiveForm from "../../../Form/ArchiveForm";
import Confirm from "../../../../messages/ModalConfirm";
import { t } from "../../../../../lang";

class Layers extends Component {
  state = {
    clicked: false,
    isAllSelected: false,
    layers: this.props.layers,
    checked: [],
    isSelected: false,
    showAll: false,
    showArchiveFormModal: false,
    clickedId: null,
  };

  modal = React.createRef();
  confirm = React.createRef();
  share = React.createRef();
  store = React.createRef();

  componentDidMount() {
    let list = [];
    if (this.props.layers.length) {
      this.props.layers.forEach((l) => {
        if (
          this.props.selectedQuicklooks &&
          this.props.selectedQuicklooks.includes(l.code)
        ) {
          list.push(true);
        } else {
          list.push(false);
        }
      });
    }
    this.setState({ checked: list });
  }

  componentDidUpdate(prevState, prevProps) {
    if (this.props.clickedLayer !== null && !this.state.clicked) {
      this.state.layers.forEach((layer) => {
        if (layer.code === this.props.clickedLayer) {
          this.modal.current.showModal();
          this.modal.current.setLayer(layer);
        }
      });
    }

    if (this.props.shareLink && this.props.shareLink !== prevProps.shareLink) {
      this.share.current.showModal();
    }
  }

  showQuicklook = (code) => {
    if (!this.state.clicked) {
      this.props.quicklooks.forEach((q) => {
        if (q.code === code) {
          this.props.onItemHover(code);
        }
      });
    }
  };

  hideQuicklook = (code) => {
    this.props.quicklooks.forEach((q) => {
      if (q.code === code) {
        this.props.onItemLeave(code);
      }
    });
  };

  quicklookClicked = (e, layer) => {
    try {
      e.stopPropagation();
      let layers = this.state.layers;
      layers.forEach((q) => {
        if (layer.code === q.code) {
          if (q.clicked) {
              e.target.style.boxShadow = "unset";
          } else {
              e.target.style.boxShadow = "0px 0px 2px 3px #4183c4";
          }
          
          layer.clicked ? (layer.clicked = false) : (layer.clicked = true);
          this.props.onQuicklookLockOn(layer.code);
        }
      });
      this.setState({ layers: layers });
    } catch (er) {
      console.log(er);
    }
  };

  toggleAllQuicklooks = (e) => {
    let layers = this.state.layers;
    layers.forEach((l) => {
      // let image = document.getElementById(`${l.code}-image`);
      // if (this.state.showAll) {
      //     image.style.boxShadow = "unset";
      // } else {
      //     image.style.boxShadow = "0px 0px 2px 3px #4183c4";
      // }
      l.clicked = !this.state.showAll;
    });
    this.props.onQuicklookLockOnAll(this.state.showAll);
    this.setState({ layers: layers, showAll: !this.state.showAll });
  };

  itemClicked = (layer) => {
    this.modal.current.showModal();
    this.modal.current.setLayer(layer);
    this.setState({ clicked: true });
  };

  modalClosed = () => {
    if (!this.state.clicked && this.props.clickedLayer)
      this.props.onClickedLayerClose();
    this.setState({ clicked: false });
    this.modal.current.closeModal();
  };

  toggleAllCheckboxes = (e) => {
    this.setState({ isAllSelected: !this.state.isAllSelected });
    setTimeout(() => {
      let list = this.state.checked;
      for (let i = 0; i < list.length; i++) {
        list[i] = this.state.isAllSelected;
      }
      this.setState({ checked: list });
      this.setState({ isSelected: this.state.isAllSelected });
    }, 1);
  };

  toggleCheckbox = (i) => {
    let list = this.state.checked;
    list[i] = !list[i];
    if (list[i] === false) {
      this.setState({ isAllSelected: false });
    }
    this.setState({ checked: list });
    this.checkChecked();
    this.checkAllChecked();
  };

  goToCart = () => {
    this.setQuicklooks();
    this.props.history.push("/cart");
  };

  hideCartModal = () => {
    this.confirm.current.hideModal();
    this.setQuicklooks();
  };

  checkChecked = () => {
    this.setState({ isSelected: false });
    this.state.checked.forEach((e) => {
      if (e === true) {
        this.setState({ isSelected: true });
      }
    });
  };
  checkAllChecked = () => {
    let check = true;
    this.state.checked.forEach((e) => {
      if (e === false) {
        check = false;
      }
    });
    if (check) {
      this.setState({ isAllSelected: true });
    }
  };

  setQuicklooks = () => {
    let selectedQuicklooks = [];
    for (let i = 0; i < this.props.layers.length; i++) {
      if (this.state.checked[i]) {
        selectedQuicklooks.push(this.state.layers[i].code);
      }
    }
    this.props.setQuicklooks(selectedQuicklooks);
  };

  toKMLFolder = (layer) => {
    const coorList = layer.geometry.coordinates;
    return `<Folder><name>${layer.code}</name><description>Date: ${
      layer.meta_date
    }, Satellite: ${
      layer.satellite
    }</description><Placemark><Polygon><outerBoundaryIs><LinearRing><coordinates>${
      coorList[1]
    },${coorList[0]},0 ${coorList[7]},${coorList[6]},0 ${coorList[5]},${
      coorList[4]
    },0 ${coorList[3]},${coorList[2]},0 ${coorList[1]},${
      coorList[0]
    },0</coordinates></LinearRing></outerBoundaryIs></Polygon></Placemark><GroundOverlay><Icon><href>${window
      .location.href +
      layer.quicklook_url}</href></Icon><gx:LatLonQuad xmlns:gx="http://www.google.com/kml/ext/2.2"><coordinates>${
      coorList[1]
    },${coorList[0]},0 ${coorList[7]},${coorList[6]},0 ${coorList[5]},${
      coorList[4]
    },0 ${coorList[3]},${coorList[2]},0 ${coorList[1]},${
      coorList[0]
    },0</coordinates></gx:LatLonQuad></GroundOverlay></Folder>`;
  };

  downloadKml = () => {
    let selectedLayers = "";
    for (let i = 0; i < this.props.layers.length; i++) {
      if (this.state.checked[i]) {
        selectedLayers += this.toKMLFolder(this.state.layers[i]);
      }
    }
    const kml = `<?xml version="1.0" encoding="UTF-8"?><kml xmlns="http://www.opengis.net/kml/2.2"><Folder><name>Quicklooks: from ${this.props.dateFrom}, to ${this.props.dateTo}</name>${selectedLayers}</Folder></kml>`;
    const file = new Blob([kml], { type: "text/xml" });
    saveAs(file, `${new Date()}.kml`);
  };

  onShareModalClosed = () => {
    this.setState({ openShareModal: false });
  };

  saveToStoreClicked = () => {
    this.props.getStores();
    this.store.current.showModal();
  };

  createNewStoreClicked = (data) => {
    return this.props.createStore(data).then((res) => res);
  };

  saveQuicklooks = (id) => {
    const data = {
      id,
      satellite_images: this.getSelectedQuicklooks().map((q) => q.id),
    };
    this.props.addQuicklooksToStore(data).then((res) => {
      this.store.current.onSuccess(res && res.success);
    });
  };

  getSelectedQuicklooks = () => {
    return this.props.layers.filter((q, i) => {
      if (this.state.checked[i]) return q;
    });
  };

  render() {
    let list = [];

    if (this.state.layers && this.state.layers.length > 0) {
      this.state.layers.forEach((layer) => {

        list.push(
          <Table.Row
            positive={layer.clicked}
            style={{ display: "inline-block !important" }}
            className="item"
            key={layer.code}
            onMouseEnter={() => this.showQuicklook(layer.code)}
            onMouseLeave={() => this.hideQuicklook(layer.code)}
          >
            <Table.Cell className="checkbox">
              <Checkbox
                checked={this.state.checked[this.state.layers.indexOf(layer)]}
                onChange={() =>
                  this.toggleCheckbox(this.state.layers.indexOf(layer))
                }
              />
            </Table.Cell>
            <Table.Cell onClick={() => this.itemClicked(layer)}>
              <Image
                id={layer.code + "-image"}
                className="preview"
                onClick={(e) => this.quicklookClicked(e, layer)}
                src={layer.quicklook_url}
              />
            </Table.Cell>
            <Table.Cell
              onClick={() => this.itemClicked(layer)}
              title={layer.satellite}
              style={{ whiteSpace: "nowrap" }}
            >
              {layer.satellite}
            </Table.Cell>
            <Table.Cell onClick={() => this.itemClicked(layer)}>
              {layer.cloud_coverage ? layer.cloud_coverage : t("orders.not_defined")}
            </Table.Cell>
            <Table.Cell onClick={() => this.itemClicked(layer)}>
              {layer.meta_date}
            </Table.Cell>
            <Table.Cell textAlign="right">
              {this.props.isAuthenticated && (
                <Button
                  id={layer.code + "2"}
                  icon="plus cart"
                  className="info"
                  title={t("sidebar.layers.cart")}
                  onClick={() =>
                    this.setState({
                      showArchiveFormModal: true,
                      clickedId: layer.id,
                    })
                  }
                />
              )}
            </Table.Cell>
            <Info ref={this.modal} onModalClosed={this.modalClosed} />
          </Table.Row>
        );
      });
    } else {
      list.push(
        <Table.Row key="nothing" style={{ textAlign: "center" }}>
          <Table.Cell singleLine>{t("sidebar.layers.nothing")}</Table.Cell>
        </Table.Row>
      );
    }
    return (
      <React.Fragment>
        {this.state.layers && (
          <>
            <Table fixed compact selectable basic="very" id="layers_table">
              {this.state.layers && this.state.layers.length > 0 && (
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell className="checkbox">
                      <Checkbox
                        checked={this.state.isAllSelected}
                        onChange={this.toggleAllCheckboxes}
                      />
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      style={{ textAlign: "left", cursor: "pointer" }}
                    >
                      {this.props.layers && this.props.layers.length > 0 && (
                        <Label
                          color={this.state.showAll ? "green" : "blue"}
                          style={{ textAlign: "center" }}
                          className="preview"
                          id="layer_badge"
                          onClick={this.toggleAllQuicklooks}
                        >
                          {this.props.layers.length}
                        </Label>
                      )}
                    </Table.HeaderCell>
                    <Table.HeaderCell title={t("sidebar.layers.satellite")}>
                      {t("sidebar.layers.satellite")}
                    </Table.HeaderCell>
                    <Table.HeaderCell title={t("sidebar.layers.cloud")}>
                      {t("sidebar.layers.cloud")}
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      className="date-row"
                      title={t("sidebar.layers.date")}
                    >
                      {t("sidebar.layers.date")}
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ display: "flex" }}>
                      <Button
                        positive={this.state.isSelected}
                        style={{ marginLeft: "auto" }}
                        id="kml"
                        disabled={!this.state.isSelected}
                        onClick={this.downloadKml}
                        icon="download"
                        title="KML"
                      />
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
              )}
              <Table.Body>{list}</Table.Body>
            </Table>
            {this.state.layers.length > 0 && !this.props.storeMode && (
              <div className="footer-btns">
                {this.props.isAuthenticated && (
                  <Button
                    className="btn-store"
                    title={t("sidebar.layers.save")}
                    primary
                    disabled={!this.state.isSelected}
                    onClick={this.saveToStoreClicked}
                  >
                    {t("sidebar.layers.save")}
                  </Button>
                )}
                {!this.props.shareMode && (
                  <Button
                    className="btn-share"
                    title={t("sidebar.layers.share")}
                    positive
                    onClick={this.props.onShareClicked}
                  >
                    {t("sidebar.layers.share")}
                  </Button>
                )}
              </div>
            )}
          </>
        )}
        <ArchiveForm
          id={this.state.clickedId}
          isModalActive={this.state.showArchiveFormModal}
          onModalClosed={(type) => {
            this.setState({ showArchiveFormModal: false });
            if (type)
              this.confirm.current.showModal(
                this.state.clickedId,
                t("sidebar.layers.title"),
                t("sidebar.layers.description"),
                t("sidebar.layers.confirm"),
                t("sidebar.layers.cancel"),
                null
              );
          }}
        />
        <Confirm
          ref={this.confirm}
          onCancelClicked={this.hideCartModal}
          onConfirmClicked={this.goToCart}
        />
        <Link
          ref={this.share}
          url={this.props.shareLink}
          onShareModalClose={this.props.onShareModalClose}
        />
        <StoreList
          ref={this.store}
          store={this.props.store.reverse()}
          onCreateStore={this.createNewStoreClicked}
          saveClicked={this.saveQuicklooks}
        />
      </React.Fragment>
    );
  }
}

Layers.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  setQuicklooks: PropTypes.func.isRequired,
  selectedQuicklooks: PropTypes.array,
  getStores: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    isAuthenticated: !!state.user.token,
    layers: state.layer,
    selectedQuicklooks: state.quicklooks,
    store: state.store,
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    { setQuicklooks, getStores, createStore, addQuicklooksToStore }
  )(Layers)
);
