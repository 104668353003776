import React, { Component } from "react";
import { List, Icon, Header } from "semantic-ui-react";
import { connect } from "react-redux";
import { getStores, deleteStore } from "../../../actions/layer";
import "./Store.css";
import { t } from "../../../lang";
import Loader from "../Loader/Loader";
import ModalConfirm from "../../messages/ModalConfirm";

class Store extends Component {
  state = {
    loading: true,
    currentStore: null,
  };

  modal = React.createRef();
  confirm = React.createRef();

  componentDidMount() {
    this.props.getStores().then((res) => {
      if (res) this.setState({ loading: false });
    });
  }

  itemClicked = (id) => {
    this.props.history.push(`/store/${id}`);
  };

  deleteStoreClicked = (id) => {
    this.deleteStore(id);
    this.confirm.current.hideModal();
  };

  deleteStore = (id) => {
    this.props.deleteStore(id).then((res) => {
      if (res && res.success) this.props.getStores();
    });
  };

  render() {
    return (
      <>
        <List divided verticalAlign="middle">
          {this.props.store &&
            this.props.store.map((s, i) => (
              <List.Item key={s.id}>
                <List.Content
                  floated="left"
                  onClick={() => this.itemClicked(s.id)}
                >
                  {s.isPublic ? (
                    <Icon
                      name="unlock"
                      size="large"
                      style={{ lineHeight: 1.5 }}
                    />
                  ) : (
                    <Icon
                      name="lock"
                      size="large"
                      style={{ lineHeight: 1.5 }}
                    />
                  )}
                </List.Content>
                <List.Content floated="right">
                  <Icon
                    name="trash"
                    className="delete-store"
                    onClick={() =>
                      this.confirm.current.showModal(
                        s.id,
                        t("cart.header"),
                        t("cart.content")
                      )
                    }
                  />
                </List.Content>
                <List.Content
                  floated="right"
                  style={{ color: "gray" }}
                  onClick={() => this.itemClicked(s.id)}
                >
                  <span>{new Date(s.created_at).toLocaleDateString()}</span>
                </List.Content>
                <List.Content onClick={() => this.itemClicked(s.id)}>
                  <List.Header as="a">{s.title}</List.Header>
                  <List.Description>
                    {s.description ? (
                      s.description
                    ) : (
                      <em style={{ color: "lightgray" }}>
                        {t("sidebar.store.no_desc")}
                      </em>
                    )}
                  </List.Description>
                </List.Content>
              </List.Item>
            ))}
          {this.props.store && this.props.store.length === 0 && (
            <List.Item>
              <Header
                as="h3"
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  color: "grey",
                }}
              >
                {t("sidebar.store.no_content")}
              </Header>
            </List.Item>
          )}
        </List>
        {this.state.loading && <Loader />}
        <ModalConfirm
          ref={this.confirm}
          onConfirmClicked={this.deleteStoreClicked}
          onCancelClicked={() => this.confirm.current.hideModal()}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    isAuthenticated: !!state.user.token,
    store: state.store,
  };
}

export default connect(
  mapStateToProps,
  { getStores, deleteStore }
)(Store);
