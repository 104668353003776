export default {
  en: {
    order_new: `Order New`,
    signin: `Sign in`,
    signup: `Sign up`,
    profile: `Profile`,
    cart: `Cart`,
    orders: `Orders`,
    control: `Controls`,
    store: `Quicklooks`,
    logout: `Log out`,
  },
  ru: {
    order_new: `Заказать новый`,
    signin: `Войти`,
    signup: `Регистрация`,
    profile: `Профиль`,
    cart: `Корзина`,
    orders: `Заказы`,
    control: `Управление`,
    store: `Снимки`,
    logout: `Выход`,
  },
};
