export default {
    en: {
        header_email: `Reset your password`,
        header_password: `Change password for`,
        content_email: `Enter email to reset your password.`,
        check_email: `Check your email for a link to reset your password. If it doesn’t appear within a few minutes, check your spam folder.`,
        btn_email: `Send password reset email`,
        btn_sign_in: `Return to sign in`,
        btn_password: `Change password`,
        confirm_password: `Confirm password`,
        current_password: `Current password`,
        repeat: `New password must not be the same as old one.`,
    },
    ru: {
        header_email: `Сбросить пароль`,
        header_password: `Изменить пароль на`,
        content_email: `Введите почту, чтобы сбросить пароль.`,
        check_email: `Проверьте свою электронную почту, чтобы найти ссылку для сброса пароля. Если он не появится в течение нескольких минут, проверьте папку со спамом.`,
        btn_email: `Отправить пароль для сброса пароля`,
        btn_sign_in: `Вернуться, чтобы войти`,
        btn_password: `Изменить пароль`,
        confirm_password: `Подтвердите пароль`,
        current_password: `Текущий пароль`,
        repeat: `Новый пароль не должен совпадать со старым.`,
    }
};
