import datepicker from "./datepicker";
import messages from "./messages";
import new_form from "./new_form";
import profile from "./profile";
import sidebar from "./sidebar";
import control from "./control";
import pricing from "./pricing";
import signin from "./signin";
import navbar from "./navbar";
import signup from "./signup";
import orders from "./orders";
import toast from "./toast";
import users from "./users";
import image from "./image";
import reset from "./reset";
import demo from "./demo";
import cart from "./cart";

export default {
    languages: {
        ru: {
            datepicker: datepicker.ru,
            messages: messages.ru,
            new_form: new_form.ru,
            profile: profile.ru,
            sidebar: sidebar.ru,
            control: control.ru,
            pricing: pricing.ru,
            signin: signin.ru,
            navbar: navbar.ru,
            signup: signup.ru,
            orders: orders.ru,
            toast: toast.ru,
            users: users.ru,
            image: image.ru,
            reset: reset.ru,
            demo: demo.ru,
            cart: cart.ru,
        },
        en: {
            datepicker: datepicker.en,
            messages: messages.en,
            new_form: new_form.en,
            profile: profile.en,
            sidebar: sidebar.en,
            control: control.en,
            pricing: pricing.en,
            signin: signin.en,
            navbar: navbar.en,
            signup: signup.en,
            orders: orders.en,
            toast: toast.en,
            users: users.en,
            image: image.en,
            reset: reset.en,
            demo: demo.en,
            cart: cart.en,
        }
    }
};
