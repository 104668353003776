import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  Segment,
  Button,
  Header,
  List,
  Image,
  Icon,
  Form,
  Checkbox,
} from "semantic-ui-react";
import { t } from "../../../../lang";
import {
  getStore,
  deleteSatelliteImage,
  updateStore,
} from "../../../../actions/layer";
import Info from "../../Map/Sidebar/Layers/components/Info";
import ModalConfirm from "../../../messages/ModalConfirm";
import InlineError from "../../../messages/InlineError";
import Link from "../../Map/Sidebar/Layers/components/Link";
import Loader from "../../Loader/Loader";

class SingleStore extends Component {
  state = {
    store: null,
    editMode: false,
    title: "",
    description: "",
    isPublic: true,
    loading: true,
    link: "",
  };

  info = React.createRef();
  confirm = React.createRef();
  link = React.createRef();

  componentWillMount() {
    const id = this.props.history.location.pathname.split("/").pop();
    if (id) this.getStore(id);
  }

  getStore = (id) => {
    this.props.getStore(id).then((res) => {
      if (res && res.store)
        this.setState({
          store: res.store,
          loading: false,
          link: `/stores/share/` + res.store.uuid,
        });
    });
  };

  showInfo = (image) => {
    this.info.current.setLayer(image);
    this.info.current.showModal();
  };

  deleteImageClicked = (id) => {
    this.deleteImage(id);
    this.confirm.current.hideModal();
  };

  deleteImage = (id) => {
    const store_id = this.state.store.id;
    this.props.deleteSatelliteImage({ store_id, image_id: id }).then((res) => {
      if (res && res.success) this.getStore(store_id);
    });
  };

  editModeOn = () => {
    this.setState({
      editMode: true,
      title: this.state.store.title,
      description: this.state.store.description,
      isPublic: this.state.store.isPublic,
    });
  };
  editModeOff = () => {
    this.setState({
      editMode: false,
      title: "",
      description: "",
      isPublic: true,
    });
  };

  handleChange(e) {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
    });
  }

  updateStore = () => {
    const { title, description, isPublic } = this.state;
    const params = {
      title,
      description,
      isPublic,
    };

    const id = this.state.store.id;

    this.props.updateStore({ id, params }).then((res) => {
      if (res && res.success) {
        this.getStore(id);
        this.editModeOff();
      }
    });
  };

  render() {
    const { store, editMode, link } = this.state;
    return (
      <>
        {store && (
          <>
            <Container>
              <span
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Button
                  style={{ flex: 0.2 }}
                  primary
                  onClick={() => this.props.history.push(link)}
                >
                  {t("sidebar.store.map")}
                </Button>
                {store.isPublic && (
                  <Button
                    positive
                    style={{ flex: 0.2 }}
                    onClick={() => this.link.current.showModal()}
                  >
                    {t("sidebar.layers.share")}
                  </Button>
                )}
              </span>

              {!editMode && (
                <Segment>
                  <span style={{ display: "flex" }}>
                    <Header as="h3" style={{ flex: 1 }}>
                      {store.title}
                    </Header>
                    <Icon
                      link
                      name="edit outline"
                      className="edit-store"
                      onClick={this.editModeOn}
                    />
                  </span>

                  <p>
                    {store.description ? (
                      store.description
                    ) : (
                      <em style={{ color: "lightgrey" }}>
                        {t("sidebar.store.no_desc")}
                      </em>
                    )}
                  </p>
                  <span style={{ color: "grey", display: "flex" }}>
                    <span style={{ flex: 1 }}>
                      {new Date(store.created_at).toLocaleDateString()}
                    </span>
                    {store.isPublic ? (
                      <Icon style={{ margin: 0 }} name="unlock" />
                    ) : (
                      <Icon style={{ margin: 0 }} name="lock" />
                    )}
                  </span>
                </Segment>
              )}
              {editMode && (
                <Segment>
                  <span style={{ display: "flex" }}>
                    <Form style={{ flex: 1 }}>
                      <Form.Field error={!!!this.state.title}>
                        <label>{t("sidebar.layers.store.title")}</label>
                        <input
                          placeholder={t("sidebar.layers.store.title")}
                          name="title"
                          value={this.state.title}
                          onChange={(e) => this.handleChange(e)}
                        />
                        {!!!this.state.title && (
                          <InlineError text={t("sidebar.layers.store.empty")} />
                        )}
                      </Form.Field>
                      <Form.Field>
                        <label>{t("sidebar.layers.store.description")}</label>
                        <Form.TextArea
                          placeholder={t("sidebar.layers.store.description")}
                          name="description"
                          value={this.state.description}
                          onChange={(e) => this.handleChange(e)}
                        />
                      </Form.Field>
                      <Form.Field>
                        <Checkbox
                          label={t("sidebar.layers.store.private")}
                          style={{ paddingLeft: 0 }}
                          checked={!this.state.isPublic}
                          onChange={() =>
                            this.setState({ isPublic: !this.state.isPublic })
                          }
                        />
                      </Form.Field>
                    </Form>
                    &nbsp;
                    <span>
                      <Icon
                        link
                        name="check"
                        className="edit-save"
                        disabled={!!!this.state.title}
                        onClick={this.updateStore}
                      />
                      <Icon
                        link
                        name="cancel"
                        className="edit-delete"
                        onClick={this.editModeOff}
                      />
                    </span>
                  </span>
                </Segment>
              )}
              <Header as="h4">{t("sidebar.store.quicklooks")}:</Header>
              <Segment>
                <List>
                  {store.satellite_images.map((image) => (
                    <List.Item key={image.id}>
                      <Image
                        onClick={() => this.showInfo(image)}
                        floated="left"
                        avatar
                        src={window.location.origin + image.quicklook_url}
                      />
                      <List.Content floated="right" style={{ color: "grey" }}>
                        {image.meta_date} &nbsp;
                        <Icon
                          name="trash"
                          className="delete-store"
                          onClick={() =>
                            this.confirm.current.showModal(
                              image.id,
                              t("cart.header"),
                              t("cart.content")
                            )
                          }
                        />
                      </List.Content>
                      <List.Content onClick={() => this.showInfo(image)}>
                        <List.Header>{image.code}</List.Header>
                        <List.Description>{image.satellite}</List.Description>
                      </List.Content>
                    </List.Item>
                  ))}
                </List>
              </Segment>
            </Container>
            <Info
              ref={this.info}
              onModalClosed={() => this.info.current.closeModal()}
            />
            <ModalConfirm
              ref={this.confirm}
              onConfirmClicked={this.deleteImageClicked}
              onCancelClicked={() => this.confirm.current.hideModal()}
            />
            <Link
              ref={this.link}
              url={window.location.origin + link}
              onShareModalClose={() => {}}
            />
          </>
        )}
        {this.state.loading && <Loader />}
      </>
    );
  }
}

export default connect(
  null,
  { getStore, deleteSatelliteImage, updateStore }
)(SingleStore);
