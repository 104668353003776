import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import en_locale from 'rc-pagination/lib/locale/en_US';
import ru_locale from 'rc-pagination/lib/locale/ru_RU';
import {Table, Select} from "semantic-ui-react";
import './Orders.css';
import { getAllOrders } from '../../../actions/orders';
import { t, getLanguage } from '../../../lang';

class Orders extends Component {

    state = {
        params: {
            page: 1,
            size: 10,
            filter: 'all',
            order: 'desc'
        },
        total: 0,
        filters: [
            { key: 1, value: 'all', text: t('orders.all') },
            { key: 2, value: 'canceled', text: t('orders.cancelled') },
            { key: 3, value: 'processed', text: t('orders.done') },
            { key: 4, value: 'notprocessed', text: t('orders.processing') }
        ]
    };

    componentWillMount() {
        this.getOrders()
    }

    onChange = (page) => {
        const params = this.state.params;
        params.page = page;
        this.setState({
            params: params
        });
        this.props.getAllOrders(this.state.params)
            .then(res => {
                this.setState({total: res.total});
            });
    };

    getOrders = () => {
        this.props.getAllOrders(this.state.params)
            .then(res => {
                this.setState({total: res.total});
            });
    };

    orderClicked = (order) => {
        this.props.history.push(`/orders/${order.id}`);
    };

    onFilterChange = (e, data) => {
        const params = this.state.params;
        params.filter = data.value;
        this.setState({params: params});
        this.getOrders();
    };

    render() {

        const { orders } = this.props;
        const list = [];
        if (orders.length) orders.forEach((order, index) => {
                list.push(<Table.Row
                    key={index}
                    negative={order.is_canceled}
                    positive={order.is_done}
                    onClick={() => this.orderClicked(order)}
                >
                    <Table.Cell>{order.id}</Table.Cell>
                    <Table.Cell>{order.area} km<sup>2</sup></Table.Cell>
                    <Table.Cell>{parseFloat(order.price_kzt).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} ₸</Table.Cell>
                    <Table.Cell>{parseFloat(order.price_usd).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} $</Table.Cell>
                    <Table.Cell>{order.is_done ? t('orders.done') : order.is_canceled ? t('orders.cancelled') : t('orders.processing')}</Table.Cell>
                    <Table.Cell>
                        {new Date(order.created_at).toLocaleDateString()}
                    </Table.Cell>
                </Table.Row>);
            });

        return (
            <React.Fragment>
                <div id="container">
                    <div className="orders-wrapper">
                        <div className="filter">
                            <span>
                                {t('orders.filter')}:
                                &nbsp;
                                <Select
                                    name="filter"
                                    value={this.state.params.filter}
                                    onChange={this.onFilterChange}
                                    options={this.state.filters}
                                />
                            </span>
                        </div>
                        <Table celled compact definition selectable>
                            <Table.Header fullWidth>
                                <Table.Row>
                                    <Table.HeaderCell>ID</Table.HeaderCell>
                                    <Table.HeaderCell>{t('orders.area')}</Table.HeaderCell>
                                    <Table.HeaderCell>{t('orders.price')} (KZT)</Table.HeaderCell>
                                    <Table.HeaderCell>{t('orders.price')} (USD)</Table.HeaderCell>
                                    <Table.HeaderCell>{t('orders.status')}</Table.HeaderCell>
                                    <Table.HeaderCell>{t('orders.date')}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {list}
                            </Table.Body>
                        </Table>
                        <Pagination
                            current={this.state.params.page}
                            total={this.state.total}
                            pageSize={this.state.params.size}
                            onChange={this.onChange}
                            showTotal={(total) => `${t('orders.total')} ${total}`}
                            showQuickJumper={{ goButton: true }}
                            locale={getLanguage()==='ru'? ru_locale : en_locale}
                            hideOnSinglePage
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

Orders.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
    return {
        isAuthenticated: !!state.user.token,
        orders: state.orders
    }
};

export default connect(mapStateToProps, {getAllOrders})(Orders);
