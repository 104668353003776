import api from "../api";
import {requestHasError} from "./error";
import {PRICES_GOTTEN, PRICE_CHANGED, MIN_AREA_CHANGED} from "../types";

export const pricesGotten = (data) => ({
    type: PRICES_GOTTEN,
    prices: data.prices,
});

export const priceChanged = (data) => ({
    type: PRICE_CHANGED
});

export const minAreaChanged = (data) => ({
    type: MIN_AREA_CHANGED
});

export const getPrices = () => dispatch => {
    return api.price.get_prices().then(res => {
        if (!res.success) dispatch(requestHasError(res));
        if(res.success) dispatch(pricesGotten(res));
    });
};

export const changePrice = data => dispatch =>  {
    return api.price.set_price(data).then(res => {
        if(!res.success) dispatch(requestHasError(res));
        if(res.success) dispatch(priceChanged(res));
    });
};

export const changeMinArea = data => dispatch =>  {
    return api.price.set_min_area(data).then(res => {
        if(!res.success) dispatch(requestHasError(res));
        if(res.success) dispatch(minAreaChanged(res));
    });
};