export default {
    en: {
        confirm: {
            cancel: `Cancel`,
            confirm: `Confirm`
        },
        loading: `Loading`
    },
    ru: {
        confirm: {
            cancel: `Отмена`,
            confirm: `Подтвердить`
        },
        loading: `Загрузка`
    }
};