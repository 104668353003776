import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Redirect, Route } from "react-router-dom";
import { withRouter } from "react-router";

import SignIn from "./components/pages/Auth/SignIn";
import SignUp from "./components/pages/Auth/SignUp";
import Verification from "./components/pages/Auth/Verification";
import ResetPassword from "./components/pages/Auth/ResetPassword";
import Map from "./components/pages/Map/Map";
import Cart from "./components/pages/Cart/Cart";
import NewForm from "./components/pages/Form/NewForm";
import Profile from "./components/pages/Profile/Profile";
import Orders from "./components/pages/Orders/Orders";
import Order from "./components/pages/Orders/Order";
import Settings from "./components/pages/Profile/Settings";
import Control from "./components/pages/Control/Control";
import Store from "./components/pages/Store/Store";
import SingleStore from "./components/pages/Store/components/SingleStore";

import PriceList from "./components/pages/Control/PriceList/PriceList";
import UserRoute from "./components/routes/UserRoute";
import GuestRoute from "./components/routes/GuestRoute";
import ManagerRoute from "./components/routes/ManagerRoute";
import Navbar from "./components/pages/Navbar/Navbar";
import Users from "./components/pages/Control/Users/Users";
import User from "./components/pages/Control/Users/User";
import { toggleLanguage } from "./lang";
import Toast from "./components/messages/Toast";
import { errorWasShown } from "./actions/error";

class App extends React.Component {
  state = {
    lang: "ru",
  };

  toaster = React.createRef();

  toggleLang = () => {
    this.setState({ lang: toggleLanguage() });
  };

  componentDidMount() {}

  async componentDidUpdate(prevState, prevProps) {
    if (
      this.props.error &&
      prevProps.error !== this.props.error &&
      this.props.error.hasOwnProperty("message")
    ) {
      await this.toaster.current.configErrorResponse(this.props.error);
      await this.props.errorWasShown();
    }
  }

  render() {
    const { location } = this.props;
    return (
      <div>
        <Navbar toggleLang={this.toggleLang} lang={this.state.lang} />
        <div style={{ paddingTop: "52.31px" }}>
          <GuestRoute
            location={location}
            path="/signin"
            exact
            component={withRouter(SignIn)}
          />
          <GuestRoute
            location={location}
            path="/signup"
            exact
            component={withRouter(SignUp)}
          />
          <GuestRoute
            location={location}
            path="/verification"
            exact
            component={withRouter(Verification)}
          />
          <GuestRoute
            location={location}
            path="/verification/:token"
            exact
            component={withRouter(Verification)}
          />
          <Route
            location={location}
            path="/password_reset"
            exact
            component={withRouter(ResetPassword)}
          />
          <GuestRoute
            location={location}
            path="/password_reset/:token"
            exact
            component={withRouter(ResetPassword)}
          />
          <GuestRoute
            location={location}
            path="/"
            exact
            component={withRouter(Map)}
          />
          <UserRoute
            location={location}
            path="/"
            exact
            component={withRouter(Map)}
          />
          <UserRoute
            location={location}
            path="/form"
            exact
            component={withRouter(NewForm)}
          />
          <UserRoute
            location={location}
            path="/cart"
            exact
            component={withRouter(Cart)}
          />
          <UserRoute
            location={location}
            path="/profile"
            exact
            component={withRouter(Profile)}
          />
          <UserRoute
            location={location}
            path="/orders/:id"
            exact
            component={withRouter(Order)}
          />
          <UserRoute
            location={location}
            path="/settings/"
            exact
            component={withRouter(Settings)}
          />
          <ManagerRoute
            location={location}
            path="/orders"
            exact
            component={withRouter(Orders)}
          />
          <ManagerRoute
            location={location}
            path="/control"
            exact
            component={withRouter(Control)}
          />
          <ManagerRoute
            location={location}
            path="/pricelist"
            exact
            component={withRouter(PriceList)}
          />
          <ManagerRoute
            location={location}
            path="/users"
            exact
            component={withRouter(Users)}
          />
          <ManagerRoute
            location={location}
            path="/users/:id"
            exact
            component={withRouter(User)}
          />
          <Route
            location={location}
            path="/search-history/share/:code"
            exact
            component={withRouter(Map)}
          />
          <UserRoute
            location={location}
            path="/store"
            exact
            component={withRouter(Store)}
          />
          <UserRoute
            location={location}
            path="/store/:id"
            exact
            component={withRouter(SingleStore)}
          />
          <UserRoute
            location={location}
            path="/stores/share/:code"
            exact
            component={withRouter(Map)}
          />
          {/*<Redirect to="/" />*/}
          <Toast ref={this.toaster} />
        </div>
      </div>
    );
  }
}

App.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  errorWasShown: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    isAuthenticated: !!state.user.token,
    error: state.error,
  };
}

export default connect(
  mapStateToProps,
  { errorWasShown }
)(App);
