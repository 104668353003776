import axios from "axios";
// FIXME: change to this then pushing
// axios.defaults.baseURL = "/api/v1";
axios.defaults.baseURL = "http://test-eo.gharysh.kz/api/v1";

export default {
  user: {
    login: (credentials) =>
      axios
        .post("/users/session", credentials)
        .then((res) => {
          if (res && res.data) {
            return res.data;
          }
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            return err.response.data;
          }
        }),
    logout: (token) =>
      axios
        .delete("/users/session", token)
        .then((res) => res.data)
        .catch((err) => err.response.data),
    signup: (user) =>
      axios
        .post("/users", user)
        .then((res) => {
          if (res && res.data) {
            return res.data;
          }
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            return err.response.data;
          }
        }),
    verify: (token) =>
      axios({
        url: "/users/wf",
        method: "POST",
        params: { upn: token },
      })
        .then((res) => res.data)
        .catch((err) => err.response.data),
    resend: (email) =>
      axios({
        url: "/users/mail",
        method: "POST",
        params: { email: email },
      })
        .then((res) => res.data)
        .catch((err) => err.response.data),
    users: (params) =>
      axios
        .get("/users/all", { params: params })
        .then((res) => res.data)
        .catch((err) => err.response.data),
    user: (id) =>
      axios
        .get(`/users/all/${id}`)
        .then((res) => res.data)
        .catch((err) => err.response.data),
    delete: (id) =>
      axios
        .delete(`/users/all/${id}`)
        .then((res) => res.data)
        .catch((err) => err.response.data),
    update: (data) =>
      axios
        .patch(`/users/all/${data.id}`, data.user)
        .then((res) => res.data)
        .catch((err) => err.response.data),
    me_get: (id) =>
      axios
        .get(`/users/${id}`)
        .then((res) => res.data)
        .catch((err) => err.response.data),
    me_update: (data) =>
      axios
        .patch(`/users/${data.id}`, data.user)
        .then((res) => res.data)
        .catch((err) => err.response.data),
    me_update_image: (data) =>
      axios
        .patch(`/users/${data.id}/image`, data.image, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => res.data)
        .catch((err) => err.response.data),
    me_delete_image: (data) =>
      axios
        .delete(`/users/${data.id}/image`)
        .then((res) => res.data)
        .catch((err) => err.response.data),
    email: (email) =>
      axios
        .post(`/users/password_reset/mail?email=${email}`)
        .then((res) => res.data)
        .catch((err) => err.response.data),
    reset: (data) =>
      axios
        .post(`/users/password_reset/?upn=${data.token}`, {
          password: data.password,
        })
        .then((res) => res.data)
        .catch((err) => err.response.data),
  },
  layer: {
    layers: (data, type) =>
      axios
        .post(type === "demo" ? "/areas/search/demo" : "/areas/search", data)
        .then((res) => res.data)
        .catch((err) => err.response.data),
    share_link: (data) =>
      axios
        .post("/search-history", data)
        .then((res) => res.data)
        .catch((err) => err.response.data),
    data_from_url: (url) =>
      axios
        .get(url)
        .then((res) => res.data)
        .catch((err) => err.response.data),
    get_stores: () =>
      axios
        .get("/stores")
        .then((res) => res.data)
        .catch((err) => err.response.data),
    get_store: (id) =>
      axios
        .get(`/stores/${id}`)
        .then((res) => res.data)
        .catch((err) => err.response.data),
    create_store: (data) =>
      axios
        .post("/stores", data)
        .then((res) => res.data)
        .catch((err) => err.response.data),
    add_to_store: (data) =>
      axios
        .post(`/stores/${data.id}/satellite_images`, {
          satellite_images: data.satellite_images,
        })
        .then((res) => res.data)
        .catch((err) => err.response.data),
    del_sat_img: (data) =>
      axios
        .delete(`/stores/${data.store_id}/satellite_images/${data.image_id}`)
        .then((res) => res.data)
        .catch((err) => err.response.data),
    update_store: (data) =>
      axios
        .patch(`/stores/${data.id}`, data.params)
        .then((res) => res.data)
        .catch((err) => err.response.data),
    delete_store: (data) =>
      axios
        .delete(`/stores/${data}`)
        .then((res) => res.data)
        .catch((err) => err.response.data),
    get_store_uuid: (url) =>
      axios
        .get(url)
        .then((res) => res.data)
        .catch((err) => err.response.data),
  },
  cart: {
    cart_archive: (data) =>
      axios
        .post("/cart/archive", data)
        .then((res) => res.data)
        .catch((err) => err.response.data),
    cart_new: (data) =>
      axios
        .post("/cart/new", data)
        .then((res) => res.data)
        .catch((err) => err.response.data),
    cart: () =>
      axios
        .get("/cart")
        .then((res) => res.data)
        .catch((err) => err.response.data),
    delete_cart: (data) =>
      axios
        .delete("/cart/images/" + data.id)
        .then((res) => res.data)
        .catch((err) => err.response.data),
    submit_cart: () =>
      axios
        .post("/cart/order")
        .then((res) => res.data)
        .catch((err) => err.response.data),
  },
  order: {
    get_orders: (data) =>
      axios
        .get("/orders", { params: data })
        .then((res) => res.data)
        .catch((err) => err.response.data),
    get_orders_all: (data) =>
      axios
        .get("/orders/all", { params: data })
        .then((res) => res.data)
        .catch((err) => err.response.data),
    cancel_order: (data) =>
      axios
        .post(`/order/${data}/cancel`)
        .then((res) => res.data)
        .catch((err) => err.response.data),
    get_order: (data) =>
      axios
        .get(`/orders/all/${data}`)
        .then((res) => res.data)
        .catch((err) => err.response.data),
    get_user_order: (data) =>
      axios
        .get(`/orders/${data}`)
        .then((res) => res.data)
        .catch((err) => err.response.data),
    cancel_manager_order: (data) =>
      axios
        .post(`/orders/all/${data.id}/cancel`, { reason: data.reason })
        .then((res) => res.data)
        .catch((err) => err.response.data),
    approve_manager_order: (data) =>
      axios
        .post(`/orders/all/${data.id}/done`, { response: data.response })
        .then((res) => res.data)
        .catch((err) => err.response.data),
    add_download: (data) =>
      axios
        .post(
          `/orders/all/${data.order_id}/images/${data.image_id}/downloads`,
          {
            user_id: data.user_id,
          }
        )
        .then((res) => res.data)
        .catch((err) => err.response.data),
  },
  price: {
    get_prices: () =>
      axios
        .get("/prices")
        .then((res) => res.data)
        .catch((err) => err.response.data),
    set_price: (data) =>
      axios
        .patch(`/prices/${data.id}`, {
          [data.currency === "kzt" ? "price_kzt" : "price_usd"]: data.price,
        })
        .then((res) => res.data)
        .catch((err) => err.response.data),
    set_min_area: (data) => {
      const body = {};
      body[Object.keys(data)[2]] = Object.values(data)[2];
      return axios
        .patch(
          `/prices/min_areas?acquisition_mode=${data.params.acquisition_mode}&image_source=${data.params.image_source}&satellite=${data.params.satellite}`,
          body
        )
        .then((res) => res.data)
        .catch((err) => err.response.data);
    },
  },
};
