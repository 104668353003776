import {ME_UPDATED, USER_LOGGED_IN, USER_LOGGED_OUT} from '../types';
import api from '../api';
import axios from 'axios';
import {requestHasError} from "./error";

export const userLoggedIn = (user) => ({
    type: USER_LOGGED_IN,
    user: user,
});

export const userLoggedOut = () => ({
    type: USER_LOGGED_OUT
});

export const myselfUpdated = (data) => ({
    type: ME_UPDATED,
    user: data,
});

export const login = (data, isRemember) => dispatch =>
    api.user.login(data)
        .then(data => {
            if(data && data.success){
                if(isRemember){
                    localStorage.token = data.auth_token;
                    data.user.token = data.auth_token;
                    localStorage.user = JSON.stringify(data.user);
                } else {
                    sessionStorage.token = data.auth_token;
                    data.user.token = data.auth_token;
                    sessionStorage.user = JSON.stringify(data.user);
                }
                axios.defaults.headers.common['Authorization'] = `Bearer ${data.auth_token}`;
                dispatch(userLoggedIn(data.user));
            }
            if(data && !data.success) dispatch(requestHasError(data));
            sessionStorage.removeItem('demo');
            return data;
        });

export const signup = data => dispatch =>
    api.user.signup(data).then(res => {
        sessionStorage.removeItem('demo');
        if(!res.success) dispatch(requestHasError(res));
        if(res) return res;
    });

export const logout = token => dispatch => {
    return api.user.logout(token)
        .then(res => {
            if (localStorage.token) {
                localStorage.removeItem('token');
                localStorage.removeItem('user');
            } else if (sessionStorage.token) {
                sessionStorage.removeItem('token');
                sessionStorage.removeItem('user');
            }
            delete axios.defaults.headers.common['Authorization'];
            dispatch(userLoggedOut());
            sessionStorage.removeItem('demo');
            sessionStorage.removeItem('pop_url');
        });
};

export const logoutInvalidToken = () => dispatch => {
    if (localStorage.token) {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
    } else if (sessionStorage.token) {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('user');
    }
    delete axios.defaults.headers.common['Authorization'];
    dispatch(userLoggedOut());
    sessionStorage.removeItem('demo');
    sessionStorage.removeItem('pop_url');
};

export const verify = token => dispatch =>
    api.user.verify(token).then(res => {
        if(!res.success) dispatch(requestHasError(res));
        return res;
    });
export const resend = email => dispatch =>
    api.user.resend(email).then(res => {
        if(!res.success) dispatch(requestHasError(res));
        return res;
    });

export const sendEmail = email => dispatch =>
    api.user.email(email).then(res => {
        if(!res.success) dispatch(requestHasError(res));
        return res;
    });

export const resetPassword = data => dispatch =>
    api.user.reset(data).then(res => {
        if(!res.success) dispatch(requestHasError(res));
        return res;
    });