export default {
    en: {
        header: `Select a photo`,
        text: `Drag 'n' drop a file here, or click to select a file`,
        cancel: `Cancel`,
        confirm: `Confirm`,
        errors: {
            size: `Image size should not be larger than 2 mb`,
            type: `Only images are allowed!`
        },
        replace: `Replace`,
        delete: `Delete`,
        add: `Add`
    },
    ru: {
        header: `Выберите фото`,
        text: `Перетащите сюда файл или нажмите, чтобы выбрать файл`,
        cancel: `Cancel`,
        confirm: `Confirm`,
        errors: {
            size: `Размер изображения не должен превышать 2 мб`,
            type: `Только изображения разрешены!`
        },
        replace: `Заменить`,
        delete: `Удалить`,
        add: `Добавить`
    }
};
