import React from 'react';
import {Button, Card, Icon, Image, Table} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import en_locale from 'rc-pagination/lib/locale/en_US';
import ru_locale from 'rc-pagination/lib/locale/ru_RU';
import Select from '../../Profile/components/Select';
import Confirm from '../../../messages/ModalConfirm';
import './User.css';
import {cancelOrder, getOrders} from '../../../../actions/orders';
import {getUser, updateUser} from '../../../../actions/users';
import { t, getLanguage } from '../../../../lang';
import EditUser from "../../../modals/UserModal";


class User extends React.Component {

    state = {
        params: {
            page: 1,
            size: 10,
            order: 'desc',
            filter: 'all'
        },
        total: 0,
        user: null
    };

    select = React.createRef();
    confirm = React.createRef();
    edit = React.createRef();

    showSelectModal = () => {
        this.select.current.showModal();
    };

    componentDidMount() {
        if (this.props.match.params.id && this.props.isAuthenticated) {
            this.props.getUser(this.props.match.params.id)
                .then(res => {
                    this.setState({user: res.user})
                })
        }
        // this.props.getOrders(this.state.params)
        //     .then(res => {
        //         this.setState({total: res.total});
        //     });
    }

    cancelOrderClicked = (id, item) => {
        this.props.cancelOrder({
            id: id,
            params: this.state.params
        }).then((res) => {
            if (res.success) this.confirm.current.hideModal();
        });
    };

    onChange = (page) => {
        const params = this.state.params;
        params.page = page;
        this.setState({
            params: params
        });
        this.props.getOrders(this.state.params)
            .then(res => {
                this.setState({total: res.total});
            });
    };

    editUser = user => {
        this.edit.current.hideModal();
        const id = user.id;
        delete user.id;
        delete user.image;
        delete user.image_url;
        delete user.created_at;
        delete user.updated_at;
        this.props.updateUser({id: id, user: user})
            .then(res => {
                this.props.getUser(id)
                    .then(res => {
                        this.setState({user: res.user})
                    })
            })
    };

    render() {
        const {orders} = this.props;
        const {user} = this.state;
        let ordersList = [];
        if(orders.length > 0) {
            ordersList = orders.map((order, index) => {
                return (<Table.Row
                    key={index}
                    negative={order.is_canceled}
                    positive={order.is_done}
                    onClick={()=>this.props.history.push(`/orders/${order.id}`)}
                >
                    <Table.Cell>{order.id}</Table.Cell>
                    <Table.Cell>{order.area} km<sup>2</sup></Table.Cell>
                    <Table.Cell>{parseFloat(order.price_kzt).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} ₸</Table.Cell>
                    <Table.Cell>{parseFloat(order.price_usd).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} $</Table.Cell>
                    <Table.Cell>{order.is_done ? t('profile.done') : order.is_canceled ? t('profile.cancelled') : t('profile.processing')}</Table.Cell>
                    <Table.Cell>
                        {new Date(order.created_at).toLocaleDateString()}
                    </Table.Cell>
                    {/*<Table.Cell selectable textAlign='center'>*/}
                    {/*    {!order.is_canceled && !order.is_done && <Icon name='cancel' color='red'*/}
                    {/*       onClick={() => this.confirm.current.showModal(*/}
                    {/*           order.id,*/}
                    {/*           `Cancel order ${order.id}?`,*/}
                    {/*           `Are you sure you want to cancel this order? This action can't be undone!`,*/}
                    {/*           `Confirm`,*/}
                    {/*           `Cancel`,*/}
                    {/*           order*/}
                    {/*       )}*/}
                    {/*    />}*/}
                    {/*</Table.Cell>*/}
                </Table.Row>)
            });
        }
        return (
            <React.Fragment>
                <div id="profile-container">
                    {/*<Button onClick={this.showSelectModal} color='blue'>Cloud selection instrument</Button>*/}
                    {user && <Card className="card" fluid>
                        <Card.Content className="user-container">
                            <Image className="user-image" circular
                                   src={require("../../../../assets/images/noavatar.webp")}/>
                            <div className="user-wrapper">
                                <a><Icon className="edit" size="large" name="edit outline" style={{float: 'right'}} onClick={()=>this.edit.current.showModal(user)}/></a>
                                <div className="user-name">{user.last_name} {user.first_name}</div>
                                <div className="user-email">{user.email}</div>
                                <div className="user-buttons">
                                    <Button icon labelPosition="left" size="large" primary style={{float: 'left'}}>
                                        <Icon name="history"/>
                                        {t('profile.orders')}
                                    </Button>
                                    <Button icon labelPosition="left" size="large" negative>
                                        <Icon name="percent"/>
                                        {t('profile.coupons')}
                                    </Button>
                                    <Button icon labelPosition="left" size="large" positive style={{float: 'right'}}>
                                        <Icon name="users"/>
                                        {t('profile.partners')}
                                    </Button>
                                </div>
                            </div>
                        </Card.Content>
                    </Card>}
                    <Card className="card orders" fluid>
                        <Card.Content header={t('profile.history')} />
                        <Card.Content style={{padding: 'unset'}}>
                            <Table selectable celled style={{border: 'unset'}}>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>ID</Table.HeaderCell>
                                        <Table.HeaderCell>{t('profile.area')}</Table.HeaderCell>
                                        <Table.HeaderCell>{t('profile.price')} (KZT)</Table.HeaderCell>
                                        <Table.HeaderCell>{t('profile.price')} (USD)</Table.HeaderCell>
                                        <Table.HeaderCell>{t('profile.status')}</Table.HeaderCell>
                                        <Table.HeaderCell>{t('profile.date')}</Table.HeaderCell>
                                        {/*<Table.HeaderCell>Cancel</Table.HeaderCell>*/}
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {ordersList}
                                </Table.Body>
                            </Table>
                        </Card.Content>
                        {this.state.total > this.state.params.size && <Card.Content extra>
                            {/*<Icon name='dollar' position='right'/>Total: 12345*/}
                            <Pagination
                                current={this.state.params.page}
                                total={this.state.total}
                                pageSize={this.state.params.size}
                                onChange={this.onChange}
                                showTotal={(total) => `${t('profile.total')} ${total}`}
                                showQuickJumper={{ goButton: true }}
                                locale={ getLanguage() === 'ru' ? ru_locale : en_locale }
                                hideOnSinglePage
                                showLessItems
                            />
                        </Card.Content>}
                    </Card>
                </div>
                <Select ref={this.select}/>
                <Confirm
                    ref={this.confirm}
                    onConfirmClicked={this.cancelOrderClicked}
                />
                <EditUser
                    ref={this.edit}
                    onCancel={() => this.edit.current.hideModal()}
                    onConfirm={this.editUser}
                    isAdmin={this.props.isAdmin}
                />
            </React.Fragment>
        );
    }
}

User.propTypes = {
    getOrders: PropTypes.func.isRequired,
    cancelOrder: PropTypes.func.isRequired,
    getUser: PropTypes.func.isRequired,
    updateUser: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: !!state.user.token,
        isAdmin: state.user.role === 'admin',
        orders: state.orders
    }
};

export default connect(mapStateToProps, {getOrders, cancelOrder, getUser, updateUser})(User);