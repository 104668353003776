export const leaflet_russian = (L) => {
    L.drawLocal = {
        draw: {
            toolbar: {
                actions: {
                    title: 'Прекратить рисование', // 'Cancel drawing',
                    text: 'Отмена'
                },
                finish: {
                    title: 'Завершить рисование',
                    text: 'Закончить'
                },
                undo: {
                    title: 'Удалить последнюю нарисованную точку',
                    text: 'Удалить последнюю точку' // 'Delete last point'
                },
                buttons: {
                    polyline: 'Нарисовать линию', // 'Draw a polyline',
                    polygon: 'Нарисовать полигон', // 'Draw a polygon',
                    rectangle: 'Нарисовать прямоугольник', // 'Draw a rectangle',
                    circle: 'Нарисовать окружность', // 'Draw a circle',
                    marker: 'Нарисовать маркер', // 'Draw a marker'
                    circlemarker: 'Нарисуйте круговой маркер'
                }
            },
            handlers: {
                circle: {
                    tooltip: {
                        start: 'Нажмите и перетащите, чтобы нарисовать круг.'
                    },
                    radius: 'Радиус'
                },
                circlemarker: {
                    tooltip: {
                        start: 'Нажмите на карту, чтобы разместить маркер круга.'
                    }
                },
                marker: {
                    tooltip: {
                        start: 'Нажмите на карту, чтобы разместить маркер.'
                    }
                },
                polygon: {
                    tooltip: {
                        start: 'Нажмите, чтобы начать рисовать фигуру.',
                        cont: 'Нажмите, чтобы продолжить рисование фигуры.',
                        end: 'Нажмите на первую точку, чтобы закрыть эту форму.'
                    }
                },
                polyline: {
                    error: '<strong>Ошибка:</strong> части линии не должны пересекаться!',
                    tooltip: {
                        start: 'Кликните на карту для начала рисования',// 'Click to start drawing line.',
                        cont: 'Кликните для продолжения рисования',// 'Click to continue drawing line.',
                        end: 'Кликните на последнюю вершину для завершения линии'// 'Click last point to finish line.'
                    }
                },
                rectangle: {
                    tooltip: {
                        start: 'Кликните и потяните для создания прямоугольника'// 'Click and drag to draw rectangle.'
                    }
                },
                simpleshape: {
                    tooltip: {
                        end: 'Отпустите кнопку мыши для окончания рисования'// 'Release mouse to finish drawing.'
                    }
                }
            }
        },
        edit: {
            toolbar: {
                actions: {
                    save: {
                        title: 'Сохранить изменения',
                        text: 'Сохранить'
                    },
                    cancel: {
                        title: 'Отменить редактирование, отменить все изменения',
                        text: 'Отмена'
                    },
                    clearAll: {
                        title: 'Очистить все слои',
                        text: 'Очистить все'
                    }
                },
                buttons: {
                    edit: 'Редактировать слои',
                    editDisabled: 'Нет слоев для редактирования',
                    remove: 'Удалить слои',
                    removeDisabled: 'Нет слоев для удаления'
                }
            },
            handlers: {
                edit: {
                    tooltip: {
                        text: 'Перетащите маркеры или маркеры для редактирования объектов.',
                        subtext: 'Нажмите Отмена, чтобы отменить изменения.'
                    }
                },
                remove: {
                    tooltip: {
                        text: 'Нажмите на функцию, чтобы удалить.'
                    }
                }
            }
        }
    }
};
export const leaflet_english =(L) => {
    L.drawLocal = {
        draw: {
            toolbar: {
                // #TODO: this should be reorganized where actions are nested in actions
                // ex: actions.undo  or actions.cancel
                actions: {
                    title: 'Cancel drawing',
                    text: 'Cancel'
                },
                finish: {
                    title: 'Finish drawing',
                    text: 'Finish'
                },
                undo: {
                    title: 'Delete last point drawn',
                    text: 'Delete last point'
                },
                buttons: {
                    polyline: 'Draw a polyline',
                    polygon: 'Draw a polygon',
                    rectangle: 'Draw a rectangle',
                    circle: 'Draw a circle',
                    marker: 'Draw a marker',
                    circlemarker: 'Draw a circlemarker'
                }
            },
            handlers: {
                circle: {
                    tooltip: {
                        start: 'Click and drag to draw circle.'
                    },
                    radius: 'Radius'
                },
                circlemarker: {
                    tooltip: {
                        start: 'Click map to place circle marker.'
                    }
                },
                marker: {
                    tooltip: {
                        start: 'Click map to place marker.'
                    }
                },
                polygon: {
                    tooltip: {
                        start: 'Click to start drawing shape.',
                        cont: 'Click to continue drawing shape.',
                        end: 'Click first point to close this shape.'
                    }
                },
                polyline: {
                    error: '<strong>Error:</strong> shape edges cannot cross!',
                    tooltip: {
                        start: 'Click to start drawing line.',
                        cont: 'Click to continue drawing line.',
                        end: 'Click last point to finish line.'
                    }
                },
                rectangle: {
                    tooltip: {
                        start: 'Click and drag to draw rectangle.'
                    }
                },
                simpleshape: {
                    tooltip: {
                        end: 'Release mouse to finish drawing.'
                    }
                }
            }
        },
        edit: {
            toolbar: {
                actions: {
                    save: {
                        title: 'Save changes',
                        text: 'Save'
                    },
                    cancel: {
                        title: 'Cancel editing, discards all changes',
                        text: 'Cancel'
                    },
                    clearAll: {
                        title: 'Clear all layers',
                        text: 'Clear All'
                    }
                },
                buttons: {
                    edit: 'Edit layers',
                    editDisabled: 'No layers to edit',
                    remove: 'Delete layers',
                    removeDisabled: 'No layers to delete'
                }
            },
            handlers: {
                edit: {
                    tooltip: {
                        text: 'Drag handles or markers to edit features.',
                        subtext: 'Click cancel to undo changes.'
                    }
                },
                remove: {
                    tooltip: {
                        text: 'Click on a feature to remove.'
                    }
                }
            }
        }
    };
};