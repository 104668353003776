export default {
  ru: {
    search: `Поиск`,
    tabs: {
      filter: `Фильтр`,
      tools: `Зона интереса`,
    },
    filter: {
      type: `Тип`,
      date: `Дата`,
      cloud: `Процент облачности`,
      pitch: `Тангаж`,
      roll: `Крен`,
      yaw: `Рыскание`,
      sun: `Солнечный Азимут`,
      reset: `Сброс`,
      advanced: `Расширенный поиск`,
      not_for_kazeosat_1: `(Не доступно для KazEOSat-1)`,
    },
    tools: {
      point: `Точка`,
      square: `Квадрат`,
      rectangle: `Прямоугольник`,
      polygon: `Полигон`,
      upload: `Загрузить`,
    },
    layers: {
      nothing: `Ничего не найдено`,
      date: `Дата`,
      cloud: `Облачность`,
      satellite: `Спутник`,
      cart: `Добавить в корзину`,
      title: `Продолжить или остаться?`,
      description: `Изображение было добавлено в корзину. Вы хотите остаться или пойти в корзину?`,
      cancel: `Остаться`,
      confirm: `Продолжить`,
      share: `Поделиться`,
      copy_link: `Скопировать ссылку`,
      save: `Сохранить`,
      saved: `Сохраненные снимки`,
      store: {
        title: `Название`,
        description: `Описание`,
        create_new: `Создать новый`,
        cancel_new: `Отменить создание`,
        save: "Сохранить",
        create: `Создать`,
        cancel: `Отмена`,
        empty: `Название обязательно!`,
        private: `Приватный`,
        public: `Публичный`,
      },
    },
    info: {
      description: `Описание`,
      date: `Дата`,
      link: `Ссылка`,
      pitch: `Тангаж`,
      roll: `Крен`,
      yaw: `Рыскание`,
      cloud: `Облачность`,
      download: `Скачать KML`,
      close: `Закрыть`,
    },
    store: {
      no_desc: `Без описания`,
      quicklooks: `Список сохраненных снимков`,
      map: `Посмотреть на карте`,
      no_content: `У вас еще нет сохраненных снимков`,
    },
  },
  en: {
    search: `Search`,
    tabs: {
      filter: `Filter`,
      tools: `Area of interest`,
    },
    filter: {
      type: `Type`,
      date: `Date`,
      cloud: `Cloud Percentage`,
      pitch: `Pitch`,
      roll: `Roll`,
      yaw: `Yaw`,
      sun: `Solar Azimuth`,
      reset: `Reset`,
      advanced: `Advanced search`,
      not_for_kazeosat_1: `(Not available for KazEOSat-1)`,
    },
    tools: {
      point: `Point`,
      square: `Square`,
      rectangle: `Rectangle`,
      polygon: `Polygon`,
      upload: `Upload`,
    },
    layers: {
      nothing: `Nothing found`,
      date: `Date`,
      cloud: `Cloud`,
      satellite: `Satellite`,
      cart: `Add to cart`,
      title: `Proceed or Stay?`,
      description: `The image was added to the cart. Do you wish to stay or go to cart?`,
      cancel: `Stay`,
      confirm: `Proceed`,
      share: `Share`,
      copy_link: `Copy link`,
      save: `Save`,
      saved: `Saved quicklooks`,
      store: {
        title: `Title`,
        description: `Description`,
        create_new: `Create new`,
        cancel_new: `Cancel create`,
        save: "Save",
        create: `Create`,
        cancel: `Cancel`,
        empty: `title is required!`,
        private: `Private`,
        public: `Public`,
      },
    },
    info: {
      description: `Description`,
      date: `Date`,
      link: `Link`,
      pitch: `Pitch`,
      roll: `Roll`,
      yaw: `Yaw`,
      cloud: `Cloud Percentage`,
      download: `Download KML`,
      close: `Close`,
    },
    store: {
      no_desc: `No description`,
      quicklooks: `List of saved quicklooks`,
      map: `See on map`,
      no_content: `You don't have any saved quicklooks yet`,
    },
  },
};
