export default {
    en: {
        header: `Welcome to Sunkarsat`,
        signin: `Sign In`,
        signup: `Sign Up`,
        continue: `Continue`,
        text1: `You are currently in demonstration mode with restricted functionality.`,
        text2: `If you want to enjoy full access, please sign in or sign up.`
    },
    ru: {
        header: `Добро пожаловать в Сункарсат`,
        signin: `Войти`,
        signup: `Регистрация`,
        continue: `Продолжить`,
        text1: `Вы находитесь в режиме демо с ограниченными функциональными возможностями.`,
        text2: `Если вы хотите получить полный доступ, пожалуйста, войдите или зарегистрируйтесь.`
    }
};
