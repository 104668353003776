import React, { Component } from 'react'
import { Button, Header, Icon, Modal } from 'semantic-ui-react'
import {withRouter} from 'react-router-dom'
import { t } from '../../lang';

class Demo extends Component {

    state = {
        open: true
    };

    closeModal = () => {
        this.setState({open: false})
    };

    goTo = (type) => {
        this.props.history.push(type)
    };

    demoClicked = () => {
        sessionStorage.setItem('demo', true);
        this.closeModal();
    };
    render() {
        const { open } = this.state;
        return (
        <Modal
            style={{width: 'auto'}}
            open={open} 
            onClose={this.closeModal}
            closeOnDimmerClick={false}
        >
            <Header icon='archive' content={t('demo.header')} />
            <Modal.Content>
                <p>
                    {t('demo.text1')}
                </p>
                <p>
                    {t('demo.text2')}
                </p>
            </Modal.Content>
            <Modal.Actions>
                <Button color='red' onClick={() => this.goTo('/signin')}>
                    <Icon name='sign-in' /> {t('demo.signin')}
                </Button>
                <Button color='blue' onClick={() => this.goTo('/signup')}>
                    <Icon name='sign-in alternate' /> {t('demo.signup')}
                </Button>
                <Button color='green' onClick={this.demoClicked}>
                    <Icon name='checkmark'/> {t('demo.continue')}
                </Button>
            </Modal.Actions>
        </Modal>
        )
    }
}

export default withRouter(Demo);