import React, { Component } from "react";
import { Animated } from "react-animated-css";
import { Tab, Icon, MenuItem, Button } from "semantic-ui-react";
import "./Tabs.css";
import Filter from "./Filter/Filter";
import Tools from "./Tools/Tools";
import Layers from "./Layers/Layers";
import { t } from "../../../../lang";

export default class Tabs extends Component {
  state = {
    active: 0,
  };

  componentDidMount() {
    let filterTab = document.getElementById("tabs").children[0].children[0];
    filterTab.addEventListener("click", () => {
      if (this.props.drawn) {
        if (this.state.active !== 0) {
          this.setState({ active: 0 });
        } else {
          this.setState({ active: -1 });
        }
      } else {
        this.setState({ active: 0 });
      }
    });
    let toolsTab = document.getElementById("tabs").children[0].children[1];
    toolsTab.addEventListener("click", () => {
      if (this.props.drawn) {
        if (this.state.active !== 1) {
          this.setState({ active: 1 });
        } else {
          this.setState({ active: -1 });
        }
      } else {
        this.setState({ active: 1 });
      }
    });
  }

  render() {
    const panes = [
      {
        menuItem: (
          <MenuItem key={0}>
            {t("sidebar.tabs.filter")}
            {this.props.drawn && (
              <Icon
                className="tab-icon"
                name={this.state.active === 0 ? "caret up" : "caret down"}
              />
            )}
          </MenuItem>
        ),
        render: () => (
          <Tab.Pane id="filter">
            <Filter
              satellite={this.props.satellite}
              dateFrom={this.props.dateFrom}
              dateTo={this.props.dateTo}
              cloud={this.props.cloud}
              pitch={this.props.pitch}
              roll={this.props.roll}
              yaw={this.props.yaw}
              sun={this.props.sun}
              onTypeChosen={this.props.onTypeChosen}
              onDateChosen={this.props.onDateChosen}
              onPitchChosen={this.props.onPitchChosen}
              onRollChosen={this.props.onRollChosen}
              onYawChosen={this.props.onYawChosen}
              onCloudChosen={this.props.onCloudChosen}
              onSunChosen={this.props.onSunChosen}
              onResetClicked={this.props.onResetClicked}
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: (
          <MenuItem key={1}>
            {t("sidebar.tabs.tools")}
            {this.props.drawn && (
              <Icon
                className="tab-icon"
                name={this.state.active === 1 ? "caret up" : "caret down"}
              />
            )}
          </MenuItem>
        ),
        render: () => (
          <Tab.Pane>
            <Tools onDrawShape={this.props.onDrawShape} />
          </Tab.Pane>
        ),
      },
    ];

    return (
      <div>
        {!this.props.storeMode && (
          <Tab
            id="tabs"
            activeIndex={this.state.active}
            menu={{ secondary: true, pointing: true }}
            panes={panes}
          />
        )}
        {this.state.active === -1 && !this.props.storeMode && (
          <Layers
            onQuicklookLockOnAll={this.props.onQuicklookLockOnAll}
            onQuicklookLockOn={this.props.onQuicklookLockOn}
            onItemLeave={this.props.onItemLeave}
            onItemHover={this.props.onItemHover}
            quicklooks={this.props.quicklooks}
            dateFrom={this.props.dateFrom}
            dateTo={this.props.dateTo}
            clickedLayer={this.props.clickedLayer}
            shareLink={this.props.shareLink}
            onClickedLayerClose={this.props.onClickedLayerClose}
            onShareClicked={this.props.onShareClicked}
            onShareModalClose={this.props.onShareModalClose}
            shareMode={this.props.shareMode}
          />
        )}
        {this.props.storeMode && (
          <Layers
            onQuicklookLockOnAll={this.props.onQuicklookLockOnAll}
            onQuicklookLockOn={this.props.onQuicklookLockOn}
            onItemLeave={this.props.onItemLeave}
            onItemHover={this.props.onItemHover}
            quicklooks={this.props.quicklooks}
            dateFrom={this.props.dateFrom}
            dateTo={this.props.dateTo}
            clickedLayer={this.props.clickedLayer}
            shareLink={this.props.shareLink}
            onClickedLayerClose={this.props.onClickedLayerClose}
            onShareClicked={this.props.onShareClicked}
            onShareModalClose={this.props.onShareModalClose}
            shareMode={this.props.shareMode}
            storeMode={this.props.storeMode}
          />
        )}
      </div>
    );
  }
}
