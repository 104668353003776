import React from 'react';
import { Button, Modal, Input, Image, Popup } from 'semantic-ui-react';
import './Select.css';
import MagicWand from '../../../../assets/js/magic-wand-min';
import $ from 'jquery'
import classifyPoint  from 'robust-point-in-polygon';

class Select extends React.Component {
    state = { 
        isModalActive: false,
        colorThreshold: 5,
        blurRadius: 1,
        simplifyTolerant: 0,
        simplifyCount: 30,
        hatchLength: 4,
        hatchOffset: 0,
        imageInfo: null,
        cacheInd: null,     
        mask: null,
        downPoint: null,
        upPoint: null,
        allowDraw: false,
        currentThreshold: null,
        isDrawing: false,
        cloudPercentage: null,
        shiftPressed: false,
        mode: 'none',
        prevPoint: null,
        points: [],
        isZoomed: false,
        offset: {},
        originalImageInfo: null,
        revert: false
    }

    componentDidMount(){
        this.setState({currentThreshold: this.state.colorThreshold});
        
        document.addEventListener('keydown', (e) => {
            if(e.key === 'Shift'){
                if(this.state.mode === 'wand') {
                    document.getElementsByClassName('canvas')[0].style.cursor = `url(${require('../../../../assets/images/magic-wand-plus.webp')}), auto`;
                } else if(this.state.mode === 'lasso') {
                    document.getElementsByClassName('canvas')[0].style.cursor = `url(${require('../../../../assets/images/lasso-plus.webp')}), auto`;
                } else if(this.state.mode === 'zoom') {
                    document.getElementsByClassName('canvas')[0].style.cursor = `url(${require('../../../../assets/images/zoom-out.webp')}), auto`;
                }
                    this.setState({shiftPressed: true})
                }
            
        });
        document.addEventListener('keyup', (e) => {
            if(e.key === 'Shift'){
                if(this.state.mode === 'wand') {
                    document.getElementsByClassName('canvas')[0].style.cursor = `url(${require('../../../../assets/images/magic-wand.webp')}), auto`;
                } else if(this.state.mode === 'lasso') {
                    document.getElementsByClassName('canvas')[0].style.cursor = `url(${require('../../../../assets/images/lasso.webp')}), auto`;
                } else if(this.state.mode === 'zoom') {
                    document.getElementsByClassName('canvas')[0].style.cursor = `url(${require('../../../../assets/images/zoom-in.webp')}), auto`;
                }
                this.setState({shiftPressed: false})
            }
            if(e.key === 'Enter' && this.state.mask && this.state.isDrawing){
                this.trace()
            }
        });
    }

    showModal = () => {
        this.setState({isModalActive: true});
    }

    showThreshold = () => {
        document.getElementById("threshold").innerHTML = "<strong>Threshold:</strong> " + this.state.currentThreshold;
    };

    canvasEnter = (e) => {
        switch (this.state.mode) {
            case 'none':
                document.getElementsByClassName('canvas')[0].style.cursor = `initial`;
                break;
            case 'wand':
                document.getElementsByClassName('canvas')[0].style.cursor = `url(${require('../../../../assets/images/magic-wand.webp')}), auto`;
                break;
            case 'lasso':
                document.getElementsByClassName('canvas')[0].style.cursor = `url(${require('../../../../assets/images/lasso.webp')}), auto`;
                break;
            case 'zoom':
                document.getElementsByClassName('canvas')[0].style.cursor = `url(${require('../../../../assets/images/zoom-in.webp')}), auto`;
                break;
            default:
                break;
        }
    }

    initCanvas = (e) => {
        let cvs = document.getElementById("resultCanvas");
        let img = e.target;
        cvs.width = img.offsetWidth;
        cvs.height = img.offsetHeight;
        this.state.imageInfo = {
                width: img.offsetWidth,
                height: img.offsetHeight,
                context: cvs.getContext("2d")
            }
        this.setState({mask: null});
        
        let tempCtx = document.createElement("canvas").getContext("2d");
        tempCtx.canvas.width = this.state.imageInfo.width;
        tempCtx.canvas.height = this.state.imageInfo.height;
        tempCtx.drawImage(img, 0, 0);
        this.state.imageInfo.data = tempCtx.getImageData(0, 0, this.state.imageInfo.width, this.state.imageInfo.height);
        // if(this.state.originalImageInfo === null) {
        //     this.state.originalImageInfo = this.state.imageInfo;
        // }
    };

    getMousePosition = (e) => {
        let x = Math.round((e.clientX || e.pageX) - $(e.target).offset().left),
            y = Math.round((e.clientY || e.pageY) - $(e.target).offset().top);
        return { x: x, y: y };
    };

    drawMask = (x, y) => {
        if (!this.state.imageInfo) return;
        
        this.showThreshold();
        var image = {
            data: this.state.imageInfo.data.data,
            width: this.state.imageInfo.width,
            height: this.state.imageInfo.height,
            bytes: 4
        };
        if(!this.state.shiftPressed) {
            if (this.state.mode === 'wand') {
                this.state.mask = MagicWand.floodFill(image, x, y, this.state.currentThreshold);
                this.state.mask = MagicWand.gaussBlurOnlyBorder(this.state.mask, this.state.blurRadius);
                
            } else if(this.state.mode === 'none') {
                this.state.mask = MagicWand.floodFill(image, x, y, this.state.currentThreshold);
            } else if(this.state.mode === 'lasso') {
                this.drawLasso({x, y})
            }
        } else if(this.state.shiftPressed && this.state.mask) {
            if (this.state.mode === 'wand') {
                let tempMask = MagicWand.floodFill(image, x, y, this.state.currentThreshold);
                tempMask = MagicWand.gaussBlurOnlyBorder(tempMask, this.state.blurRadius);
                tempMask.data.forEach((e, i) => {
                    if(e===1){
                        this.state.mask.data[i] = 1;
                    }
                });
            } else if(this.state.mode === 'none') {
                this.state.mask = MagicWand.floodFill(image, x, y, this.state.currentThreshold);
            } else if(this.state.mode === 'lasso') {
                this.drawLasso({x, y})
                this.drawBorder();
            }
        }
        this.drawBorder();
    };

    drawBorder = (noBorder) => {
        if (!this.state.mask) return;
        let x,y,i,j,
            w = this.state.imageInfo.width,
            h = this.state.imageInfo.height,
            ctx = this.state.imageInfo.context,
            imgData = ctx.createImageData(w, h),
            res = imgData.data;
        
        if (!noBorder) this.state.cacheInd = MagicWand.getBorderIndices(this.state.mask);
        
        ctx.clearRect(0, 0, w, h);
        let len = this.state.cacheInd.length;
        for (j = 0; j < len; j++) {
            i = this.state.cacheInd[j];
            x = i % w; // calc x by index
            y = (i - x) / w; // calc y by index
            let k = (y * w + x) * 4; 
            if ((x + y + this.state.hatchOffset) % (this.state.hatchLength * 2) < this.state.hatchLength) { // detect hatch color 
                res[k + 3] = 255; // black, change only alpha
            } else {
                res[k] = 255; // white
                res[k + 1] = 255;
                res[k + 2] = 255;
                res[k + 3] = 255;
            }
        }
        
        ctx.putImageData(imgData, 1, 1);
        
    };

    drawLasso = (point) => {
        
        let w = this.state.imageInfo.width;
        if(!this.state.mask) {
            let w = this.state.imageInfo.width,
            h = this.state.imageInfo.height,
            ctx = this.state.imageInfo.context;
            this.state.mask = ctx.createImageData(w, h);
        }

        if(this.state.prevPoint) {
            let lineArray = this.drawLine(this.state.prevPoint, point);
            lineArray.forEach(el => {
                this.state.points.push([el.x, el.y])
                this.state.mask.data[w*el.y+el.x] = 1;
            })
        }

        // let lineArray = this.drawLine(this.state.downPoint, point);
        // lineArray.forEach(el => {
        //     this.state.points.push([el.x, el.y])
        //     this.state.mask.data[w*el.y+el.x] = 1;
        // })

        this.setState({prevPoint: point})
    }
    
    drawLine = (startCoordinates, endCoordinates) => {
        var coordinatesArray = [];
        // Translate coordinates
        var x1 = startCoordinates.x;
        var y1 = startCoordinates.y;
        var x2 = endCoordinates.x;
        var y2 = endCoordinates.y;
        // Define differences and error check
        var dx = Math.abs(x2 - x1);
        var dy = Math.abs(y2 - y1);
        var sx = (x1 < x2) ? 1 : -1;
        var sy = (y1 < y2) ? 1 : -1;
        var err = dx - dy;
        // Set first coordinates
        coordinatesArray.push({x:x1, y:y1});
        // Main loop
        while (!((x1 === x2) && (y1 === y2))) {
            var e2 = err << 1;
            if (e2 > -dy) {
                err -= dy;
                x1 += sx;
            }
            if (e2 < dx) {
                err += dx;
                y1 += sy;
            }
            // Set coordinates
            coordinatesArray.push({x:x1, y:y1});

        }
        // Return the result
        return coordinatesArray;
    }

    fillLasso = () => {
        let w = this.state.imageInfo.width;
        if(this.state.mode==='lasso' && this.state.points.length > 0){
            let maxX = null, minX = null,
                maxY = null, minY = null;

            this.state.points.forEach(e => {
                if (!maxX) {
                    maxX = e[0];
                } else {
                    maxX < e[0] ? maxX = e[0]: maxX  = maxX;
                }
                if (!minX) {
                    minX = e[0];
                } else {
                    minX > e[0] ? minX = e[0]: minX  = minX;
                }
                if (!maxY) {
                    maxY = e[1];
                } else {
                    maxY < e[1] ? maxY = e[1]: maxY = maxY;
                }
                if (!minY) {
                    minY = e[1];
                } else {
                    minY > e[1] ? minY = e[1]: minY = minY;
                }
            })

            for (let i = minX; i <= maxX; i++) {
                for (let j = minY; j <= maxY; j++) {
                    if (classifyPoint(this.state.points, [i, j]) == -1) {
                        this.state.mask.data[w*j+i] = 1
                    }
                }
            }

            // var asyncIterable = {
            //     [Symbol.asyncIterator]() {
            //     return {
            //         i: minX,
            //         j: minY,
            //         next() {
            //         if( (this.i+1) * (this.j) === maxX * maxY ) {
            //             return Promise.resolve({ done: true });
            //         } 

            //         if(this.j >= maxY) {
            //                 this.i++;
            //                 this.j = minY;
            //         }

            //         if (this.i <=maxX ) {
            //             return Promise.resolve({ value: {i: this.i, j: this.j++}, done: false });
            //         }
            
            //         return Promise.resolve({ done: true });
            //         }
            //     };
            //     }
            // };
            
            // (async () => {
            //     for await (let num of asyncIterable) {
            //         if (classifyPoint(this.state.points, [num.i, num.j]) == -1) {
            //             this.state.mask.data[w*num.j+num.i] = 1
            //         }
            //     }
            // })();
            this.drawBorder();
        }
    }

    trace = () => {
        this.setState({isDrawing: false})
        let count = 0;
        this.state.mask.data.forEach(el => {
            if(el===1) count+=1;
        })
        this.calculateCloudPercentage(count)
    };

    hatchTick = () => {
        this.state.hatchOffset = (this.state.hatchOffset + 1) % (this.state.hatchLength * 2);
        this.drawBorder(true);
    };

    onMouseDown = (e) => {
        this.setState({isDrawing: false})
        if (e.button === 0 && this.state.mode !== 'none') {
            this.setState({allowDraw: true});
            this.state.downPoint = this.getMousePosition(e);
            this.drawMask(this.state.downPoint.x, this.state.downPoint.y);
            this.state.points = [];
            if(this.state.mode === 'lasso' && !this.state.shiftPressed) {
                this.state.mask = null;
            }
            // if(this.state.mode === 'zoom') {
            //     this.zoomImage();
            // } 
            // else {
            //     let e = document.getElementById('picture');
            //     e.style.width = `${this.state.originalImageInfo.width}px`;
            //     this.initCanvas({target: e})
            //     this.drawBorder();
            // }
        }
        else this.setState({allowDraw: false});
    };

    onMouseMove = (e) => {
        if (this.state.allowDraw && this.state.mode !== 'none') {
            var p = this.getMousePosition(e);
            if(this.state.mode === 'wand') {
                if (p.x !== this.state.downPoint.x || p.y !== this.state.downPoint.y) {
                    var dx = p.x - this.state.downPoint.x,
                        dy = p.y - this.state.downPoint.y,
                        len = Math.sqrt(dx * dx + dy * dy),
                        adx = Math.abs(dx),
                        ady = Math.abs(dy),
                        sign = adx > ady ? dx / adx : dy / ady;
                    sign = sign < 0 ? sign / 5 : sign / 3;
                    var thres = Math.min(Math.max(this.state.colorThreshold + Math.floor(sign * len), 1), 255);
                    //var thres = Math.min(colorThreshold + Math.floor(len / 3), 255);
                    if (thres !== this.state.currentThreshold) {
                        this.state.currentThreshold = thres;
                        this.drawMask(this.state.downPoint.x, this.state.downPoint.y);
                    }
                }
            } else {
                this.drawMask(p.x, p.y);
            }
        }
    };

    onMouseUp = (e) => {
        if(!this.state.mask) {
            let w = this.state.imageInfo.width,
            h = this.state.imageInfo.height,
            ctx = this.state.imageInfo.context;
            this.state.mask = ctx.createImageData(w, h);
        }

        this.state.allowDraw = false;
        this.state.currentThreshold = this.state.colorThreshold;
        if(this.state.mode !== 'none' && this.state.mode !== 'zoom') {
            this.setState({isDrawing: true})
        }
        this.setState({prevPoint: null})

        if(this.state.mode === 'lasso') {
            let lineArray = this.drawLine(this.state.downPoint, this.getMousePosition(e));
            let w = this.state.imageInfo.width;
            lineArray.forEach(el => {
                this.state.points.push([el.x, el.y])
                this.state.mask.data[w*el.y+el.x] = 1;
            })
            this.fillLasso();
        }
    }

    clearMask = () => {
        if(!this.state.mask) return;
        this.state.mask.data.forEach(e => {
            e = 0;
        });
        this.state.mask = null;
        this.setState({isDrawing: false, prevPoint: null, points: []});
        this.drawBorder();
        let w = this.state.imageInfo.width,
            h = this.state.imageInfo.height,
            ctx = this.state.imageInfo.context;
        ctx.clearRect(0, 0, w, h);
        // let e = document.getElementById('picture');
        // e.style.width = `${this.state.originalImageInfo.width}px`;
    }

    onRadiusChange = (e) => {
        if(parseInt(e.target.value) >= 0  || e.target.value === ''){
            this.setState({blurRadius: e.target.value})
        }
        
    };
    onThresholdChange = (e) => {
        if(parseInt(e.target.value) >= 0 || e.target.value === ''){
            this.setState({colorThreshold: e.target.value})
        }
    };

    calcPolygonArea = (vertices) => {
        var total = 0;
    
        for (var i = 0, l = vertices.length; i < l; i++) {
          var addX = vertices[i].x;
          var addY = vertices[i == vertices.length - 1 ? 0 : i + 1].y;
          var subX = vertices[i == vertices.length - 1 ? 0 : i + 1].x;
          var subY = vertices[i].y;
    
          total += (addX * addY * 0.5);
          total -= (subX * subY * 0.5);
        }
    
        return Math.abs(total);
    }

    calculateCloudPercentage = (area) => {
        let totalArea = this.state.imageInfo.height*this.state.imageInfo.width;
        this.setState({cloudPercentage: parseFloat(area/totalArea*100).toFixed(2)})
    }

    handleZoom = () => {
        this.state.mode==='zoom' ? 
            this.setState({mode: 'none', isZoomed: false}):
            this.setState({mode: 'zoom'});
    }
    handleWand = () => {
        this.state.mode==='wand' ? 
            this.setState({mode: 'none'}):
            this.setState({mode: 'wand'});
    }

    handleLasso = () => {
        this.state.mode==='lasso' ? 
            this.setState({mode: 'none'}):
            this.setState({mode: 'lasso'});
    }

    zoomImage = () => {
        this.setState({isZoomed: true});
        let e = document.getElementById('picture');
        this.state.mask = null;
        if(!e.style.width) {
            e.style.width = `${this.state.imageInfo.width}px`;
        }
        let width = this.state.imageInfo.width;
        if(!this.state.shiftPressed && width < 4000) {
            e.style.width = `${width*1.1}px`;
        } else if(this.state.shiftPressed && width > 200) {
            e.style.width = `${width*0.9}px`;
        }
        this.initCanvas({target: e})
        this.drawBorder();
    }

    render() { 
        return ( 
            <React.Fragment>
                <Modal className='modal' open={this.state.isModalActive} onClose={() => this.setState({isModalActive:false})}>
                    <Modal.Header>Cloud Selection Tool</Modal.Header>
                    <Modal.Content>
                    <Modal.Description>
                        <div style={{marginBottom: '8px', position: 'relative', display: 'flex'}}>
                            <Popup
                                content="Magic Wand"
                                trigger={
                                    <Button circular onClick={this.handleWand} className='wand-button' color={this.state.mode==='wand' ? 'grey' : null}>
                                        <Image width='16px' src={require('../../../../assets/images/magic-wand.webp')}/>
                                    </Button>
                                }
                            />
                            <Popup
                                content="Lasso"
                                trigger={
                                    <Button circular onClick={this.handleLasso} className='lasso-button' color={this.state.mode==='lasso' ? 'grey' : null}>
                                        <Image width='16px' src={require('../../../../assets/images/lasso.webp')}/>
                                    </Button>
                                }
                            />
                            <Popup
                                content="Zoom"
                                trigger={
                                    <Button disabled={true} circular onClick={this.handleZoom} className='lasso-button' color={this.state.mode==='zoom' ? 'grey' : null}>
                                        <Image width='16px' src={require('../../../../assets/images/search.webp')}/>
                                    </Button>
                                }
                            />
                            <Popup
                                content="Clear Mask"
                                trigger={
                                    <Button className='clear-button' onClick={this.clearMask} circular>
                                        <Image className='clear' width='16px' src={require('../../../../assets/images/clear.webp')}/>
                                    </Button>
                                }
                            />
                        </div>
                        <div className="wand-params">
                            {this.state.mode==='wand' && <Input onChange={this.onRadiusChange} value={this.state.blurRadius} className='blur' label='Blur' type='number' />}
                            {this.state.mode==='wand' && <Input onChange={this.onThresholdChange} value={this.state.colorThreshold} className='thresh' label='Threshold' type='number' />}
                        </div>
                        <div className='image-content'>
                            <img onLoad={this.initCanvas} alt='quicklook' id="picture" src={require('../../../../assets/images/DS_DZHR1_201907200653149_KZ1_E066N50_017688_QL.webp')} className="picture" />
                            <canvas onMouseOver={this.canvasEnter} onMouseLeave={this.canvasLeave} className="canvas" id="resultCanvas" onMouseUp={this.onMouseUp} onMouseDown={this.onMouseDown} onMouseMove={this.onMouseMove}></canvas>
                        </div>
                        <Button color='green' className='polygon' onClick={this.trace} disabled={!this.state.isDrawing}>Calculate Selected area</Button>
                        <div style={{display: 'flex', marginBottom: '4px'}}>
                            <div id="threshold"><strong>Threshold:</strong> {this.state.currentThreshold}</div>
                            {this.state.cloudPercentage !== null && <div style={{marginLeft: 'auto'}}><strong>Area:</strong> {this.state.cloudPercentage} %</div>}
                        </div>
                    </Modal.Description>
                    </Modal.Content>
                </Modal>
            </React.Fragment>
        );
    }
}
 
export default Select;