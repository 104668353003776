import React, {Component} from 'react';
import {Header, Input, Select, Table} from "semantic-ui-react";
import {connect} from "react-redux";
import './PriceList.css';
import PropTypes from 'prop-types';
import {changeMinArea, changePrice, getPrices} from '../../../../actions/prices';
import {t} from '../../../../lang';
import Confirm from '../../../messages/ModalConfirm';

class PriceList extends Component {

    constructor() {
        super();
        this.timer = null;
    }

    confirm = React.createRef();

    state = {
        options: [
            {key: 1, value: 'kzt', text: t('pricing.tenge')},
            {key: 2, value: 'usd', text: t('pricing.dollar')},
        ],
        currency: 'kzt',
        prices: [],

        KazEOSat1_L1_mono_archive: '',
        KazEOSat1_L1_mono_standard: '',
        KazEOSat1_L1_mono_priority: '',
        KazEOSat1_L1_stereo_archive: '',
        KazEOSat1_L1_stereo_standard: '',
        KazEOSat1_L1_stereo_priority: '',

        KazEOSat1_L2_mono_archive: '',
        KazEOSat1_L2_mono_standard: '',
        KazEOSat1_L2_mono_priority: '',
        KazEOSat1_L2_stereo_archive: '',
        KazEOSat1_L2_stereo_standard: '',
        KazEOSat1_L2_stereo_priority: '',

        KazEOSat1_L3_mono_archive: '',
        KazEOSat1_L3_mono_standard: '',
        KazEOSat1_L3_mono_priority: '',
        KazEOSat1_L3_stereo_archive: '',
        KazEOSat1_L3_stereo_standard: '',
        KazEOSat1_L3_stereo_priority: '',

        KazEOSat1_L4_mono_archive: '',
        KazEOSat1_L4_mono_standard: '',
        KazEOSat1_L4_mono_priority: '',
        KazEOSat1_L4_stereo_archive: '',
        KazEOSat1_L4_stereo_standard: '',
        KazEOSat1_L4_stereo_priority: '',

        KazEOSat2_L1_mono_archive: '',
        KazEOSat2_L1_mono_standard: '',
        KazEOSat2_L1_mono_priority: '',

        KazEOSat2_L2_mono_archive: '',
        KazEOSat2_L2_mono_standard: '',
        KazEOSat2_L2_mono_priority: '',

        KazEOSat2_L3_mono_archive: '',
        KazEOSat2_L3_mono_standard: '',
        KazEOSat2_L3_mono_priority: '',

        KazEOSat2_L4_mono_archive: '',
        KazEOSat2_L4_mono_standard: '',
        KazEOSat2_L4_mono_priority: '',

        KazEOSat1_mono_archive_min_area: '',
        KazEOSat1_mono_standard_min_area: '',
        KazEOSat1_mono_priority_min_area: '',
        KazEOSat1_stereo_archive_min_area: '',
        KazEOSat1_stereo_standard_min_area: '',
        KazEOSat1_stereo_priority_min_area: '',

        express_mono_standard_price: '',
        express_mono_priority_price: '',
        express_stereo_standard_price: '',
        express_stereo_priority_price: '',
    };

    async componentDidMount() {
        if (this.props.isAuthenticated) await this.props.getPrices();
        this.initPrices();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.prices !== this.props.prices) {
            this.setState({prices: this.props.prices});
        }
    }

    initPrices = () => {
        if (this.state.prices.length) {
            this.setState({
                KazEOSat1_L1_mono_archive: this.getPrice('KazEOSat-1', 'mono', 'archive', 'L1'),
                KazEOSat1_L1_mono_standard: this.getPrice('KazEOSat-1', 'mono', 'standard', 'L1'),
                KazEOSat1_L1_mono_priority: this.getPrice('KazEOSat-1', 'mono', 'priority', 'L1'),
                KazEOSat1_L1_stereo_archive: this.getPrice('KazEOSat-1', 'stereo', 'archive', 'L1'),
                KazEOSat1_L1_stereo_standard: this.getPrice('KazEOSat-1', 'stereo', 'standard', 'L1'),
                KazEOSat1_L1_stereo_priority: this.getPrice('KazEOSat-1', 'stereo', 'priority', 'L1'),

                KazEOSat1_L2_mono_archive: this.getPrice('KazEOSat-1', 'mono', 'archive', 'L2'),
                KazEOSat1_L2_mono_standard: this.getPrice('KazEOSat-1', 'mono', 'standard', 'L2'),
                KazEOSat1_L2_mono_priority: this.getPrice('KazEOSat-1', 'mono', 'priority', 'L2'),
                KazEOSat1_L2_stereo_archive: this.getPrice('KazEOSat-1', 'stereo', 'archive', 'L2'),
                KazEOSat1_L2_stereo_standard: this.getPrice('KazEOSat-1', 'stereo', 'standard', 'L2'),
                KazEOSat1_L2_stereo_priority: this.getPrice('KazEOSat-1', 'stereo', 'priority', 'L2'),

                KazEOSat1_L3_mono_archive: this.getPrice('KazEOSat-1', 'mono', 'archive', 'L3'),
                KazEOSat1_L3_mono_standard: this.getPrice('KazEOSat-1', 'mono', 'standard', 'L3'),
                KazEOSat1_L3_mono_priority: this.getPrice('KazEOSat-1', 'mono', 'priority', 'L3'),
                KazEOSat1_L3_stereo_archive: this.getPrice('KazEOSat-1', 'stereo', 'archive', 'L3'),
                KazEOSat1_L3_stereo_standard: this.getPrice('KazEOSat-1', 'stereo', 'standard', 'L3'),
                KazEOSat1_L3_stereo_priority: this.getPrice('KazEOSat-1', 'stereo', 'priority', 'L3'),

                KazEOSat1_L4_mono_archive: this.getPrice('KazEOSat-1', 'mono', 'archive', 'L4'),
                KazEOSat1_L4_mono_standard: this.getPrice('KazEOSat-1', 'mono', 'standard', 'L4'),
                KazEOSat1_L4_mono_priority: this.getPrice('KazEOSat-1', 'mono', 'priority', 'L4'),
                KazEOSat1_L4_stereo_archive: this.getPrice('KazEOSat-1', 'stereo', 'archive', 'L4'),
                KazEOSat1_L4_stereo_standard: this.getPrice('KazEOSat-1', 'stereo', 'standard', 'L4'),
                KazEOSat1_L4_stereo_priority: this.getPrice('KazEOSat-1', 'stereo', 'priority', 'L4'),

                KazEOSat2_L1_mono_archive: this.getPrice('KazEOSat-2', 'mono', 'archive', 'L1'),
                KazEOSat2_L1_mono_standard: this.getPrice('KazEOSat-2', 'mono', 'standard', 'L1'),
                KazEOSat2_L1_mono_priority: this.getPrice('KazEOSat-2', 'mono', 'priority', 'L1'),

                KazEOSat2_L2_mono_archive: this.getPrice('KazEOSat-2', 'mono', 'archive', 'L2'),
                KazEOSat2_L2_mono_standard: this.getPrice('KazEOSat-2', 'mono', 'standard', 'L2'),
                KazEOSat2_L2_mono_priority: this.getPrice('KazEOSat-2', 'mono', 'priority', 'L2'),

                KazEOSat2_L3_mono_archive: this.getPrice('KazEOSat-2', 'mono', 'archive', 'L3'),
                KazEOSat2_L3_mono_standard: this.getPrice('KazEOSat-2', 'mono', 'standard', 'L3'),
                KazEOSat2_L3_mono_priority: this.getPrice('KazEOSat-2', 'mono', 'priority', 'L3'),

                KazEOSat2_L4_mono_archive: this.getPrice('KazEOSat-2', 'mono', 'archive', 'L4'),
                KazEOSat2_L4_mono_standard: this.getPrice('KazEOSat-2', 'mono', 'standard', 'L4'),
                KazEOSat2_L4_mono_priority: this.getPrice('KazEOSat-2', 'mono', 'priority', 'L4'),

                KazEOSat1_mono_archive_min_area: this.getMinOrderArea('KazEOSat-1', 'mono', 'archive', 'L1'),
                KazEOSat1_mono_standard_min_area: this.getMinOrderArea('KazEOSat-1', 'mono', 'standard', 'L1'),
                KazEOSat1_mono_priority_min_area: this.getMinOrderArea('KazEOSat-1', 'mono', 'priority', 'L1'),
                KazEOSat1_stereo_archive_min_area: this.getMinOrderArea('KazEOSat-1', 'stereo', 'archive', 'L1'),
                KazEOSat1_stereo_standard_min_area: this.getMinOrderArea('KazEOSat-1', 'stereo', 'standard', 'L1'),
                KazEOSat1_stereo_priority_min_area: this.getMinOrderArea('KazEOSat-1', 'stereo', 'priority', 'L1'),

                KazEOSat2_mono_archive_min_area: this.getMinOrderArea('KazEOSat-2', 'mono', 'archive', 'L1'),
                KazEOSat2_mono_standard_min_area: this.getMinOrderArea('KazEOSat-2', 'mono', 'standard', 'L1'),
                KazEOSat2_mono_priority_min_area: this.getMinOrderArea('KazEOSat-2', 'mono', 'priority', 'L1'),

                express_mono_standard_price: this.getExpressMinOrderArea('KazEOSat-1', 'mono', 'standard', 'L1'),
                express_mono_priority_price: this.getExpressMinOrderArea('KazEOSat-1', 'mono', 'priority', 'L1'),
                express_stereo_standard_price: this.getExpressMinOrderArea('KazEOSat-1', 'stereo', 'standard', 'L1'),
                express_stereo_priority_price: this.getExpressMinOrderArea('KazEOSat-1', 'stereo', 'priority', 'L1'),
            });

        }
    };

    handlePriceChange = (e, id) => {
        clearTimeout(this.timer);
        const name = e.target.name;
        const value = e.target.value;
        this.setState({...this.state, [name]: value});
        this.timer = setTimeout((value, id) => {
            this.confirm.current.showModal(
                id,
                t('pricing.title'),
                t('pricing.body'),
                null,
                null,
                value
            );
        }, 1000, value, id);
    };

    handleMinAreaChange = (e, satellite, mode, type, level) => {
        clearTimeout(this.timer);
        const name = e.target.name;
        const value = e.target.value;
        const id = {
            params: {
                acquisition_mode: mode,
                image_source: type,
                satellite
            },
            isExpress: name.includes('express')
        };

        this.setState({...this.state, [name]: value});

        this.timer = setTimeout((value, id) => {
            this.confirm.current.showModal(
                id,
                t('pricing.title'),
                t('pricing.body'),
                null,
                null,
                value
            );
        }, 1000, value, id);
    };

    changePrice = async (id, value) => {
        if (id instanceof Object) {
            const data = id;
            id.isExpress ? data['min_order_area_express'] = value : data['min_order_area'] = value;
            await this.props.changeMinArea(data).then(async () => {
                await this.props.getPrices();
                this.initPrices();
            });
        } else {
            await this.props.changePrice({
                id: id,
                price: parseFloat(value).toFixed(2),
                currency: this.state.currency
            }).then(async () => {
                await this.props.getPrices();
                this.initPrices();
            });
        }
        this.confirm.current.hideModal();
    };

    cancelPriceChange = async () => {
        await this.props.getPrices();
        this.initPrices();
        this.confirm.current.hideModal();
    };

    handleSelectChange = (e, data) => {
        this.setState({...this.state, [data.name]: data.value});
    };

    getPrice = (satellite, mode, type, level) => {
        const {prices} = this.state;
        const price = prices.filter(price => {
            if (price.satellite === satellite &&
                price.acquisition_mode === mode &&
                price.image_source === type &&
                price.processing_level === level) {
                return price;
            }
            return null;
        })[0];
        return this.state.currency === 'kzt' ? price.price_kzt : price.price_usd;
    };

    getExpressPrice = (satellite, mode, type, level) => {
        const {prices} = this.state;
        const price = prices.filter(price => {
            if (price.satellite === satellite &&
                price.acquisition_mode === mode &&
                price.image_source === type &&
                price.processing_level === level) {
                return price;
            }
            return null;
        })[0];
        return this.state.currency === 'kzt' ? price.express_price_kzt : price.express_price_usd;
    };

    getId = (satellite, mode, type, level) => {
        const {prices} = this.state;
        const price = prices.filter(price => {
            if (price.satellite === satellite &&
                price.acquisition_mode === mode &&
                price.image_source === type &&
                price.processing_level === level) {
                return price;
            }
            return null;
        })[0];
        return price.id;
    };

    getMinOrderArea = (satellite, mode, type, level) => {
        const {prices} = this.state;
        const price = prices.filter(price => {
            if (price.satellite === satellite &&
                price.acquisition_mode === mode &&
                price.image_source === type &&
                price.processing_level === level) {
                return price;
            }
            return null;
        })[0];
        return price.min_order_area;
    };

    getExpressMinOrderArea = (satellite, mode, type, level) => {
        const {prices} = this.state;
        const price = prices.filter(price => {
            if (price.satellite === satellite &&
                price.acquisition_mode === mode &&
                price.image_source === type &&
                price.processing_level === level) {
                return price;
            }
            return null;
        })[0];
        return price.min_order_area_express;
    };

    render() {
        const {prices} = this.state;
        return (
            <div className="price-wrapper">
                {prices.length && <div>
                    <Header as='h3' textAlign='center' style={{
                        marginRight: '10vw',
                        marginLeft: '10vw'
                    }}>{t('pricing.prices')} {this.state.currency === 'kzt' ? t('pricing.in_tenge') : t('pricing.in_dollar')} {t('pricing.for1')}</Header>
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell colSpan='8' textAlign='center' className="main_header">
                                    <span className="main_header_title">KazEOSat-1</span>
                                    <Select className='currency' value={this.state.currency}
                                            onChange={this.handleSelectChange} name="currency"
                                            options={this.state.options}/>
                                </Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell colSpan='2'
                                                  textAlign='center'>{t('pricing.acquisition')}</Table.HeaderCell>
                                <Table.HeaderCell colSpan='3' textAlign='center'>{t('pricing.mono')}</Table.HeaderCell>
                                <Table.HeaderCell colSpan='3'
                                                  textAlign='center'>{t('pricing.stereo')}</Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell colSpan='2'
                                                  textAlign='center'>{t('pricing.prices')} {this.state.currency === 'kzt' ? t('pricing.in_tenge') : t('pricing.in_dollar')} {t('pricing.for_km')}<sup>2</sup></Table.HeaderCell>
                                <Table.HeaderCell colSpan='1'
                                                  textAlign='center'>{t('pricing.archive')}</Table.HeaderCell>
                                <Table.HeaderCell colSpan='1'
                                                  textAlign='center'>{t('pricing.standard')}</Table.HeaderCell>
                                <Table.HeaderCell colSpan='1'
                                                  textAlign='center'>{t('pricing.priority')}</Table.HeaderCell>
                                <Table.HeaderCell colSpan='1'
                                                  textAlign='center'>{t('pricing.archive')}</Table.HeaderCell>
                                <Table.HeaderCell colSpan='1'
                                                  textAlign='center'>{t('pricing.standard')}</Table.HeaderCell>
                                <Table.HeaderCell colSpan='1'
                                                  textAlign='center'>{t('pricing.priority')}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell colSpan='2' textAlign='center'
                                            className="price-row">{t('pricing.min')}<sup>2</sup></Table.Cell>
                                <Table.Cell className="cell">
                                    <Input className="price-input" type='number'
                                           name='KazEOSat1_mono_archive_min_area'
                                           value={this.state.KazEOSat1_mono_archive_min_area}
                                           onChange={e => this.handleMinAreaChange(e, 'KazEOSat-1', 'mono', 'archive', 'L1')}/>
                                </Table.Cell>
                                <Table.Cell className="cell">
                                    <Input className="price-input" type='number'
                                           name='KazEOSat1_mono_standard_min_area'
                                           value={this.state.KazEOSat1_mono_standard_min_area}
                                           onChange={e => this.handleMinAreaChange(e, 'KazEOSat-1', 'mono', 'standard', 'L1')}/>
                                </Table.Cell>
                                <Table.Cell className="cell">
                                    <Input className="price-input" type='number'
                                           name='KazEOSat1_mono_priority_min_area'
                                           value={this.state.KazEOSat1_mono_priority_min_area}
                                           onChange={e => this.handleMinAreaChange(e, 'KazEOSat-1', 'mono', 'priority', 'L1')}/>
                                </Table.Cell>
                                <Table.Cell className="cell">
                                    <Input className="price-input" type='number'
                                           name='KazEOSat1_stereo_archive_min_area'
                                           value={this.state.KazEOSat1_stereo_archive_min_area}
                                           onChange={e => this.handleMinAreaChange(e, 'KazEOSat-1', 'stereo', 'archive', 'L1')}/>
                                </Table.Cell>
                                <Table.Cell className="cell">
                                    <Input className="price-input" type='number'
                                           name='KazEOSat1_stereo_standard_min_area'
                                           value={this.state.KazEOSat1_stereo_standard_min_area}
                                           onChange={e => this.handleMinAreaChange(e, 'KazEOSat-1', 'stereo', 'standard', 'L1')}/>
                                </Table.Cell>
                                <Table.Cell className="cell">
                                    <Input className="price-input" type='number'
                                           name='KazEOSat1_stereo_priority_min_area'
                                           value={this.state.KazEOSat1_stereo_priority_min_area}
                                           onChange={e => this.handleMinAreaChange(e, 'KazEOSat-1', 'stereo', 'priority', 'L1')}/>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell colSpan='1' textAlign='center' className="price-row">{t('pricing.pan')}:
                                    1 {t('pricing.m')}; {t('pricing.mc')}: 4 {t('pricing.m')}</Table.Cell>
                                <Table.Cell colSpan='1' textAlign='center' className="price-row">L1</Table.Cell>
                                <Table.Cell className="cell">
                                    <Input className="price-input" type='number' step='0.01'
                                           value={this.state.KazEOSat1_L1_mono_archive} name='KazEOSat1_L1_mono_archive'
                                           onChange={(e) => this.handlePriceChange(e, this.getId('KazEOSat-1', 'mono', 'archive', 'L1'))}/>
                                </Table.Cell>
                                <Table.Cell className="cell">
                                    <Input className="price-input" type='number' step='0.01'
                                           value={this.state.KazEOSat1_L1_mono_standard}
                                           name='KazEOSat1_L1_mono_standard'
                                           onChange={(e) => this.handlePriceChange(e, this.getId('KazEOSat-1', 'mono', 'standard', 'L1'))}/>
                                </Table.Cell>
                                <Table.Cell className="cell">
                                    <Input className="price-input" type='number' step='0.01'
                                           value={this.state.KazEOSat1_L1_mono_priority}
                                           name='KazEOSat1_L1_mono_priority'
                                           onChange={(e) => this.handlePriceChange(e, this.getId('KazEOSat-1', 'mono', 'priority', 'L1'))}/>
                                </Table.Cell>
                                <Table.Cell className="cell">
                                    <Input className="price-input" type='number' step='0.01'
                                           value={this.state.KazEOSat1_L1_stereo_archive}
                                           name='KazEOSat1_L1_stereo_archive'
                                           onChange={(e) => this.handlePriceChange(e, this.getId('KazEOSat-1', 'stereo', 'archive', 'L1'))}/>
                                </Table.Cell>
                                <Table.Cell className="cell">
                                    <Input className="price-input" type='number' step='0.01'
                                           value={this.state.KazEOSat1_L1_stereo_standard}
                                           name='KazEOSat1_L1_stereo_standard'
                                           onChange={(e) => this.handlePriceChange(e, this.getId('KazEOSat-1', 'stereo', 'standard', 'L1'))}/>
                                </Table.Cell>
                                <Table.Cell className="cell">
                                    <Input className="price-input" type='number' step='0.01'
                                           value={this.state.KazEOSat1_L1_stereo_priority}
                                           name='KazEOSat1_L1_stereo_priority'
                                           onChange={(e) => this.handlePriceChange(e, this.getId('KazEOSat-1', 'stereo', 'priority', 'L1'))}/>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell colSpan='1' rowSpan='3' textAlign='center'
                                            className="price-row">{t('pricing.channels')}: r, g, b, nir</Table.Cell>
                                <Table.Cell colSpan='1' textAlign='center' className="price-row">L2</Table.Cell>
                                <Table.Cell className="cell">
                                    <Input className="price-input" type='number' step='0.01'
                                           value={this.state.KazEOSat1_L2_mono_archive} name='KazEOSat1_L2_mono_archive'
                                           onChange={(e) => this.handlePriceChange(e, this.getId('KazEOSat-1', 'mono', 'archive', 'L2'))}/>
                                </Table.Cell>
                                <Table.Cell className="cell">
                                    <Input className="price-input" type='number' step='0.01'
                                           value={this.state.KazEOSat1_L2_mono_standard}
                                           name='KazEOSat1_L2_mono_standard'
                                           onChange={(e) => this.handlePriceChange(e, this.getId('KazEOSat-1', 'mono', 'standard', 'L2'))}/>
                                </Table.Cell>
                                <Table.Cell className="cell">
                                    <Input className="price-input" type='number' step='0.01'
                                           value={this.state.KazEOSat1_L2_mono_priority}
                                           name='KazEOSat1_L2_mono_priority'
                                           onChange={(e) => this.handlePriceChange(e, this.getId('KazEOSat-1', 'mono', 'priority', 'L2'))}/>
                                </Table.Cell>
                                <Table.Cell className="cell">
                                    <Input className="price-input" type='number' step='0.01'
                                           value={this.state.KazEOSat1_L2_stereo_archive}
                                           name='KazEOSat1_L2_stereo_archive'
                                           onChange={(e) => this.handlePriceChange(e, this.getId('KazEOSat-1', 'stereo', 'archive', 'L2'))}/>
                                </Table.Cell>
                                <Table.Cell className="cell">
                                    <Input className="price-input" type='number' step='0.01'
                                           value={this.state.KazEOSat1_L2_stereo_standard}
                                           name='KazEOSat1_L2_stereo_standard'
                                           onChange={(e) => this.handlePriceChange(e, this.getId('KazEOSat-1', 'stereo', 'standard', 'L2'))}/>
                                </Table.Cell>
                                <Table.Cell className="cell">
                                    <Input className="price-input" type='number' step='0.01'
                                           value={this.state.KazEOSat1_L2_stereo_priority}
                                           name='KazEOSat1_L2_stereo_priority'
                                           onChange={(e) => this.handlePriceChange(e, this.getId('KazEOSat-1', 'stereo', 'priority', 'L2'))}/>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell colSpan='1' textAlign='center' className="price-row"
                                            style={{borderLeft: '1px solid #E3E4E5'}}>L3</Table.Cell>
                                <Table.Cell className="cell">
                                    <Input className="price-input" type='number' step='0.01'
                                           value={this.state.KazEOSat1_L3_mono_archive} name='KazEOSat1_L3_mono_archive'
                                           onChange={(e) => this.handlePriceChange(e, this.getId('KazEOSat-1', 'mono', 'archive', 'L3'))}/>
                                </Table.Cell>
                                <Table.Cell className="cell">
                                    <Input className="price-input" type='number' step='0.01'
                                           value={this.state.KazEOSat1_L3_mono_standard}
                                           name='KazEOSat1_L3_mono_standard'
                                           onChange={(e) => this.handlePriceChange(e, this.getId('KazEOSat-1', 'mono', 'standard', 'L3'))}/>
                                </Table.Cell>
                                <Table.Cell className="cell">
                                    <Input className="price-input" type='number' step='0.01'
                                           value={this.state.KazEOSat1_L3_mono_priority}
                                           name='KazEOSat1_L3_mono_priority'
                                           onChange={(e) => this.handlePriceChange(e, this.getId('KazEOSat-1', 'mono', 'priority', 'L3'))}/>
                                </Table.Cell>
                                <Table.Cell className="cell">
                                    <Input className="price-input" type='number' step='0.01'
                                           value={this.state.KazEOSat1_L3_stereo_archive}
                                           name='KazEOSat1_L3_stereo_archive'
                                           onChange={(e) => this.handlePriceChange(e, this.getId('KazEOSat-1', 'stereo', 'archive', 'L3'))}/>
                                </Table.Cell>
                                <Table.Cell className="cell">
                                    <Input className="price-input" type='number' step='0.01'
                                           value={this.state.KazEOSat1_L3_stereo_standard}
                                           name='KazEOSat1_L3_stereo_standard'
                                           onChange={(e) => this.handlePriceChange(e, this.getId('KazEOSat-1', 'stereo', 'standard', 'L3'))}/>
                                </Table.Cell>
                                <Table.Cell className="cell">
                                    <Input className="price-input" type='number' step='0.01'
                                           value={this.state.KazEOSat1_L3_stereo_priority}
                                           name='KazEOSat1_L3_stereo_priority'
                                           onChange={(e) => this.handlePriceChange(e, this.getId('KazEOSat-1', 'stereo', 'priority', 'L3'))}/>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell colSpan='1' textAlign='center' className="price-row"
                                            style={{borderLeft: '1px solid #E3E4E5'}}>L4</Table.Cell>
                                <Table.Cell className="cell">
                                    <Input className="price-input" type='number' step='0.01'
                                           value={this.state.KazEOSat1_L4_mono_archive} name='KazEOSat1_L4_mono_archive'
                                           onChange={(e) => this.handlePriceChange(e, this.getId('KazEOSat-1', 'mono', 'archive', 'L4'))}/>
                                </Table.Cell>
                                <Table.Cell className="cell">
                                    <Input className="price-input" type='number' step='0.01'
                                           value={this.state.KazEOSat1_L4_mono_standard}
                                           name='KazEOSat1_L4_mono_standard'
                                           onChange={(e) => this.handlePriceChange(e, this.getId('KazEOSat-1', 'mono', 'standard', 'L4'))}/>
                                </Table.Cell>
                                <Table.Cell className="cell">
                                    <Input className="price-input" type='number' step='0.01'
                                           value={this.state.KazEOSat1_L4_mono_priority}
                                           name='KazEOSat1_L4_mono_priority'
                                           onChange={(e) => this.handlePriceChange(e, this.getId('KazEOSat-1', 'mono', 'priority', 'L4'))}/>
                                </Table.Cell>
                                <Table.Cell className="cell">
                                    <Input className="price-input" type='number' step='0.01'
                                           value={this.state.KazEOSat1_L4_stereo_archive}
                                           name='KazEOSat1_L4_stereo_archive'
                                           onChange={(e) => this.handlePriceChange(e, this.getId('KazEOSat-1', 'stereo', 'archive', 'L4'))}/>
                                </Table.Cell>
                                <Table.Cell className="cell">
                                    <Input className="price-input" type='number' step='0.01'
                                           value={this.state.KazEOSat1_L4_stereo_standard}
                                           name='KazEOSat1_L4_stereo_standard'
                                           onChange={(e) => this.handlePriceChange(e, this.getId('KazEOSat-1', 'stereo', 'standard', 'L4'))}/>
                                </Table.Cell>
                                <Table.Cell className="cell">
                                    <Input className="price-input" type='number' step='0.01'
                                           value={this.state.KazEOSat1_L4_stereo_priority}
                                           name='KazEOSat1_L4_stereo_priority'
                                           onChange={(e) => this.handlePriceChange(e, this.getId('KazEOSat-1', 'stereo', 'priority', 'L4'))}/>
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                    <Header as='h3' textAlign='center' style={{
                        marginRight: '10vw',
                        marginLeft: '10vw'
                    }}>{t('pricing.prices')} {this.state.currency === 'kzt' ? t('pricing.in_tenge') : t('pricing.in_dollar')} {t('pricing.for2')}</Header>
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell colSpan='5' textAlign='center' className="main_header">
                                    <span className="main_header_title">KazEOSat-2</span>
                                </Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell colSpan='2'
                                                  textAlign='center'>{t('pricing.acquisition')}</Table.HeaderCell>
                                <Table.HeaderCell colSpan='3' textAlign='center'>{t('pricing.mono')}</Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell colSpan='2'
                                                  textAlign='center'>{t('pricing.prices')} {this.state.currency === 'kzt' ? t('pricing.in_tenge') : t('pricing.in_dollar')} {t('pricing.for_km')}<sup>2</sup></Table.HeaderCell>
                                <Table.HeaderCell colSpan='1'
                                                  textAlign='center'>{t('pricing.archive')}</Table.HeaderCell>
                                <Table.HeaderCell colSpan='1'
                                                  textAlign='center'>{t('pricing.standard')}</Table.HeaderCell>
                                <Table.HeaderCell colSpan='1'
                                                  textAlign='center'>{t('pricing.priority')}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell colSpan='2' textAlign='center'
                                            className="price-row">{t('pricing.min')}<sup>2</sup></Table.Cell>
                                <Table.Cell className="cell">
                                    <Input className="price-input" type='number'
                                           name='KazEOSat2_mono_archive_min_area'
                                           value={this.state.KazEOSat2_mono_archive_min_area}
                                           onChange={e => this.handleMinAreaChange(e, 'KazEOSat-2', 'mono', 'archive', 'L1')}/>
                                </Table.Cell>
                                <Table.Cell className="cell">
                                    <Input className="price-input" type='number'
                                           name='KazEOSat2_mono_standard_min_area'
                                           value={this.state.KazEOSat2_mono_standard_min_area}
                                           onChange={e => this.handleMinAreaChange(e, 'KazEOSat-2', 'mono', 'standard', 'L1')}/>
                                </Table.Cell>
                                <Table.Cell className="cell">
                                    <Input className="price-input" type='number'
                                           name='KazEOSat2_mono_priority_min_area'
                                           value={this.state.KazEOSat2_mono_priority_min_area}
                                           onChange={e => this.handleMinAreaChange(e, 'KazEOSat-2', 'mono', 'priority', 'L1')}/>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell colSpan='1' textAlign='center'
                                            className="price-row">6.5 {t('pricing.m')}</Table.Cell>
                                <Table.Cell colSpan='1' textAlign='center' className="price-row">L1</Table.Cell>
                                <Table.Cell className="cell">
                                    <Input className="price-input" type='number' step='0.01'
                                           value={this.state.KazEOSat2_L1_mono_archive}
                                           name='KazEOSat2_L1_mono_archive'
                                           onChange={(e) => this.handlePriceChange(e, this.getId('KazEOSat-2', 'mono', 'archive', 'L1'))}/>
                                </Table.Cell>
                                <Table.Cell className="cell">
                                    <Input className="price-input" type='number' step='0.01'
                                           value={this.state.KazEOSat2_L1_mono_standard}
                                           name='KazEOSat2_L1_mono_standard'
                                           onChange={(e) => this.handlePriceChange(e, this.getId('KazEOSat-2', 'mono', 'standard', 'L1'))}/>
                                </Table.Cell>
                                <Table.Cell className="cell">
                                    <Input className="price-input" type='number' step='0.01'
                                           value={this.state.KazEOSat2_L1_mono_priority}
                                           name='KazEOSat2_L1_mono_priority'
                                           onChange={(e) => this.handlePriceChange(e, this.getId('KazEOSat-2', 'mono', 'priority', 'L1'))}/>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell colSpan='1' rowSpan='3' textAlign='center'
                                            className="price-row">{t('pricing.channels')}: r, g, b, nir</Table.Cell>
                                <Table.Cell colSpan='1' textAlign='center' className="price-row">L2</Table.Cell>
                                <Table.Cell className="cell">
                                    <Input className="price-input" type='number' step='0.01'
                                           value={this.state.KazEOSat2_L2_mono_archive} name='KazEOSat2_L2_mono_archive'
                                           onChange={(e) => this.handlePriceChange(e, this.getId('KazEOSat-2', 'mono', 'archive', 'L2'))}/>
                                </Table.Cell>
                                <Table.Cell className="cell">
                                    <Input className="price-input" type='number' step='0.01'
                                           value={this.state.KazEOSat2_L2_mono_standard}
                                           name='KazEOSat2_L2_mono_standard'
                                           onChange={(e) => this.handlePriceChange(e, this.getId('KazEOSat-2', 'mono', 'standard', 'L2'))}/>
                                </Table.Cell>
                                <Table.Cell className="cell">
                                    <Input className="price-input" type='number' step='0.01'
                                           value={this.state.KazEOSat2_L2_mono_priority}
                                           name='KazEOSat2_L2_mono_priority'
                                           onChange={(e) => this.handlePriceChange(e, this.getId('KazEOSat-2', 'mono', 'priority', 'L2'))}/>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell colSpan='1' textAlign='center' className="price-row"
                                            style={{borderLeft: '1px solid #E3E4E5'}}>L3</Table.Cell>
                                <Table.Cell className="cell">
                                    <Input className="price-input" type='number' step='0.01'
                                           value={this.state.KazEOSat2_L3_mono_archive} name='KazEOSat2_L3_mono_archive'
                                           onChange={(e) => this.handlePriceChange(e, this.getId('KazEOSat-2', 'mono', 'archive', 'L3'))}/>
                                </Table.Cell>
                                <Table.Cell className="cell">
                                    <Input className="price-input" type='number' step='0.01'
                                           value={this.state.KazEOSat2_L3_mono_standard}
                                           name='KazEOSat2_L3_mono_standard'
                                           onChange={(e) => this.handlePriceChange(e, this.getId('KazEOSat-2', 'mono', 'standard', 'L3'))}/>
                                </Table.Cell>
                                <Table.Cell className="cell">
                                    <Input className="price-input" type='number' step='0.01'
                                           value={this.state.KazEOSat2_L3_mono_priority}
                                           name='KazEOSat2_L3_mono_priority'
                                           onChange={(e) => this.handlePriceChange(e, this.getId('KazEOSat-2', 'mono', 'priority', 'L3'))}/>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell colSpan='1' textAlign='center' className="price-row"
                                            style={{borderLeft: '1px solid #E3E4E5'}}>L4</Table.Cell>
                                <Table.Cell className="cell">
                                    <Input className="price-input" type='number' step='0.01'
                                           value={this.state.KazEOSat2_L4_mono_archive} name='KazEOSat2_L4_mono_archive'
                                           onChange={(e) => this.handlePriceChange(e, this.getId('KazEOSat-2', 'mono', 'archive', 'L4'))}/>
                                </Table.Cell>
                                <Table.Cell className="cell">
                                    <Input className="price-input" type='number' step='0.01'
                                           value={this.state.KazEOSat2_L4_mono_standard}
                                           name='KazEOSat2_L4_mono_standard'
                                           onChange={(e) => this.handlePriceChange(e, this.getId('KazEOSat-2', 'mono', 'standard', 'L4'))}/>
                                </Table.Cell>
                                <Table.Cell className="cell">
                                    <Input className="price-input" type='number' step='0.01'
                                           value={this.state.KazEOSat2_L4_mono_priority}
                                           name='KazEOSat2_L4_mono_priority'
                                           onChange={(e) => this.handlePriceChange(e, this.getId('KazEOSat-2', 'mono', 'priority', 'L4'))}/>
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                    <Header as='h3' textAlign='center' style={{
                        marginRight: '10vw',
                        marginLeft: '10vw'
                    }}>{t('pricing.prices')} {this.state.currency === 'kzt' ? t('pricing.in_tenge') : t('pricing.in_dollar')} {t('pricing.urgent')}</Header>
                    <span style={{color: 'red', fontWeight: 'bold'}}>*</span> {t('pricing.reminder')}.
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell colSpan='1'
                                                  textAlign='center'>{t('pricing.acquisition')}</Table.HeaderCell>
                                <Table.HeaderCell colSpan='2' textAlign='center'>{t('pricing.mono')}</Table.HeaderCell>
                                <Table.HeaderCell colSpan='2'
                                                  textAlign='center'>{t('pricing.stereo')}</Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell colSpan='1'
                                                  textAlign='center'>{t('pricing.prices')} {this.state.currency === 'kzt' ? t('pricing.in_tenge') : t('pricing.in_dollar')} {t('pricing.for_km')}<sup>2</sup></Table.HeaderCell>
                                <Table.HeaderCell colSpan='1'
                                                  textAlign='center'>{t('pricing.standard')}</Table.HeaderCell>
                                <Table.HeaderCell colSpan='1'
                                                  textAlign='center'>{t('pricing.priority')}</Table.HeaderCell>
                                <Table.HeaderCell colSpan='1'
                                                  textAlign='center'>{t('pricing.standard')}</Table.HeaderCell>
                                <Table.HeaderCell colSpan='1'
                                                  textAlign='center'>{t('pricing.priority')}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell colSpan='1' textAlign='center'
                                            className="price-row">{t('pricing.min')}<sup>2</sup></Table.Cell>
                                <Table.Cell colSpan='1' textAlign='center'>
                                    <Input className="price-input" type='number'
                                           value={this.state.express_mono_standard_price}
                                           name='express_mono_standard_price'
                                           onChange={e => this.handleMinAreaChange(e, 'KazEOSat-1', 'mono', 'standard', 'L1')} />
                                </Table.Cell>
                                <Table.Cell colSpan='1' textAlign='center'>
                                    <Input className="price-input" type='number'
                                           value={this.state.express_mono_priority_price}
                                           onChange={e => this.handleMinAreaChange(e, 'KazEOSat-1', 'mono', 'priority', 'L1')}
                                           name="express_mono_priority_price"/>
                                </Table.Cell>
                                <Table.Cell colSpan='1' textAlign='center'>
                                    <Input className="price-input" type='number'
                                           value={this.state.express_stereo_standard_price}
                                           onChange={e => this.handleMinAreaChange(e, 'KazEOSat-1', 'stereo', 'standard', 'L1')}
                                           name="express_stereo_standard_price"/>
                                </Table.Cell>
                                <Table.Cell colSpan='1' textAlign='center'>
                                    <Input className="price-input" type='number'
                                           value={this.state.express_stereo_priority_price}
                                           onChange={e => this.handleMinAreaChange(e, 'KazEOSat-1', 'stereo', 'priority', 'L1')}
                                           name="express_stereo_priority_price"/>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell colSpan='1' textAlign='center' className="price-row">KazEOSat-1</Table.Cell>
                                <Table.Cell rowSpan='2' className="cell">
                                    <Input className="price-input" type='number' step='0.01'
                                           value={this.getExpressPrice('KazEOSat-1', 'mono', 'standard', 'L1')}
                                           onChange={this.handleChange}/>
                                </Table.Cell>
                                <Table.Cell rowSpan='2' className="cell">
                                    <Input className="price-input" type='number' step='0.01'
                                           value={this.getExpressPrice('KazEOSat-1', 'mono', 'priority', 'L1')}
                                           onChange={this.handleChange}/>
                                </Table.Cell>
                                <Table.Cell rowSpan='2' className="cell">
                                    <Input className="price-input" type='number' step='0.01'
                                           value={this.getExpressPrice('KazEOSat-1', 'stereo', 'standard', 'L1')}
                                           onChange={this.handleChange}/>
                                </Table.Cell>
                                <Table.Cell rowSpan='2' className="cell">
                                    <Input className="price-input" type='number' step='0.01'
                                           value={this.getExpressPrice('KazEOSat-1', 'stereo', 'priority', 'L1')}
                                           onChange={this.handleChange}/>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell colSpan='1' textAlign='center' className="price-row">KazEOSat-2</Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </div>}
                <Confirm
                    ref={this.confirm}
                    onCancelClicked={this.cancelPriceChange}
                    onConfirmClicked={this.changePrice}
                />
            </div>
        );
    }
}

PriceList.propTypes = {
    // prices: PropTypes.array.isRequired,
    getPrices: PropTypes.func.isRequired,
    changePrice: PropTypes.func.isRequired,
    changeMinArea: PropTypes.func.isRequired
};

const mapStateToProps = state => {
    return {
        isAuthenticated: !!state.user.token,
        prices: state.prices
    }
};

export default connect(mapStateToProps, {getPrices, changePrice, changeMinArea})(PriceList);
