import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Form,
  Button,
  Header,
  Card,
  Icon,
  Popup,
  Input,
} from "semantic-ui-react";
import "./ResetPassword.css";
import { sendEmail, resetPassword, logout } from "../../../actions/auth";
import { updateMyself } from "../../../actions/users";
import { t } from "../../../lang";
import Toast from "../../messages/Toast";
import InlineError from "../../messages/InlineError";

class ResetPassword extends Component {
  state = {
    email: "",
    password: "",
    repassword: "",
    errors: {},
    loading: false,
    emailSend: false,
    token: "",
    passwordRules: t("signup.rule"),
    eye: "eye",
    passwordType: "password",
    current_password: "",
  };

  toaster = React.createRef();

  componentDidMount() {
    if (this.props.match.params.token)
      this.setState({ token: this.props.match.params.token });
  }

  handleChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
      errors: {},
    });
  };

  handleEmailSubmit = () => {
    const errors = this.validate();
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });
      this.props.sendEmail(this.state.email).then((res) => {
        this.setState({ loading: false });
        if (res.success) {
          this.toaster.current.configSuccessResponse(res);
          this.setState({ emailSend: true });
        }
      });
    }
  };

  handlePasswordSubmit = () => {
    const errors = this.validate();
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });
      if (!this.props.isAuthenticated) {
        this.props
          .resetPassword({
            token: this.props.match.params.token,
            password: this.state.password,
          })
          .then((res) => {
            if (res.success) {
              this.setState({ loading: false });
              this.toaster.current.configSuccessResponse(res);
              this.props.history.push("/signin");
            }
          });
      } else {
        this.props
          .updateMyself({
            id: this.props.user.id,
            user: {
              password: this.state.password,
              current_password: this.state.current_password,
            },
          })
          .then((res) => {
            if (res.success) {
              this.setState({ loading: false });
              this.toaster.current.configSuccessResponse(res);
              this.props.logout(this.props.user.token);
            }
          });
      }
    }
  };

  togglePassword = () => {
    if (this.state.eye === "eye") {
      this.setState({ eye: "eye slash", passwordType: "text" });
    } else {
      this.setState({ eye: "eye", passwordType: "password" });
    }
  };

  validate = () => {
    const errors = {};

    let emailPattern = new RegExp(
      "^[a-z0-9]+(.[_a-z0-9]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,15})$",
      "i"
    );
    let passwordPattern = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})",
      "i"
    );

    if (this.props.isAuthenticated) {
      if (this.state.current_password === "") {
        errors.current_password = "Password is empty";
      }
      if (!passwordPattern.test(this.state.current_password)) {
        errors.current_password = t("signup.errors.valid.password");
      }

      if (this.state.current_password.length < 8) {
        errors.current_password = t("signup.errors.min_password");
      }
      if (this.state.password === this.state.current_password) {
        errors.password = t("reset.repeat");
        errors.current_password = t("reset.repeat");
      }
      if (
        this.state.password === this.state.repassword &&
        this.state.password === this.state.current_password
      ) {
        errors.repassword = t("reset.repeat");
      }
    }

    if (!this.state.token && !this.props.isAuthenticated) {
      if (this.state.email === "") {
        errors.email = "Email is empty";
      }
      if (
        this.state.email &&
        !emailPattern.test(this.state.email.toLowerCase())
      ) {
        errors.email = t("signin.errors.email");
      }
    }
    if (this.state.token || this.props.isAuthenticated) {
      if (this.state.password === "") {
        errors.password = "Password is empty";
      }
      if (this.state.repassword === "") {
        errors.password = "Password is empty";
      }
      if (!passwordPattern.test(this.state.password)) {
        errors.password = t("signup.errors.valid.password");
      }

      if (!passwordPattern.test(this.state.repassword)) {
        errors.repassword = t("signup.errors.valid.password");
      }

      if (this.state.password.length < 8) {
        errors.password = t("signup.errors.min_password");
      }

      if (this.state.repassword.length < 8) {
        errors.repassword = t("signup.errors.min_password");
      }

      if (this.state.password !== this.state.repassword) {
        errors.password = t("signup.errors.match");
        errors.repassword = t("signup.errors.match");
      }
    }

    return errors;
  };

  render() {
    const {
      email,
      emailSend,
      loading,
      token,
      password,
      repassword,
      current_password,
    } = this.state;
    const { isAuthenticated } = this.props;
    return (
      <div>
        <Header className="reset-header">
          {!token ? t("reset.header_email") : t("reset.header_password")}
        </Header>
        {!emailSend && !token && !isAuthenticated && (
          <Card className="card-email">
            <Card.Content textAlign="center">
              <label>
                <strong>{t("reset.content_email")}</strong>
              </label>
            </Card.Content>
            <Card.Content>
              <Form className="form-reset">
                <Form.Field error={!!this.state.errors.email}>
                  <label>{t("signup.email")}</label>
                  <Input
                    fluid
                    placeholder="example@example.com"
                    type="text"
                    name="email"
                    value={email}
                    onChange={this.handleChange}
                  />
                  {this.state.errors.email && (
                    <InlineError text={this.state.errors.email} />
                  )}
                </Form.Field>
              </Form>
            </Card.Content>
            <Card.Content extra>
              <Button
                loading={loading}
                fluid
                positive
                type="submit"
                disabled={!this.state.email}
                onClick={this.handleEmailSubmit}
              >
                {t("reset.btn_email")}
              </Button>
            </Card.Content>
          </Card>
        )}
        {emailSend && !token && !isAuthenticated && (
          <Card className="card-send">
            <Card.Content>
              <Card.Description>
                <label>{t("reset.check_email")}</label>
              </Card.Description>
            </Card.Content>
            <Card.Content extra>
              <Button fluid>{t("reset.btn_sign_in")}</Button>
            </Card.Content>
          </Card>
        )}
        {(token || isAuthenticated) && (
          <Card className="card-send">
            <Card.Content>
              <Form className="form">
                {isAuthenticated && (
                  <Form.Field error={!!this.state.errors.current_password}>
                    <label>
                      {t("reset.current_password")}
                      &nbsp;
                      <Popup
                        content={this.state.passwordRules}
                        position="top center"
                        trigger={<Icon link name="info circle" color="blue" />}
                      />
                    </label>
                    <Input
                      fluid
                      className="input-password"
                      icon={
                        <Icon
                          color="blue"
                          link
                          name={this.state.eye}
                          onClick={this.togglePassword}
                        />
                      }
                      type={this.state.passwordType}
                      name="current_password"
                      value={current_password}
                      onChange={this.handleChange}
                    />
                    {this.state.errors.current_password && (
                      <InlineError text={this.state.errors.current_password} />
                    )}
                  </Form.Field>
                )}
                <Form.Field error={!!this.state.errors.password}>
                  <label>
                    {t("signup.password")}
                    &nbsp;
                    <Popup
                      content={this.state.passwordRules}
                      position="top center"
                      trigger={<Icon link name="info circle" color="blue" />}
                    />
                  </label>
                  <Input
                    fluid
                    className="input-password"
                    icon={
                      <Icon
                        color="blue"
                        link
                        name={this.state.eye}
                        onClick={this.togglePassword}
                      />
                    }
                    type={this.state.passwordType}
                    name="password"
                    value={password}
                    onChange={this.handleChange}
                  />
                  {this.state.errors.password && (
                    <InlineError text={this.state.errors.password} />
                  )}
                </Form.Field>
                <Form.Field error={!!this.state.errors.repassword}>
                  <label>
                    {t("reset.confirm_password")}
                    &nbsp;
                    <Popup
                      content={this.state.passwordRules}
                      position="top center"
                      trigger={<Icon link name="info circle" color="blue" />}
                    />
                  </label>
                  <Input
                    fluid
                    className="input-password"
                    icon={
                      <Icon
                        color="blue"
                        link
                        name={this.state.eye}
                        onClick={this.togglePassword}
                      />
                    }
                    type={this.state.passwordType}
                    name="repassword"
                    value={repassword}
                    onChange={this.handleChange}
                  />
                  {this.state.errors.repassword && (
                    <InlineError text={this.state.errors.repassword} />
                  )}
                </Form.Field>
              </Form>
            </Card.Content>
            <Card.Content extra>
              <Button
                positive
                disabled={!this.state.password || !this.state.repassword}
                fluid
                onClick={this.handlePasswordSubmit}
              >
                {t("reset.btn_password")}
              </Button>
            </Card.Content>
          </Card>
        )}
        <Toast ref={this.toaster} />
      </div>
    );
  }
}

ResetPassword.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  sendEmail: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  updateMyself: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: !!state.user.token,
    user: state.user,
  };
};

export default connect(
  mapStateToProps,
  { sendEmail, resetPassword, updateMyself, logout }
)(ResetPassword);
