import React, { Component } from 'react'
import { Form, Button, TextArea, Icon, Input, Popup, Progress, Card, Header } from 'semantic-ui-react';
import InlineError from '../../messages/InlineError';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import InputPhone from 'react-phone-number-input/input'
import 'react-responsive-ui/style.css';
import { signup } from '../../../actions/auth';
import zxcvbn from 'zxcvbn'
import Toast from '../../messages/Toast';
import './SignUp.css';
import { t } from '../../../lang';

 class SignUp extends Component {
    state = {
        data: {
            email: '',
            password: '',
            first_name: '',
            last_name: '',
            gender: null,
            company: '',
            purpose: '',
            phone: ''
        },
        genders: [
            {key: 3, value: null, text: t('signup.gender.unclear')},
            {key: 1, value: 'male', text: t('signup.gender.male')},
            {key: 2, value: 'female', text: t('signup.gender.female')},
        ],
        repassword: '',
        loading: false,
        errors: [],
        eye: 'eye',
        passwordType: 'password',
        passwordRules: t('signup.rule'),
        progress: 0,
    };

    toaster = React.createRef();

    onChange = e => {
        this.setState({
            errors: { ...this.state.errors, [e.target.name]: null },
            data: { ...this.state.data, [e.target.name]: e.target.value }
        });
        if(e.target.name==='password') {
            this.checkStrength(e.target.value )
        }
    };

    onPhoneChange = (e) => {
        if(e) {
            this.setState({
                errors: {...this.state.errors, phone: null},
                data: {...this.state.data, phone: e}
            });
        }
    };

     handleGenderChange = (e, data) => {
         this.setState({data: {...this.state.data, gender: data.value}});
     };

    checkStrength = (value) => {
        
        if(value.length > 0){
            switch (zxcvbn(value).score) {
                case 0:
                    this.setState({progress: 0});
                    break;
                case 1:
                    this.setState({progress: 40});
                    break;
                case 2:
                    this.setState({progress: 60});
                    break;
                case 3:
                    this.setState({progress: 80});
                    break;
                case 4:
                    this.setState({progress: 100});
                    break;
                default:
                    break;
            } 
        } else {
            this.setState({progress: 0})

        }
    };

    onChangeRepassword = e => {
        this.setState({
            errors: { ...this.state.errors, [e.target.name]: null}
        });
        this.setState({repassword: e.target.value})
    };

    onSubmit = () => {
        const errors = this.validate();
        this.setState({ errors });
        if (Object.keys(errors).length === 0) {
            this.setState({loading: true});
            if(!this.state.data.gender) delete this.state.data.gender;
            this.props.signup(this.state.data)
                .then(res => {
                    if(res && !res.success){
                        // this.toaster.current.configErrorResponse(res.error)
                    } else if(res &&  res.success) {
                        this.toaster.current.configSuccessResponse(res)
                    }
                    this.setState({loading: false});
                })
        }
    };

    validate = () => {

    const errors = {};
    if (this.state.data.email==='') {
        errors.email = "Email is empty"
    }
    if (this.state.data.password==='') {
        errors.password = "Password is empty"
    }
    if (this.state.repassword==='') {
        errors.password = "Password is empty"
    }
    if (this.state.first_name==='') {
        errors.first_name = "First name is empty"
    }
    if (this.state.last_name==='') {
        errors.last_name = "Last name is empty"
    }
    if (this.state.company==='') {
        errors.company = "Company is empty"
    }
    if (this.state.purpose==='') {
        errors.purpose = "Puspose is empty"
    }
    if (this.state.phone==='') {
        errors.phone = "Phone is empty"
    }

    let emailPattern = new RegExp('^[a-z0-9]+(.[_a-z0-9]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,15})$', 'i');
    let passwordPattern = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})", "i");
    let phonePattern = new RegExp("^[0-9]{11}$", "i");
    let email = this.state.data.email.toLowerCase();
    if(this.state.data.email && !emailPattern.test(email)) {
        errors.email = t('signup.errors.valid.email');
    }
    if(!passwordPattern.test(this.state.data.password)) {
        errors.password = t('signup.errors.valid.password');
    }

    if(!passwordPattern.test(this.state.repassword)) {
        errors.repassword = t('signup.errors.valid.password');
    }

    let phone = this.state.data.phone.replace(' ', '').replace('+', '');

    if(!phonePattern.test(phone)) {
        errors.phone = t('signup.errors.valid.phone');
    }

    if (this.state.data.password.length < 8) {
        errors.password = t('signup.errors.min_password');
    }

    if (this.state.repassword.length < 8) {
        errors.repassword = t('signup.errors.min_password');
    }

    if (this.state.data.first_name.length > 32) {
        errors.first_name = t('signup.errors.max.name');
    }

    if (this.state.data.last_name.length > 32) {
        errors.last_name = t('signup.errors.max.name');
    }

    if (this.state.data.company.length > 64) {
        errors.company = t('signup.errors.max.company');
    }

    if (this.state.data.purpose.length > 500) {
        errors.purpose = t('signup.errors.max.purpose');
    }

    if (this.state.data.password !== this.state.repassword) {
        errors.password = t('signup.errors.match');
        errors.repassword = t('signup.errors.match');
    }

    return errors;
    };

    togglePassword = () => {
        if(this.state.eye === 'eye'){
            this.setState({eye: 'eye slash', passwordType: 'text'});
        } else {
            this.setState({eye: 'eye', passwordType: 'password'});
        }
    };

     onFocus = e => {
         if(this.state.errors) {
             this.setState({errors: []})
         }
     };

    onEnter = (event, callback) => event.key === 'Enter' && callback();

    render() {
        return (
            <div className='sign-up-container'>
                <Card className='sign-up-card'>
                    <Header className='sign-up-card-header'>{t('signup.header')}</Header>
                    <Card.Content>
                        <Form className='sign-up-form' onSubmit={this.onSubmit}>
                            <Form.Field error={!!this.state.errors.first_name}>
                                <label htmlFor="first_name">{t('signup.first_name')}<span className='star'>*</span></label>
                                <input
                                    type="text"
                                    id="first_name"
                                    name="first_name"
                                    placeholder={t('signup.first_name')}
                                    value={this.state.data.first_name}
                                    onChange={this.onChange} />
                                {this.state.errors.first_name && <InlineError text={this.state.errors.first_name}/>}
                            </Form.Field>
                            <Form.Field error={!!this.state.errors.last_name}>
                                <label htmlFor="last_name">{t('signup.last_name')}<span className='star'>*</span></label>
                                <input
                                    type="text"
                                    id="last_name"
                                    name="last_name"
                                    placeholder={t('signup.last_name')}
                                    value={this.state.data.last_name}
                                    onChange={this.onChange} />
                                {this.state.errors.last_name && <InlineError text={this.state.errors.last_name}/>}
                            </Form.Field>
                            <Form.Field error={this.state.errors && !!this.state.errors.email}>
                                <label htmlFor="email">{t('signup.email')}<span className='star'>*</span></label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    placeholder="example@example.com"
                                    value={this.state.data.email}
                                    onChange={this.onChange} />
                                {this.state.errors.email && <InlineError text={this.state.errors.email}/>}
                            </Form.Field>
                            <Form.Field error={this.state.errors && !!this.state.errors.phone}>
                                <label htmlFor="phone">{t('signup.phone')}<span className='star'>*</span></label>
                                <InputPhone
                                    id="phone"
                                    name="phone"
                                    placeholder="+77000000000"
                                    value={this.state.data.phone}
                                    onChange={this.onPhoneChange} />
                                {this.state.errors.phone && <InlineError text={this.state.errors.phone} />}
                            </Form.Field>
                            <Form.Field>
                                <Form.Select
                                    name='gender'
                                    value={this.state.data.gender}
                                    options={this.state.genders}
                                    onChange={this.handleGenderChange}
                                    label={<label>{t('signup.gender.label')}</label>}
                                />
                            </Form.Field>
                            <Form.Field error={!!this.state.errors.company}>
                                <label htmlFor="company">{t('signup.company')}<span className='star'>*</span></label>
                                <input
                                    type="text"
                                    id="company"
                                    name="company"
                                    placeholder="Google Inc."
                                    value={this.state.data.company}
                                    onChange={this.onChange} />
                                {this.state.errors.company && <InlineError text={this.state.errors.company}/>}
                            </Form.Field>
                            <Form.Field error={!!this.state.errors.purpose}>
                                <label htmlFor="purpose">{t('signup.purpose')}<span className='star'>*</span></label>
                                <TextArea
                                    id="purpose"
                                    name="purpose"
                                    placeholder={`${t('signup.purpose_placeholder')}...`}
                                    value={this.state.data.purpose}
                                    onChange={this.onChange} />
                                {this.state.errors.purpose && <InlineError text={this.state.errors.purpose}/>}
                            </Form.Field>
                            <Form.Field error={!!this.state.errors.password}>
                                <label htmlFor="password">
                                    {t('signup.password')}
                                    <span className='star'>*</span>
                                    &nbsp;
                                    <Popup
                                        content={this.state.passwordRules}
                                        position='top center'
                                        trigger={<Icon link name='info circle' color='blue' />}
                                    />
                                </label>
                                <Input
                                    icon={<Icon link name={this.state.eye} onClick={this.togglePassword} />}
                                    type={this.state.passwordType}
                                    id="password" 
                                    name="password" 
                                    placeholder="••••••••"
                                    value={this.state.data.password}
                                    onChange={this.onChange} />
                                    <Progress 
                                        className='sign-up-password-progress'
                                        percent={this.state.progress}
                                        attached='bottom'
                                        indicating 
                                    />
                                {this.state.errors.password && <InlineError text={this.state.errors.password}/>}
                            </Form.Field>
                            <Form.Field error={!!this.state.errors.repassword}>
                            <label htmlFor="repassword">
                                {t('signup.repeat_password')}
                                <span className='star'>*</span>
                                &nbsp;
                                <Popup
                                    content={this.state.passwordRules}
                                    position='top center'
                                    trigger={<Icon link name='info circle' color='blue' />}
                                />
                            </label>
                            <Input
                                icon={<Icon link name={this.state.eye} onClick={this.togglePassword} />}
                                type={this.state.passwordType}
                                id="repassword" 
                                name="repassword"
                                placeholder="••••••••"
                                onKeyPress={e => this.onEnter(e, this.onSubmit)}
                                value={this.state.repassword}
                                onChange={this.onChangeRepassword} />
                            {this.state.errors.notequal && <div><InlineError text={this.state.errors.notequal}/><br/></div>}
                            {this.state.errors.repassword && <InlineError text={this.state.errors.repassword}/>}
                        </Form.Field>
                    </Form>
                    </Card.Content>
                    <Card.Content extra>
                        <Popup className='sign-up-form-sign-in-button' size='small' flowing content={t('signup.popup')} trigger={<Button basic onClick={()=>this.props.history.push('/signin')}>{t('signup.signin')}</Button>} />
                        <Button className='sign-up-form-sign-up-button' primary onClick={this.onSubmit} loading={this.state.loading} disabled={this.state.data.email === '' || this.state.data.password === '' || this.state.repassword === '' || this.state.data.first_name === '' || this.state.data.last_name === '' || this.state.data.company === '' || this.state.data.purpose === ''}>{t('signup.signup')}</Button>
                    </Card.Content>
                </Card>
                <Toast ref={this.toaster}/>
            </div>
        )
    }
}

SignUp.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired,
    signup: PropTypes.func.isRequired
};

export default connect(null, {signup})(SignUp);