import React, {Component} from 'react';
import {Button, Icon, Modal, Image} from 'semantic-ui-react';
import Dropzone from 'react-dropzone'
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import './ImageDrop.css';
import {t} from '../../lang';

class ImageDrop extends Component {

    state ={
        isActive: false,
        file: null,
        error: '',
        crop: null,
        croppedImage: null
    };

    showModal = () => {
        this.deleteFile();
        this.setState({ isActive: true });
    };

    hideModal = () => {
        this.setState({ isActive: false });
    };

    setFile = acceptedFile => {
        if(acceptedFile && acceptedFile.length) {
            if (acceptedFile[0].size > 2097152) {
                this.setState({error: t('image.errors.size')});
            } else {
                const reader = new FileReader();
                reader.addEventListener("load", () => {
                        this.setState({ src: reader.result, file: acceptedFile, error: '' })
                    }
                );
                reader.readAsDataURL(acceptedFile[0]);
            }
        } else {
            this.setState({error: t('image.errors.type')})
        }
    };

    deleteFile = () => {
        this.setState({file: null, src: null, crop: null, croppedImage: null});
    };

    sendImage = async () => {
        await this.makeClientCrop(this.state.crop);
        if(this.state.croppedImage) {
            const file = await this.blobToFile(this.state.croppedImage, 'avatar.jpeg', {type: 'image/jpeg'});
            if (file) {
                this.props.onFileChoosen(file);
            }
        }
    };

    // If you setState the crop in here you should return false.
    onImageLoaded = image => {
        this.imageRef = image;
        const width = image.width > image.height ? (image.height / image.width) * 100 : 100;
        const height = image.height > image.width ? (image.width / image.height) * 100 : 100;
        const x = width === 100 ? 0 : (100 - width) / 2;
        const y = height === 100 ? 0 : (100 - height) / 2;

        const crop = {
            unit: '%',
            aspect: 1,
            width,
            height,
            x,
            y,
        }

        this.makeClientCrop(crop);
        this.setState({crop})

        return false;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop && crop.width && crop.height) {
            const croppedImage = await this.getCroppedImg(
                this.imageRef,
                crop
            );
            this.setState({ croppedImage });
        }
    }

    getCroppedImg(image, crop) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;

        if(crop.unit === '%') {
            canvas.width = crop.width*image.width/100;
            canvas.height = crop.height*image.height/100;
        }
        const ctx = canvas.getContext("2d");

        if(crop.unit === 'px') {
            ctx.drawImage(
                image,
                crop.x * scaleX,
                crop.y * scaleY,
                crop.width * scaleX,
                crop.height * scaleY,
                0,
                0,
                crop.width,
                crop.height
            );
        } else {
            ctx.drawImage(
                image,
                crop.x*image.width/100 * scaleX,
                crop.y*image.width/100 * scaleY,
                canvas.width * scaleX,
                canvas.height * scaleY,
                0,
                0,
                canvas.width,
                canvas.height
            );
        }

        // As Base64 string
        return canvas.toDataURL('image/jpeg');
    }

    async blobToFile(base64, fileName, type){
        let file = null;
        await fetch(base64)
            .then(res => res.blob())
            .then(blob => {
                file = new File([blob], fileName, type);
            });
        return file;
    }

    render() {
        const {isActive, file, error, crop, src, croppedImage} = this.state;
        return (
            <Modal size='small' open={isActive} onClose={this.hideModal}>
                <Modal.Header>{t('image.header')}</Modal.Header>
                <Modal.Content>
                    <Dropzone onDrop={acceptedFile => this.setFile(acceptedFile)} accept="image/*">
                        {({getRootProps, getInputProps}) => (
                            <section>
                                <div {...getRootProps()} className='dropzone'>
                                    <input {...getInputProps()} type='file' accept="image/*" multiple={false} />
                                    <p>{t('image.text')}</p>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                    {file && <p>{file[0].name} - {file[0].size} bytes <Icon onClick={this.deleteFile} link color='red' name="x"/></p>}
                    {error && <p style={{color: 'red'}}>{error}</p>}

                    {src && (
                        <ReactCrop
                            src={src}
                            crop={crop}
                            ruleOfThirds
                            onImageLoaded={this.onImageLoaded}
                            onComplete={this.onCropComplete}
                            onChange={this.onCropChange}
                            minWidth={10}
                            minHeight={10}
                            keepSelection
                            circularCrop
                        />
                    )}
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        negative
                        content={t('image.cancel')}
                        onClick={this.hideModal}
                    />
                    <Button
                        positive
                        content={t('image.confirm')}
                        disabled={!file}
                        onClick={this.sendImage}
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}

export default ImageDrop;
