import React from "react";
import {
  Menu,
  Dropdown,
  Icon,
  Label,
  Image,
  Button,
  Select,
} from "semantic-ui-react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./Navbar.css";
import { logout } from "../../../actions/auth";
import { getCart } from "../../../actions/cart";
import { t } from "../../../lang";

class Navbar extends React.Component {
  state = {
    isMenuOpen: false,
    langOptions: [
      { key: 1, value: "en", text: "ENG" },
      { key: 2, value: "ru", text: "РУС" },
    ],
    src: require("../../../assets/images/noavatar.webp"),
  };

  componentWillMount() {
    if (this.props.isAuthenticated) this.props.getCart();
    if (this.props.user.image_url)
      this.setState({ src: this.props.user.image_url });
  }

  componentDidUpdate(prevProps, prevState) {
    const { user } = this.props;
    if (prevProps.user.image_url !== user.image_url) {
      this.setState({
        src: user.image_url
          ? user.image_url
          : require("../../../assets/images/noavatar.webp"),
      });
    }
  }

  toggleBadge = (b) => {
    this.setState({ isMenuOpen: b });
  };

  logOut = () => {
    this.props.logout(this.props.user.token);
  };

  goTo = (type) => {
    this.props.history.push(type);
  };

  orderChosen = () => {
    this.goTo("/form");
  };

  onImageError = () => {
    this.setState({ src: require("../../../assets/images/noavatar.webp") });
  };

  render() {
    return (
      <React.Fragment>
        <Menu id="navbar" fixed="top" secondary>
          <div id="logo" onClick={() => this.goTo("/")}>
            <Image
              src={require("../../../assets/images/logo_new.png")}
              size="small"
            />
          </div>
          <Menu.Item position="right">
            <Select
              button
              className="lang"
              value={this.props.lang}
              onChange={this.props.toggleLang}
              selection
              options={this.state.langOptions}
            />
            {this.props.isAuthenticated &&
              this.props.location.pathname !== "/form" && (
                <Button
                  className="order-nav"
                  inverted
                  onClick={this.orderChosen}
                >
                  {t(`navbar.order_new`)}
                </Button>
              )}
            {this.props.isAuthenticated && (
              <Dropdown
                onOpen={() => this.toggleBadge(true)}
                onClose={() => this.toggleBadge(false)}
                trigger={
                  <Label id="profile">
                    {this.props.location.pathname !== "/cart" &&
                      !this.state.isMenuOpen &&
                      this.props.cart &&
                      this.props.cart.images &&
                      this.props.cart.images.length > 0 && (
                        <Label color="red" circular id="badge">
                          {this.props.cart.images.length}
                        </Label>
                      )}
                    <Image
                      id="avatar"
                      src={this.state.src}
                      avatar
                      onError={this.onImageError}
                    />
                    &nbsp;&nbsp;
                    {`${this.props.user.last_name} ${this.props.user.first_name}`}
                  </Label>
                }
              >
                <Dropdown.Menu id="menu" direction="left" open={false}>
                  {this.props.location.pathname !== "/profile" && (
                    <Dropdown.Item
                      className="menu-item"
                      onClick={() => this.goTo("/profile")}
                    >
                      <Icon name="user circle" />
                      {t("navbar.profile")}
                    </Dropdown.Item>
                  )}
                  {this.props.location.pathname !== "/cart" && (
                    <Dropdown.Item
                      className="menu-item"
                      onClick={() => this.goTo("/cart")}
                    >
                      <Icon name="cart" />
                      {t("navbar.cart")}
                      {this.state.isMenuOpen &&
                        this.props.cart &&
                        this.props.cart.images &&
                        this.props.cart.images.length > 0 && (
                          <Label color="red" circular id="badge2">
                            {this.props.cart.images.length}
                          </Label>
                        )}
                    </Dropdown.Item>
                  )}
                  {this.props.location.pathname !== "/store" && (
                    <Dropdown.Item
                      className="menu-item"
                      onClick={() => this.goTo("/store")}
                    >
                      <Icon name="list layout" />
                      {t("navbar.store")}
                    </Dropdown.Item>
                  )}
                  {(this.props.user.role === "admin" ||
                    this.props.user.role === "manager" ||
                    this.props.user.role === "handler") && (
                    <Dropdown.Item
                      className="menu-item"
                      onClick={() => this.goTo("/orders")}
                    >
                      <Icon name="shopping bag" />
                      {t("navbar.orders")}
                    </Dropdown.Item>
                  )}
                  {this.props.user.role === "admin" && (
                    <Dropdown.Item
                      className="menu-item"
                      onClick={() => this.goTo("/control")}
                    >
                      <Icon name="sliders" />
                      {t("navbar.control")}
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item className="menu-item" onClick={this.logOut}>
                    <Icon name="log out" />
                    {t("navbar.logout")}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
            {!this.props.isAuthenticated && (
              <div>
                <Button inverted onClick={() => this.goTo("/signin")}>
                  {t("navbar.signin")}
                </Button>

                <Button inverted onClick={() => this.goTo("/signup")}>
                  {t("navbar.signup")}
                </Button>
              </div>
            )}
            {this.props.location.pathname !== "/" && (
              <Icon
                name="arrow left"
                className="back"
                onClick={this.props.history.goBack}
              />
            )}
          </Menu.Item>
        </Menu>
      </React.Fragment>
    );
  }
}

Navbar.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  getCart: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    isAuthenticated: !!state.user.token,
    user: state.user,
    cart: state.cart,
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    { logout, getCart }
  )(Navbar)
);
