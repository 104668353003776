import {
  LAYERS_GOTTEN,
  LAYERS_FAILED,
  QUICKLOOKS_SELECTED,
  STORES_GOTTEN,
} from "../types";
import api from "../api";
import { requestHasError } from "./error";

export const LayersGotten = (data) => ({
  type: LAYERS_GOTTEN,
  layers: data.satellite_images,
});
export const LayersFailed = (data) => ({
  type: LAYERS_FAILED,
});
export const setQuicklookList = (data) => ({
  type: QUICKLOOKS_SELECTED,
  quicklooks: data,
});

export const setStores = (data) => ({
  type: STORES_GOTTEN,
  store: data,
});

export const getLayers = (data, type) => (dispatch) =>
  api.layer.layers(data, type).then((res) => {
    if (res.success) {
      dispatch(LayersGotten(res));
    } else {
      if (!res.success) dispatch(requestHasError(res));
      dispatch(LayersFailed());
    }
    return res;
  });

export const setQuicklooks = (data) => (dispatch) => {
  dispatch(setQuicklookList(data));
};

export const getShareLink = (data) => (dispatch) =>
  api.layer.share_link(data).then((res) => {
    return res;
  });

export const getDataFromSharedLink = (data) => (dispatch) =>
  api.layer.data_from_url(data).then((res) => {
    return res;
  });

export const getStores = () => (dispatch) =>
  api.layer.get_stores().then((res) => {
    dispatch(setStores(res.stores));
    return res;
  });

export const createStore = (data) => (dispatch) =>
  api.layer.create_store(data).then((res) => {
    dispatch(getStores());
    return res;
  });

export const addQuicklooksToStore = (data) => (dispatch) =>
  api.layer.add_to_store(data).then((res) => {
    dispatch(getStores());
    return res;
  });

export const getStore = (data) => (dispatch) =>
  api.layer.get_store(data).then((res) => res);

export const deleteSatelliteImage = (data) => (dispatch) => {
  console.log(data);
  return api.layer.del_sat_img(data).then((res) => res);
};

export const updateStore = (data) => (dispatch) =>
  api.layer.update_store(data).then((res) => res);

export const deleteStore = (data) => (dispatch) =>
  api.layer.delete_store(data).then((res) => res);

export const getStoreById = (data) => (dispatch) =>
  api.layer.get_store_uuid(data).then((res) => {
    if (res.success) {
      dispatch(LayersGotten(res.store));
    } else {
      if (!res.success) dispatch(requestHasError(res));
      dispatch(LayersFailed());
    }
    return res;
  });
