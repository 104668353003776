import React, { Component } from "react";
import { Modal, Button, Icon, List, Form, Checkbox } from "semantic-ui-react";
import { t } from "../../../../../../lang";
import InlineError from "../../../../../messages/InlineError";

class StoreList extends Component {
  state = {
    isOpen: false,
    createNew: false,
    title: "",
    description: "",
    isPublic: true,
    checked: null,
    isSuccess: null,
  };

  showModal() {
    this.setState({ isOpen: true });
  }

  hideModal() {
    this.setState({ isOpen: false });
  }

  handleChange(e) {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
    });
  }

  onSuccess = (b) => {
    this.setState({ isSuccess: b });
    setTimeout(() => {
      this.hideModal();
    }, 1000);
  };

  cancelCreateNew = () => {
    this.setState({
      createNew: false,
      title: "",
      description: "",
      isPublic: true,
    });
  };

  render() {
    return (
      <Modal
        centered={false}
        size="mini"
        open={this.state.isOpen}
        onClose={() => this.hideModal()}
      >
        <Modal.Header>{t("sidebar.layers.saved")}</Modal.Header>
        <List divided relaxed style={{ margin: 0 }}>
          {this.props.store &&
            this.props.store.length > 0 &&
            this.props.store.map((s) => (
              <List.Item
                key={s.id}
                onClick={() => this.setState({ checked: s.id })}
                style={{
                  background:
                    this.state.isSuccess === true && this.state.checked === s.id
                      ? "#20BA45"
                      : this.state.isSuccess === false &&
                        this.state.checked === s.id
                      ? "#DC2828"
                      : "white",
                }}
                // positive={
                //   this.state.isSuccess === true && this.state.checked === s.id
                // }
                // negative={
                //   !this.state.isSuccess === false && this.state.checked === s.id
                // }
              >
                {s.title}
                <Checkbox
                  checked={s.id === this.state.checked}
                  radio
                  style={{ float: "right" }}
                  onChange={() => this.setState({ isSuccess: null })}
                />
              </List.Item>
            ))}
          {!this.state.createNew && (
            <List.Item
              style={{ color: "gray", textAlign: "center" }}
              onClick={() => this.setState({ createNew: true })}
            >
              <Icon name="plus" />
              {t("sidebar.layers.store.create_new")}
            </List.Item>
          )}
          {this.state.createNew && (
            <>
              <List.Item
                style={{ color: "gray", textAlign: "center" }}
                onClick={this.cancelCreateNew}
              >
                <Icon name="minus" />
                {t("sidebar.layers.store.cancel_new")}
              </List.Item>
              <List.Item>
                <Form size="mini">
                  <Form.Field error={!!!this.state.title}>
                    <label>{t("sidebar.layers.store.title")}</label>
                    <input
                      placeholder={t("sidebar.layers.store.title")}
                      name="title"
                      value={this.state.title}
                      onChange={(e) => this.handleChange(e)}
                    />
                    {!!!this.state.title && (
                      <InlineError text={t("sidebar.layers.store.empty")} />
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label>{t("sidebar.layers.store.description")}</label>
                    <Form.TextArea
                      placeholder={t("sidebar.layers.store.description")}
                      name="description"
                      value={this.state.description}
                      onChange={(e) => this.handleChange(e)}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Checkbox
                      label={t("sidebar.layers.store.private")}
                      style={{ paddingLeft: 0 }}
                      checked={!this.state.isPublic}
                      onChange={() =>
                        this.setState({ isPublic: !this.state.isPublic })
                      }
                    />
                  </Form.Field>
                </Form>
              </List.Item>
            </>
          )}
        </List>
        <Modal.Actions>
          {this.state.createNew ? (
            <Button
              positive
              floated="left"
              disabled={!!!this.state.title}
              onClick={async () => {
                const data = {
                  title: this.state.title,
                  description: this.state.description,
                  isPublic: this.state.isPublic,
                };
                const res = await this.props.onCreateStore(data);
                if (res && res.success) {
                  this.cancelCreateNew();
                }
              }}
            >
              <Icon name="add" />
              {t("sidebar.layers.store.create")}
            </Button>
          ) : (
            <Button
              positive
              floated="left"
              disabled={!!!this.state.checked}
              onClick={() => this.props.saveClicked(this.state.checked)}
            >
              <Icon name="check" />
              {t("sidebar.layers.store.save")}
            </Button>
          )}
          <Button negative onClick={() => this.hideModal()}>
            <Icon name="cancel" />
            {t("sidebar.layers.store.cancel")}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default StoreList;
