export default {
    en: {
        success: {
            title_VerifyEmailAddress: `Verify Email`,
            description_VerifyEmailAddress: `Verification message was sent to your email address, please check your email to complete the registration.`,
            title_EmailVerified: `Email Verified`,
            description_EmailVerified: `Congratulations! You have successfully verified your email. You can now sign in.`,
            title_UserLoggedIn: `Welcome`,
            description_UserLoggedIn: ``,
            title_VerifyEmail: `Email Verified`,
            description_VerifyEmail: `Congratulations! You have successfully verified, you can sign in now.`,
            title_EmailSent: `Message was successfully sent to your email address!`,
            description_EmailSent: `Please check your email for a link to reset your password. If it doesn’t appear within a few minutes, check your spam folder.`,
            title_PasswordSet: `New password set successfully!`,
            description_PasswordSet: `Your password has been successfully changed. Please, sign in and enjoy.`
        },
        error: {
            title_VerifyEmail: `Verify Email`,
            description_VerifyEmail: `This email has already registered but not verified yet, please check your email to complete the registration.`,
            title_UserNotVerified : `User Not Verified`,
            description_UserNotVerified : `This user has already registered but not verified yet, please check your email to complete the registration.`,
            title_UserNotFound : `User Not Found`,
            description_UserNotFound : `This email doesn't exist in our database, please sign up to be able to sign in.`,
            title_InvalidToken : `Invalid Token`,
            description_InvalidToken : `This token is invalid.`,
            title_UserAlreadyVerified : `User Already Verified`,
            description_UserAlreadyVerified : `You have already verified this email address. Please sign in.`,
            title_UserAlreadyExists : `User Already Exists`,
            description_UserAlreadyExists : `User with this email address already exists. Please sign in.`,
            title_InvalidEmail : `Invalid Email`,
            description_InvalidEmail : `The email you have entered is not valid. Please enter valid password.`,
            title_InvalidPassword : `Invalid Password`,
            description_InvalidPassword : `Password you have entered is not valid. Please enter valid password.`,
            title_InvalidEmailToken : `Invalid Verification Token`,
            description_InvalidEmailToken : `The verification token you are using in not valid.`,
            title_InternalServer : `Internal Server`,
            description_InternalServer : `Opps! It seems something went wrong.`,
            title_TooLargeArea: `Drawn area is too large!`,
            description_TooLargeArea: `You have exceeded the maximum allowed area size.`,
            title_NotFoundOrder: `Order was not found!`,
            description_NotFoundOrder: `It seems the order was not found or you don't have access to it.`,
            title_FormatNotAllowed: `Disallowed format!`,
            description_FormatNotAllowed: `This file format is not allowed.`,
        }
    },
    ru: {
        success: {
            title_VerifyEmailAddress: `Подтвердите Email`,
            description_VerifyEmailAddress: `Письмо с подтверждением было отправлено на ваш адрес электронной почты. Проверьте свою электронную почту, чтобы завершить регистрацию.`,
            title_EmailVerified: `Email подтвержден`,
            description_EmailVerified: `Поздравляем! Вы успешно подтвердили свою электронную почту. Теперь вы можете войти.`,
            title_UserLoggedIn: `Добро пожаловать`,
            description_UserLoggedIn: ``,
            title_VerifyEmail: `Email подтвержден`,
            description_VerifyEmail: `Поздравляем! Вы были успешно подтверждены, вы можете войти в систему сейчас.`,
            title_EmailSent: `Сообщение было успешно отправлено на ваш электронный адрес!`,
            description_EmailSent: `Пожалуйста, проверьте свою электронную почту на наличие ссылки для сброса пароля. Если он не появится в течение нескольких минут, проверьте папку со спамом.`,
            title_PasswordSet: `Новый пароль успешно установлен!`,
            description_PasswordSet: `Ваш пароль был успешно изменен. Пожалуйста, войдите и наслаждайтесь.`
        },
        error: {
            title_VerifyEmail: `Подтверждение Почты`,
            description_VerifyEmail: `Этот адрес электронной почты уже зарегистрирован, но еще не проверено, пожалуйста, проверьте свою электронную почту для завершения регистрации.`,
            title_UserNotVerified : `Пользователь Не Верифицирован`,
            description_UserNotVerified : `Этот пользователь уже зарегистрирован, но еще не подтвержден, пожалуйста, проверьте свою электронную почту, чтобы завершить регистрацию.`,
            title_UserNotFound : `Пользователь Не Найден`,
            description_UserNotFound : `Этот адрес электронной почты не существует в нашей базе данных, пожалуйста, зарегистрируйтесь, чтобы иметь возможность войти.`,
            title_InvalidToken : `Неверный токен`,
            description_InvalidToken : `Этот токен недействителен.`,
            title_UserAlreadyVerified : `Пользователь уже верифицирован`,
            description_UserAlreadyVerified : `Вы уже подтвердили этот адрес электронной почты. Пожалуйста войдите.`,
            title_UserAlreadyExists : `Пользователь уже существует`,
            description_UserAlreadyExists : `Пользователь с таким адресом электронной почты уже существует. Пожалуйста войдите.`,
            title_InvalidEmail : `Неверный адрес электронной почты`,
            description_InvalidEmail : `Введенный вами адрес электронной почты недействителен. Пожалуйста, введите действительную почту.`,
            title_InvalidPassword : `Неверный пароль`,
            description_InvalidPassword : `Введенный вами пароль недействителен. Пожалуйста, введите действительный пароль.`,
            title_InvalidEmailToken : `Неверный токен подтверждения`,
            description_InvalidEmailToken : `Токен, который вы используете, недействителен.`,
            title_InternalServer : `Внутренний сервер`,
            description_InternalServer : `Ой! Кажется, что-то пошло не так.`,
            title_TooLargeArea: `Нарисованная область слишком большая!`,
            description_TooLargeArea: `Вы превысили максимально допустимый размер области.`,
            title_NotFoundOrder: `Заказ не найден!`,
            description_NotFoundOrder: `Кажется, заказ не был найден или у вас нет к нему доступа.`,
            title_FormatNotAllowed: `Неразрешенный формат!`,
            description_FormatNotAllowed: `Файл с таким форматом не разрешен.`,
        }
    }
};