export default {
    en: {
        orders: `Orders`,
        coupons: `Coupons`,
        partners: `Partnership`,
        history: `Order history`,
        area: `Area`,
        price: `Price`,
        status: `Status`,
        date: `Date`,
        total: `Total`,
        done: `Done`,
        cancelled: `Cancelled`,
        processing: `Processing`,
        development: `Development`,
    },
    ru: {
        orders: `Заказы`,
        coupons: `Купоны`,
        partners: `Партнерство`,
        history: `История заказов`,
        area: `Площадь`,
        price: `Цена`,
        status: `Статус`,
        date: `Дата`,
        total: `Всего`,
        done: `Выполнено`,
        cancelled: `Отменено`,
        processing: `В обработке`,
        development: `Разработка`,
    }
};
