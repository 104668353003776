export default {
    en: {
        not_defined: `Not defined`,
        last_name: `Last name`,
        first_name: `First name`,
        email: `Email`,
        phone: `Phone`,
        role: `Role`,
        company: `Company`,
        date: `Date`,
        delete: {
            title: `Delete user?`,
            content: `Are you sure you want to delete`
        }
    },
    ru: {
        not_defined: `Не указано`,
        last_name: `Фамилия`,
        first_name: `Имя`,
        email: `Почта`,
        phone: `Телефон`,
        role: `Роль`,
        company: `Компания`,
        date: `Дата`,
        delete: {
            title: `Удалить пользователя?`,
            content: `Вы уверены, что хотите удалить`
        }
    }
};