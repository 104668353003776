import { Form, Button, Icon } from 'semantic-ui-react';
import React from 'react';
import './Points.css'
import {t} from '../../../../lang';

class points extends React.Component {
    state = { 
        counter: 3,
        points: [],
        coordinates: []
    };

    componentWillMount() {
        for (let i = 0; i < this.state.counter; i++) {
            this.state.coordinates.push({latitude: '', longitude: ''});
        }
    }

    removePoint = () => {
        this.setState({counter: this.state.counter-1});
        this.state.coordinates.pop();
        this.props.onCoordinateInserted(this.state.coordinates);
        setTimeout(this.setpoints(), 1000);
    };
    addPoint = () => {
        this.setState({counter: this.state.counter+1});
        this.state.coordinates.push({latitude: '', longitude: ''});
        this.props.onCoordinateInserted(this.state.coordinates);
        this.setpoints();
    };

    setCoordinates = (e, i, type) => {
        if(this.state.coordinates[i]) {
            if(type==='lat' && ((parseFloat(e.target.value) >= -90 && parseFloat(e.target.value) <= 90) || e.target.value==='')) {
                this.state.coordinates[i].latitude = e.target.value
            } else if(type==='lon' && ((parseFloat(e.target.value) >= -180 && parseFloat(e.target.value) <= 180) || e.target.value==='')){
                this.state.coordinates[i].longitude = e.target.value
            }
        }
        this.props.onCoordinateInserted(this.state.coordinates);
    };

    setpoints = () => {
        this.state.points = [];
        for (let i = 0; i < this.state.counter; i++) {
            this.state.points.push(
            <React.Fragment key={i}>
                <Form.Group className='min-width' widths='equal'style={{marginBottom: '10px'}}>
                <Form.Input
                    onInput={(e)=>this.setCoordinates(e, i, 'lat')}
                    className='coordinates'
                    type='number'
                    fluid
                    placeholder={t('new_form.latitude')}
                    value={(this.state.coordinates && this.state.coordinates[i]) ? this.state.coordinates[i].latitude:''}
                />
                <Form.Input
                    onInput={(e)=>this.setCoordinates(e, i, 'lon')}
                    className='coordinates'
                    type='number'
                    fluid
                    placeholder={t('new_form.longitude')}
                    value={(this.state.coordinates && this.state.coordinates[i]) ? this.state.coordinates[i].longitude:''}
                />
                <Button color='blue' disabled={i<3} style={{margin: '0 7px'}} icon='minus' onClick={this.removePoint}/>
                </Form.Group>
            </React.Fragment>
            )         
        }
    };
    render() { 
        this.setpoints();
        return ( 
                <Form>
                    {this.state.points}
                    <Button color='blue' icon fluid onClick={this.addPoint}>
                        <Icon name='plus'/>
                        &nbsp;
                        {t('new_form.add_point')}
                    </Button>
                </Form> 
        );
    }
}
 
export default points;