export default {
    en: {
        tenge: `Tenge`,
        dollar: `Dollar`,
        in_tenge: `in tenges`,
        in_dollar: `in dollars`,
        prices: `Prices`,
        for1: `for Earth remote sensing data from the KazEOSat-1 satellite (processing level L1, L2, L3, L4)`,
        for2: `for Earth remote sensing data from the KazEOSat-2 satellite (processing level L1, L2, L3, L4)`,
        acquisition: `Acquisition mode`,
        mono: `Mono`,
        stereo: `Stereo`,
        for_km: `form 1 km`,
        archive: `Archive`,
        standard: `Standard`,
        priority: `Priority shooting`,
        min: `Min order, in km`,
        pan: `Pan`,
        mc: `MS`,
        m: `m`,
        channels: `Spectral channels`,
        urgent: `for urgent shooting 24/7`,
        reminder: `Urgent prices are added to the cost of the image.`,
        title: `Price Change`,
        body: `Are you sure you want to change price?`
    },
    ru: {
        tenge: `Тенге`,
        dollar: `Доллар`,
        in_tenge: `в тенге`,
        in_dollar: `в долларах`,
        prices: `Цены`,
        for1: `на данные дистанционного зондирования Земли с космического аппарата KazEOSat-1 (уровень обработки L1, L2, L3, L4)`,
        for2: `на данные дистанционного зондирования Земли с космического аппарата KazEOSat-2 (уровень обработки L1, L2, L3, L4)`,
        acquisition: `Режим съемки`,
        mono: `Моно`,
        stereo: `Стерео`,
        for_km: `за 1 км`,
        archive: `Архивная`,
        standard: `Стандарт`,
        priority: `Приоритетная съемка`,
        min: `Мин заказ, в км`,
        pan: `Пан`,
        mc: `МС`,
        m: `м`,
        channels: `Спектральные каналы`,
        urgent: `за срочную съемку в режиме 24/7`,
        reminder: `Цены за срочную съемку, добавляются к стоимости снимка`,
        title: `Изменение цены`,
        body: `Вы уверены, что хотите изменить цену?`
    }
};
