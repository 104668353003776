import React, { Component } from 'react'
import { DateRange } from 'react-date-range'
import { Button, Modal, Icon } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as rdrLocales from 'react-date-range/dist/locale'
import moment from 'moment'
import './DatePicker.css'
import { t, getLanguage} from '../../../../../../lang';

class DatePicker extends Component {

    state = {
        open: false,
        dateRange: {
            selection: {
                startDate: this.props.isAuthenticated ? moment(new Date()).subtract(1, 'months') : moment(new Date()).subtract(3, 'days'),
                endDate: moment(new Date()),
                key: 'selection',
            },
          },
        text: t('datepicker.header'),
        error: false
    };

    componentDidMount() {
        if(this.props.dateFrom && this.props.dateTo) {
            this.state.dateRange.selection.startDate = new Date(this.props.dateFrom);
            this.state.dateRange.selection.endDate = new Date(this.props.dateTo);
        }
        this.setState({text: `${moment(this.state.dateRange.selection.startDate).format('DD.MM.YY')} - ${moment(this.state.dateRange.selection.endDate).format('DD.MM.YY')}`})
        this.props.onDateChosen(this.state.dateRange.selection)
    }

    handleRangeChange(which, payload) {
        this.setState({
          [which]: {
            ...this.state[which],
            ...payload,
          },
        });
    };

    showModal = () => {
        this.setState({open: true})
    };

    hideModal = () => {
        this.setState({open: false})
    };

    confirmDate = () => {
        if(this.state.dateRange.selection.startDate && this.state.dateRange.selection.endDate){
            const dates = this.getDates(new Date(this.state.dateRange.selection.startDate),new Date(this.state.dateRange.selection.endDate));
            if(this.props.isAuthenticated) {
                this.setDate();
            } else {
                if(dates.length <= 3) {
                    this.setDate();
                } else {
                    this.setState({
                        text: t('datepicker.max3'),
                        error: true
                    })
                }
                
            
            }
            this.hideModal()
        }
    }
    setDate = () => {
        this.props.onDateChosen(this.state.dateRange.selection)
        this.setState({
            text: `${moment(this.state.dateRange.selection.startDate).format('DD.MM.YY')} - ${moment(this.state.dateRange.selection.endDate).format('DD.MM.YY')}`,
            error: false
        })
    }
    // Returns an array of dates between the two dates
    getDates = (startDate, endDate) => {
        let dates = [],
            currentDate = startDate,
            addDays = function(days) {
            let date = new Date(this.valueOf());
            date.setDate(date.getDate() + days);
            return date;
            };
        while (currentDate <= endDate) {
            dates.push(currentDate);
            currentDate = addDays.call(currentDate, 1);
        }
        return dates;
    };
  
    render() {
        return (
            <div>
                <Modal
                    style={{width: 'auto'}}
                    open={this.state.open}
                    onClose={this.hideModal}
                    trigger={<Button
                            style={{color: this.state.error ? 'red': ''}}
                            icon labelPosition='left' 
                            className="date" 
                            fluid onClick={this.showModal}>
                            <Icon name="calendar alternate outline"/>
                            {this.state.text}
                        </Button>}>
                    <Modal.Header>{t('datepicker.header')}</Modal.Header>
                    <Modal.Content>
                        <DateRange
                            locale={getLanguage() === 'ru' ? rdrLocales.ru : rdrLocales.en}
                            onChange={this.handleRangeChange.bind(this, 'dateRange')}
                            moveRangeOnFirstSelection={false}
                            ranges={[this.state.dateRange.selection]}
                        />
                    </Modal.Content>
                    <Modal.Actions>
                    <Button color='red' onClick={this.hideModal}>
                        <Icon name='remove' />{t('datepicker.cancel')}
                    </Button>
                    <Button color='green' onClick={this.confirmDate}>
                        <Icon name='checkmark' />{t('datepicker.confirm')}
                    </Button>
                    </Modal.Actions>
                </Modal>
            </div>
        )
    }
}

DatePicker.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
    return {
        isAuthenticated: !!state.user.token,
    }
}

export default connect(mapStateToProps, null)(DatePicker);