import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button, Segment, Sidebar, Search } from "semantic-ui-react";
import axios from "axios";
import Tabs from "./Sidebar/Tabs";
import {
  getLayers,
  getShareLink,
  getDataFromSharedLink,
  getStoreById,
} from "../../../actions/layer";
import moment from "moment";
import L from "leaflet";
import "leaflet-imageoverlay-rotated";
import "leaflet-toolbar";
import "leaflet-distortableimage";
import "leaflet-mouse-position";
import tj from "@mapbox/togeojson";
import JSZip from "jszip";
import "./Map.css";
import { setQuicklooks } from "../../../actions/layer";
import Toast from "../../messages/Toast";
import Demo from "../../modals/Demo";
import Loader from "../Loader/Loader";
import { t, getLanguage } from "../../../lang";
import {
  leaflet_russian,
  leaflet_english,
} from "../../../assets/js/leaflet.locale";
const turf = require('turf');

class Map extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      map: null,
      latlng: [47.2286086, 65.2093197],
      zoom: 5,
      drawer: "",
      drawnItems: "",
      bounds: {
        west: "",
        south: "",
        east: "",
        north: "",
      },
      visible: true,
      toggle: "chevron left",
      searchText: "",
      results: [],
      source: "",
      vector: "",
      filter: {
        satellite: "KazEOSat-1",
        date_from: this.props.isAuthenticated
          ? moment(new Date()).subtract(1, "months")
          : moment(new Date()).subtract(3, "days"),
        date_to: moment(new Date()),
        cloud_coverage: 0,
        pitch: 0,
        yaw: 0,
        roll: 0,
        sun_azimuth_angle: 0,
      },
      drawn: false,
      isDrawing: false,
      quicklooks: [],
      isButtonDisabled: false,
      showDemo: false,
      loading: false,
      clickedLayer: null,
      currentPolygon: null,
      share_link: null,
      storeMode: false,
      shareMode: false,
    };
  }

  toaster = React.createRef();
  upload = React.createRef();

  selectedQuicklooks = () => {};

  componentDidMount() {
    this.initMap();
    this.isSharedCheck();
  }

  componentDidUpdate(prevState, prevProps) {
    if (getLanguage() === "ru") {
      leaflet_russian(L);
    } else {
      leaflet_english(L);
    }
  }

  initMap = () => {
    this.state.map = L.map("map", {
      minZoom: 2,
    }).setView(this.state.latlng, this.state.zoom);
    this.state.map.attributionControl.setPrefix(
      '<a href="https://gharysh.kz/">АО НК "Қазақстан Ғарыш Сапары"</a>'
    );
    L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>',
    }).addTo(this.state.map);
    this.state.map.attributionControl.addAttribution(
      "powered by KazEOSat Team"
    );
    this.state.drawnItems = new L.FeatureGroup();
    this.state.map.addLayer(this.state.drawnItems);
    L.control.mousePosition().addTo(this.state.map);

    this.state.map.on("draw:created", (e) => {
      let layer = e.layer;
      let type = e.layerType;

      if (
        !(layer instanceof L.CircleMarker) 
        // && L.GeometryUtil.geodesicArea(layer.getLatLngs()[0]) / 1000000 <= 1000000
      ) {
        layer.addTo(this.state.drawnItems);
        this.state.drawer = "";
        let bounds = "";
        if (type === "circlemarker") {
          bounds = layer.getLatLng().toBounds(2000);
        } else {
          bounds = layer.getBounds();
        }
        if (type === "polygon") {
          let filter = this.state.filter;
          filter.geometry = layer.toGeoJSON().geometry;
          this.setState({
            filter: filter,
          });
        }
        if (type === "rectangle") {
          let filter = this.state.filter;
          layer.toGeoJSON().geometry.type = "Polygon";
          filter.geometry = layer.toGeoJSON().geometry;
          this.setState({
            filter: filter,
          });
        }
        this.setState({ isDrawing: false });
        this.state.bounds.west = bounds.getWest();
        this.state.bounds.south = bounds.getSouth();
        this.state.bounds.east = bounds.getEast();
        this.state.bounds.north = bounds.getNorth();
        this.getLayers();
      } else if (layer instanceof L.CircleMarker) {
        let bounds = layer.getLatLng().toBounds(2000);
        this.setState({ isDrawing: false });
        let filter = this.state.filter;
        filter.geometry = {
          type: "Polygon",
          coordinates: [
            [
              [bounds.getWest(), bounds.getSouth()],
              [bounds.getWest(), bounds.getNorth()],
              [bounds.getEast(), bounds.getNorth()],
              [bounds.getEast(), bounds.getSouth()],
              [bounds.getWest(), bounds.getSouth()],
            ],
          ],
        };
        this.setState({
          filter: filter,
        });
        this.state.bounds.west = bounds.getWest();
        this.state.bounds.south = bounds.getSouth();
        this.state.bounds.east = bounds.getEast();
        this.state.bounds.north = bounds.getNorth();
        this.getLayers();
      } else {
        this.toaster.current.configErrorResponse({ message: "TooLargeArea" });
      }
    });

    this.state.map.on("click", (e) => {
      if (this.state.visible) this.toggleButtonClick();
    });

    this.state.map.on("zoom", (e) => {
      if (this.state.visible) this.toggleButtonClick();
    });

    this.state.drawnItems.on("click", (e) => {
      this.setState({ clickedLayer: e.layer.code });
    });
  };

  isSharedCheck = () => {
    const { pathname } = this.props.location;

    if (pathname.includes("/search-history/share/")) {
      if (!this.props.isAuthenticated) sessionStorage.setItem("demo", true);

      this.props.getDataFromSharedLink(pathname).then((res) => {
        if (res && res.success && res.search_history) {
          this.setState({ filter: res.search_history, shareMode: true });
          const layer = L.geoJSON(this.state.filter.geometry);
          let options = {
            stroke: true,
            color: "black",
            weight: 4,
            opacity: 0.2,
            fill: true,
            fillColor: "black",
            fillOpacity: 0.1,
            clickable: true,
          };
          layer.setStyle(options);
          layer.addTo(this.state.drawnItems);
          let type = "",
            bounds = "";
          if (this.state.filter && this.state.filter.geometry)
            type = this.state.filter.geometry.type.toLowerCase();
          if ((type && type === "circlemarker") || type === "marker") {
            bounds = layer.getLatLng().toBounds(2000);
          } else {
            if (layer && layer.getBounds()) bounds = layer.getBounds();
          }

          if (bounds && Object.keys(bounds).length !== 0) {
            this.state.bounds.west = bounds.getWest();
            this.state.bounds.south = bounds.getSouth();
            this.state.bounds.east = bounds.getEast();
            this.state.bounds.north = bounds.getNorth();
            this.getLayers();
          }
        }
      });
    }
    if (pathname.includes("/stores/share/")) {
      this.props.getStoreById(pathname).then((res) => {
        if (res && res.store) {
          this.loadStore(res.store.satellite_images);
          this.setState({ storeMode: true });
        }
      });
    }
  };

  loadStore = (layers) => {
    this.setState({ quicklooks: [] });
    layers.forEach((layer) => {
      let coordinates = [
        L.latLng(parseFloat(layer.geometry.coordinates[0][0][1]), parseFloat(layer.geometry.coordinates[0][0][0])),
        L.latLng(parseFloat(layer.geometry.coordinates[0][1][1]), parseFloat(layer.geometry.coordinates[0][1][0])),
        L.latLng(parseFloat(layer.geometry.coordinates[0][2][1]), parseFloat(layer.geometry.coordinates[0][2][0])),
        L.latLng(parseFloat(layer.geometry.coordinates[0][3][1]), parseFloat(layer.geometry.coordinates[0][3][0])),
      ];
      let polygon = L.polygon(coordinates);
      // .addTo(this.state.drawnItems);
      let options = {
        stroke: true,
        color: "#4185f4",
        weight: 3,
        opacity: 0.6,
        fill: true,
        fillColor: null,
        fillOpacity: 0,
        clickable: true,
      };
      polygon.setStyle(options);

      let image = L.distortableImageOverlay(
        window.location.origin + layer.quicklook_url,
        {
          corners: [
            L.latLng(parseFloat(layer.geometry.coordinates[0][1][1]), parseFloat(layer.geometry.coordinates[0][1][0])),
            L.latLng(parseFloat(layer.geometry.coordinates[0][2][1]), parseFloat(layer.geometry.coordinates[0][2][0])),
            L.latLng(parseFloat(layer.geometry.coordinates[0][0][1]), parseFloat(layer.geometry.coordinates[0][0][0])),
            L.latLng(parseFloat(layer.geometry.coordinates[0][3][1]), parseFloat(layer.geometry.coordinates[0][3][0]))
          ]
        }
      );
      // .addTo(this.state.drawnItems);
      
      polygon.code = layer.code;
      this.state.quicklooks.push({
        code: layer.code,
        polygon: polygon,
        image: image,
        clicked: false,
        checked: false,
        maxTileZoom: layer.max_zoom,
        minTileZoom: layer.min_zoom,
        tileServiceType: layer.tile_service_type,
        tileServiceURL: layer.tile_service_url,
        quicklookURL: layer.quicklook_url,
        source: layer.source

      });
    });
  };

  toggleButtonClick = () => {
    let toggleButton = document.getElementById("toggleButton");
    let container = document.getElementById("pusher");
    if (!this.state.isDrawing) {
      // if (this.state.visible) {
      //   this.setState({ visible: false });
      //   toggleButton.style.left =
      //     parseFloat(getComputedStyle(toggleButton, null).left) + -380 + "px";
      //   container.style.marginLeft = "0";
      //   this.setState({ toggle: "chevron right" });
      // } else {
      //   this.setState({ visible: true });
      //   toggleButton.style.left =
      //     parseFloat(getComputedStyle(toggleButton, null).left) + 380 + "px";
      //   container.style.marginLeft = "120px";
      //   this.setState({ toggle: "chevron left" });
      // }
    } else {
      this.state.drawer.disable();
      this.setState({ isDrawing: false });
      // if (this.state.visible) {
      //   this.setState({ visible: false });
      //   toggleButton.style.left =
      //     parseFloat(getComputedStyle(toggleButton, null).left) + -380 + "px";
      //   container.style.marginLeft = "0";
      //   this.setState({ toggle: "chevron right" });
      // } else {
      //   this.setState({ visible: true });
      //   toggleButton.style.left =
      //     parseFloat(getComputedStyle(toggleButton, null).left) + 380 + "px";
      //   container.style.marginLeft = "120px";
      //   this.setState({ toggle: "chevron left" });
      // }
    }
  };

  searchChange = (event) => {
    
    this.setState({ searchText: event.target.value });
    this.setState({ results: [] });
    axios
      .get(`https://nominatim.openstreetmap.org/search`, {
        params: { q: event.target.value, format: `geojson` },
      })
      .then((res) => {
        let resultsArray = [];
        res.data.features.forEach((e) => {
          resultsArray.push({
            id: e.properties.osm_id,
            title: e.properties.display_name,
            bbox: e.bbox,
            geometry: e.geometry,
            properties: e.properties,
          });
        });
        this.setState({ results: resultsArray });
      });
  };

  goToResult = (e, { result }) => {
    this.state.map.fitBounds([
      [result.bbox[1], result.bbox[0]],
      [result.bbox[3], result.bbox[2]],
    ]);
  };

  drawShape = (type) => {
    if (!this.state.isDrawing) {
      this.toggleButtonClick();
    }
    let options = {
      showArea: false,
      shapeOptions: {
        stroke: true,
        color: "black",
        weight: 4,
        opacity: 0.2,
        fill: true,
        fillColor: "black",
        fillOpacity: 0.1,
        clickable: true,
      },
    };
    this.setState({ quicklooks: [] });
    this.state.drawnItems.clearLayers();
    if (type !== "Upload") {
      switch (type) {
        case "Point":
          options = {
            color: "black",
            weight: 4,
            opacity: 0.2,
            fill: true,
            fillColor: "black",
            fillOpacity: 0.1,
          };
          this.state.drawer = new L.Draw.CircleMarker(this.state.map, options);
          break;
        case "Rectangle":
          this.state.drawer = new L.Draw.Rectangle(this.state.map, options);
          break;
        case "Polygon":
          this.state.drawer = new L.Draw.Polygon(this.state.map, options);
          break;
        default:
          break;
      }
      this.state.drawer.enable();
      this.setState({ isDrawing: true });
    } else {
      this.upload.current.click();
    }
  };

  setDateRange = (date) => {
    let filter = this.state.filter;
    filter.date_from = moment(date.startDate).format("YYYY-MM-DD");
    filter.date_to = moment(date.endDate).format("YYYY-MM-DD");
    this.setState({ filter: filter });
  };

  setQualityType = (value) => {
    let filter = this.state.filter;
    filter.satellite = value;
    this.setState({ filter });
  };

  setPitch = (value) => {
    let filter = this.state.filter;
    filter.pitch = value;
    this.setState({ filter });
  };

  setRoll = (value) => {
    let filter = this.state.filter;
    filter.roll = value;
    this.setState({ filter });
  };

  setYaw = (value) => {
    let filter = this.state.filter;
    filter.yaw = value;
    this.setState({ filter });
  };

  setCloud = (value) => {
    let filter = this.state.filter;
    filter.cloud_coverage = value;
    this.setState({ filter });
  };

  setSun = (value) => {
    let filter = this.state.filter;
    filter.sun_azimuth_angle = value;
    this.setState({ filter });
  };

  getLayers = () => {
    this.props.setQuicklooks([]);
    this.state.quicklooks.forEach((q) => {
      if (this.state.drawnItems.hasLayer(q.image)) {
        this.state.drawnItems.removeLayer(q.image);
        this.state.drawnItems.removeLayer(q.polygon);
      }
      if (this.state.map.hasLayer(q.tiles)) {
        this.state.map.removeLayer(q.tiles);
      }
    });

    this.setState({ quicklooks: [] });
    // this.state.drawnItems.clearLayers()
    let bounds = this.state.bounds;
    // let filter = this.checkFilter();
    // filter.West = bounds.east;
    // filter.South = bounds.south;
    // filter.East = bounds.north;
    // filter.North = bounds.west;

    this.setState({ isButtonDisabled: true });

    let data,
      type = "user";
    if (!this.props.isAuthenticated) {
      data = {
        date_from: this.state.filter.date_from,
        date_to: this.state.filter.date_to,
        geometry: this.state.filter.geometry,
        satellite: this.state.filter.satellite,
      };
      type = "demo";
    } else {
      data = this.checkFilter();
    }

    this.setState({ loading: true });
    this.props
      .getLayers(data, type)
      .then((res) => {
        if (res.success && this.props.layers) {
          this.state.map.fitBounds([
            [bounds.north, bounds.east],
            [bounds.south, bounds.west],
          ]);
          setTimeout(() => {
            this.toggleButtonClick();
          }, 1500);
          this.setState({ drawn: true });
          this.refs.tabs.setState({ active: -1 });
          if (this.props.layers.length) this.loadLayers();
        } else {
          // this.toaster.current.configErrorResponse(res.error)
        }
        this.setState({ isButtonDisabled: false, loading: false });
      })
      .catch((err) => this.setState({ loading: false }));
  };

  loadLayers = () => {
    try {
      this.state.drawnItems.clearLayers();
      
      this.props.layers.forEach((layer) => {
        let coordinates = [
          L.latLng(parseFloat(layer.geometry.coordinates[0][0][1]), parseFloat(layer.geometry.coordinates[0][0][0])),
          L.latLng(parseFloat(layer.geometry.coordinates[0][1][1]), parseFloat(layer.geometry.coordinates[0][1][0])),
          L.latLng(parseFloat(layer.geometry.coordinates[0][2][1]), parseFloat(layer.geometry.coordinates[0][2][0])),
          L.latLng(parseFloat(layer.geometry.coordinates[0][3][1]), parseFloat(layer.geometry.coordinates[0][3][0])),
        ];
        let polygon = L.polygon(coordinates);
        let options = {
          stroke: true,
          color: "#4185f4",
          weight: 3,
          opacity: 0.6,
          fill: true,
          fillColor: null,
          fillOpacity: 0,
          clickable: true,
        };
        polygon.setStyle(options);
        // polygon.addTo(this.state.map);
        polygon.addTo(this.state.drawnItems);
        let image = L.distortableImageOverlay(
          window.location.origin + layer.quicklook_url,
          {
            corners: [
              L.latLng(parseFloat(layer.geometry.coordinates[0][1][1]), parseFloat(layer.geometry.coordinates[0][1][0])),
              L.latLng(parseFloat(layer.geometry.coordinates[0][2][1]), parseFloat(layer.geometry.coordinates[0][2][0])),
              L.latLng(parseFloat(layer.geometry.coordinates[0][0][1]), parseFloat(layer.geometry.coordinates[0][0][0])),
              L.latLng(parseFloat(layer.geometry.coordinates[0][3][1]), parseFloat(layer.geometry.coordinates[0][3][0]))
            ]    
          }
        );
        // .addTo(this.state.drawnItems);
        polygon.code = layer.code;
        this.state.quicklooks.push({
          code: layer.code,
          polygon: polygon,
          image: image,
          clicked: false,
          checked: false,
          maxTileZoom: layer.max_zoom,
          minTileZoom: layer.min_zoom,
          tileServiceType: layer.tile_service_type,
          tileServiceURL: layer.tile_service_url,
          quicklookURL: layer.quicklook_url,
          source: layer.source,
          corner: [layer.geometry.coordinates]
        });
      });
    } catch (err) {
      console.log("Sunkarsat error of loading Layers: " + err)
    }
  };

  showQuicklookOutline = (code) => {
    try {
      this.state.quicklooks.forEach((q) => {
        if (q.code === code) {
          const options = {
            stroke: true,
            // color: "#4185f4",
            color:"red",
            weight: 3,
            opacity: 0.6,
            fill: true,
            fillColor: "green",
            fillOpacity: 0,
            clickable: true,
          };
          const currentPolygon = L.geoJSON(q.polygon.toGeoJSON());
          currentPolygon.setStyle(options);
          currentPolygon.addTo(this.state.drawnItems);
          this.setState({ currentPolygon });
          this.state.map.fitBounds(q.polygon.getBounds());            
        }
      });
    } catch (err) {
      console.log(err)
    }
  };

  hideQuicklookOutline = (code) => {
    this.state.currentPolygon.setStyle({ color: '#4185f4' });
    // if (this.state.drawnItems.hasLayer(this.state.currentPolygon)) {
    //   this.state.drawnItems.removeLayer(this.state.currentPolygon);
    //   this.setState({ currentPolygon: null });
    // }
  };

  quicklookChosen = (code) => {
    try {
      var topPane = this.state.map.createPane('leaflet-top-pane', this.state.map.getPanes().mapPane);
      
      this.state.quicklooks.forEach((q) => {
        if (q.clicked) {
          if (this.state.map.hasLayer(q.tiles)) {
            this.state.map.removeLayer(q.tiles);
          }
        }
        if (q.code === code) {
          if (!q.clicked) {
            let options = {
              stroke: true,
              color: "green",
              weight: 3,
              opacity: 0.6,
              fill: true,
              fillColor: null,
              fillOpacity: 0,
              clickable: true,
            };
            q.polygon.setStyle(options);
            q.polygon.addTo(this.state.drawnItems);
            this.state.map.fitBounds(q.polygon.getBounds());            
  
            if ( (q.tileServiceURL != null) && (q.source === "metadataservice")  ) {
              q.tiles = L.tileLayer(
                window.location.origin + q.tileServiceURL + "/{z}/{x}/{y}.png", {
                // tms: (q.tileServiceType === "tms")? true : false, 
                tms: true,
                opacity: 1, 
                // updateWhenIdle: true, - do not increase performance much
                minZoom: 4, 
                maxZoom: 18,
                minNativeZoom: q.minTileZoom, 
                maxNativeZoom: q.maxTileZoom
              });
              
              q.tiles.addTo(this.state.map);
              topPane.appendChild(q.tiles.getContainer());
              q.tiles.setZIndex(5);
            } else {
              // q.image = L.distortableImageOverlay(q.image._url, q.image.options);
        
              q.image = L.distortableImageOverlay(q.quicklookURL, q.image.options);
              q.image.addTo(this.state.drawnItems);
  
            }          
            q.clicked = true;
          } else {

            if ( (this.state.drawnItems.hasLayer(q.polygon)) && (q.polygon.options.color === "green")) {
              this.state.drawnItems.removeLayer(q.polygon);
            }
            if (this.state.drawnItems.hasLayer(q.image)) {
              this.state.drawnItems.removeLayer(q.image);
            }
            if (this.state.map.hasLayer(q.tiles)) {
              this.state.map.removeLayer(q.tiles);
            }
            q.clicked = false;
          }
        }
      });
    } catch (err) {
      console.log("Sunkarsat error of loading scenes & ql: " + err)
    }
  };

  allQuicklooksChosen = (b) => {
    // this.state.quicklooks.forEach((q) => {
    //   this.quicklookChosen(q.code);
    //   if(!b) {
    //     let options = {
    //       stroke: true,
    //       color: 'green',
    //       weight: 3,
    //       opacity: 0.6,
    //       fill: true,
    //       fillColor: null,
    //       fillOpacity: 0,
    //       clickable: true
    //     };
    //     q.polygon.setStyle(options);
    //     q.polygon.addTo(this.state.drawnItems);
    //     // q.image = L.distortableImageOverlay(q.image._url, q.image.options);
    //     q.image.addTo(this.state.drawnItems);
    //     q.clicked = true;
    //   } else {
    //     if(this.state.drawnItems.hasLayer(q.polygon)) {
    //       this.state.drawnItems.removeLayer(q.polygon);
    //     }
    //     if(this.state.drawnItems.hasLayer(q.image)) {
    //       this.state.drawnItems.removeLayer(q.image);
    //     }
    //     q.clicked = false;
    //   }
    //   this.state.map.setZoom(this.state.zoom)
    // });
  };

  resetSearch = () => {
    // this.state.map.eachLayer(function (layer) {
    //   this.state.map.removeLayer(layer);
    // });
    if (this.state.drawnItems.getLayers().length > 0) {
      this.toggleButtonClick();
      this.getLayers();
    } else {
      let toolsTab = document.getElementById("tabs").children[0].children[1];
      toolsTab.click();
    }
  };

  checkFilter() {
    let _this = this;
    let filter = _this.state.filter;
    if (!filter.cloud_coverage) delete filter.cloud_coverage;
    if (!filter.pitch) delete filter.pitch;
    if (!filter.roll) delete filter.roll;
    if (!filter.yaw) delete filter.yaw;
    if (!filter.sun_azimuth_angle) delete filter.sun_azimuth_angle;
    if (!filter.across_track_incidence_angle)
      delete filter.across_track_incidence_angle;
    if (!filter.along_track_incidence) delete filter.along_track_incidence;
    if (!filter.incidence_angle) delete filter.incidence_angle;
    if (!filter.sun_elevation_angle) delete filter.sun_elevation_angle;
    return filter;
  }

  shapeUploaded = (e) => {
    let filename = e.target.files[0].name;
    let last_dot = filename.lastIndexOf(".");
    let ext = filename.slice(last_dot + 1);
    let f = this.upload.current.files[0];
    let r = new FileReader();
    const options = {
      stroke: true,
      color: "black",
      weight: 4,
      opacity: 0.2,
      fill: true,
      fillColor: "black",
      fillOpacity: 0.1,
      clickable: true,
    };
    r.addEventListener("load", async (e) => {
      let convertedKml = "";

      if (ext === "kml") {
        let kml = new DOMParser().parseFromString(e.target.result, "text/xml");
        convertedKml = tj.kml(kml);
      } else if (ext === "kmz") {
        var new_zip = new JSZip();
        const contents = await new_zip.loadAsync(e.target.result);
        const content = await contents.file("doc.kml").async("string");
        convertedKml = tj.kml(
          new DOMParser().parseFromString(content, "text/xml")
        );
      } else if (ext === "geojson") {
        convertedKml = JSON.parse(e.target.result);
      } else {
        this.toaster.current.configErrorResponse({
          message: "FormatNotAllowed",
        });
        return;
      }

      if (convertedKml.type === "Feature") {
        convertedKml = {
          type: "FeatureCollection",
          features: [convertedKml],
        };
      }

      let layer = "";
      let type = convertedKml
        ? convertedKml.features[0].geometry.type.toLowerCase()
        : "";

      if (convertedKml && convertedKml.features[0].geometry.type === "Point") {
        layer = L.geoJSON(convertedKml, {
          pointToLayer: function(feature, latlng) {
            return L.circleMarker(latlng, options);
          },
        }).getLayers()[0];
      } else {
        layer = L.geoJSON(convertedKml, options).getLayers()[0];
      }

      if (!layer) return;

      if (
        !(layer instanceof L.CircleMarker) 
        // && L.GeometryUtil.geodesicArea(layer.getLatLngs()[0]) / 1000000 <= 1000000
      ) {
        if (layer) layer.addTo(this.state.drawnItems);
        this.state.map.fitBounds(layer.getBounds());
        this.state.drawer = "";
        let bounds = "";
        if (type === "circlemarker") {
          bounds = layer.getLatLng().toBounds(2000);
        } else {
          bounds = layer.getBounds();
        }
        if (type === "polygon") {
          let filter = this.state.filter;
          filter.geometry = layer.toGeoJSON().geometry;
          this.setState({
            filter: filter,
          });
        }
        if (type === "rectangle") {
          let filter = this.state.filter;
          layer.toGeoJSON().geometry.type = "Polygon";
          filter.geometry = layer.toGeoJSON().geometry;
          this.setState({
            filter: filter,
          });
        }
        if (type === "geometrycollection") {
          let filter = this.state.filter;
          let multiPolygon = {
            type: "MultiPolygon",
            coordinates:[]
          };
          
          layer.toGeoJSON().geometry.geometries.forEach(geometry => {
            multiPolygon.coordinates.push(geometry.coordinates)
          })
          let bbox = turf.bbox(multiPolygon);
          let bboxPolygon = turf.bboxPolygon(bbox);
          filter.geometry = bboxPolygon.geometry;
          this.setState({
            filter: filter,
          });
        }
        this.setState({ isDrawing: false });
        this.state.bounds.west = bounds.getWest();
        this.state.bounds.south = bounds.getSouth();
        this.state.bounds.east = bounds.getEast();
        this.state.bounds.north = bounds.getNorth();
        this.getLayers();
      } else if (layer instanceof L.CircleMarker) {
        let bounds = layer.getLatLng().toBounds(2000);
        this.setState({ isDrawing: false });
        let filter = this.state.filter;
        filter.geometry = {
          type: "Polygon",
          coordinates: [
            [
              [bounds.getWest(), bounds.getSouth()],
              [bounds.getWest(), bounds.getNorth()],
              [bounds.getEast(), bounds.getNorth()],
              [bounds.getEast(), bounds.getSouth()],
              [bounds.getWest(), bounds.getSouth()],
            ],
          ],
        };
        this.setState({
          filter: filter,
          // visible: true
        });
        this.state.bounds.west = bounds.getWest();
        this.state.bounds.south = bounds.getSouth();
        this.state.bounds.east = bounds.getEast();
        this.state.bounds.north = bounds.getNorth();
        this.getLayers();
        this.setState({
          visible: true
        });
      } else {
        this.toaster.current.configErrorResponse({ message: "TooLargeArea" });
      }
    });
    r.readAsBinaryString(f);
    e.target.value = "";
  };

  shareAOI = () => {
    const filter = this.state.filter;
    filter.result_format = "json";
    this.props.getShareLink(filter).then((res) => {
      if (res && res.url) {
        this.setState({ share_link: window.location.origin + res.url });
      }
    });
  };

  render() {
    return (
      <div>
        <div>
          <div id="container">
            <Sidebar.Pushable className="pusher" as={Segment}>
              <Sidebar
                id="sidebar"
                animation="push"
                vertical="true"
                visible={this.state.visible}
              >
                <Search
                  icon={false}
                  placeholder={`${t("sidebar.search")}...`}
                  id="search"
                  onResultSelect={this.goToResult}
                  onSearchChange={this.searchChange}
                  input={{ fluid: true }}
                  value={this.state.searchText}
                  results={this.state.results}
                  showNoResults={false}
                />
                <Tabs
                  satellite={this.state.filter.satellite}
                  dateFrom={this.state.filter.date_from}
                  dateTo={this.state.filter.date_to}
                  cloud={this.state.filter.cloud_coverage}
                  pitch={this.state.filter.pitch}
                  roll={this.state.filter.roll}
                  yaw={this.state.filter.yaw}
                  sun={this.state.filter.sun_azimuth_angle}
                  onQuicklookLockOnAll={this.allQuicklooksChosen}
                  onQuicklookLockOn={this.quicklookChosen}
                  onItemLeave={this.hideQuicklookOutline}
                  onItemHover={this.showQuicklookOutline}
                  quicklooks={this.state.quicklooks}
                  drawn={this.state.drawn}
                  type={this.state.filter.Type}
                  ref="tabs"
                  onDrawShape={this.drawShape}
                  onDateChosen={this.setDateRange}
                  onTypeChosen={this.setQualityType}
                  onPitchChosen={this.setPitch}
                  onRollChosen={this.setRoll}
                  onYawChosen={this.setYaw}
                  onCloudChosen={this.setCloud}
                  onSunChosen={this.setSun}
                  onResetClicked={this.resetSearch}
                  clickedLayer={this.state.clickedLayer}
                  onClickedLayerClose={() =>
                    this.setState({ clickedLayer: null })
                  }
                  onShareClicked={this.shareAOI}
                  shareLink={this.state.share_link}
                  onShareModalClose={() => this.setState({ share_link: null })}
                  storeMode={this.state.storeMode}
                  shareMode={this.state.shareMode}
                />
              </Sidebar>
              {/* <Button
                id="toggleButton"
                onClick={this.toggleButtonClick}
                icon={this.state.toggle}
                disabled={this.state.isButtonDisabled}
              /> */}
              <Sidebar.Pusher id="pusher">
                <div id="map" />
              </Sidebar.Pusher>
            </Sidebar.Pushable>
          </div>
        </div>
        {!this.props.isAuthenticated && !sessionStorage.getItem("demo") && (
          <Demo />
        )}
        {this.state.loading && <Loader />}
        <Toast ref={this.toaster} />
        <input
          ref={this.upload}
          type="file"
          onChange={this.shapeUploaded}
          accept=".kml, .kmz, .geojson, .shp"
        />
      </div>
    );
  }
}

Map.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  getLayers: PropTypes.func.isRequired,
  setQuicklooks: PropTypes.func.isRequired,
  getShareLink: PropTypes.func.isRequired,
  getDataFromSharedLink: PropTypes.func.isRequired,
  getStoreById: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    isAuthenticated: !!state.user.token,
    layers: state.layer,
  };
}
export default connect(
  mapStateToProps,
  {
    getLayers,
    setQuicklooks,
    getShareLink,
    getDataFromSharedLink,
    getStoreById,
  }
)(Map);
