import React from "react";
import { connect } from "react-redux";
import { verify, resend } from "../../../actions/auth";
import PropTypes from "prop-types";
import Toast from "../../messages/Toast";
import { Button, Message, Input } from "semantic-ui-react";
import InlineError from '../../messages/InlineError';
import {t} from '../../../lang';

class Verification extends React.Component {
  state = {
    email: "",
    loading: false,
    errors: []
  };

  onChange = e => {
      this.setState({errors: []});
    this.setState({ email: e.target.value });
  };

  onClick = () => {
    const errors = this.validate(this.state.email);
    this.setState({ errors: errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });
      this.props.resend(this.state.email).then(res => {
        this.setState({ loading: false });
        if (!res.success) {
          // this.toaster.current.configErrorResponse(res.error);
        }
      });
    }
  };

  validate = (email) => {
    const errors = {};
    if (!email) {
      errors.email = t('signin.errors.empty.email');
    }
    let emailPattern = new RegExp(
      "^[a-z0-9]+(.[_a-z0-9]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,15})$",
      "i"
    );
    if (email && !emailPattern.test(email.toLowerCase())) {
      errors.email = t('signin.errors.email');
    }
    return errors;
  };

  toaster = React.createRef();

  componentDidMount() {
    if (this.props.match.params.token) {
      this.props.verify(this.props.match.params.token).then(res => {
        if (!res.success) {
          // this.toaster.current.configErrorResponse(res.error);
        } else {
          this.toaster.current.configSuccessResponse(res);
        }
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <div>
          <div>
            <Message
              style={{
                width: "70vw",
                minWidth: "320px",
                margin: "5vh auto 0 auto"
              }}
              floating
              warning
            >
              <Message.Header>{t('signup.verification.complete')}</Message.Header>
              <p>
                {t('signup.verification.body')}
              </p>
              <p>
                {t('signup.verification.not_received')}
              </p>
              <label>{t('signin.email')}</label> &nbsp;
              <Input
                value={this.state.email}
                onChange={this.onChange}
                style={{ width: "30vw", minWidth: "260px" }}
                type="email"
                placeholder="example@example.com"
                action={
                  <Button loading={this.state.loading} disabled={this.state.loading} color="blue" onClick={()=>this.onClick()}>
                    {t('signup.verification.send')}
                  </Button>
                }
              />
              {this.state.errors.email && <p><InlineError text={this.state.errors.email}/></p>}
            </Message>
          </div>
        </div>
        <Toast ref={this.toaster} />
      </React.Fragment>
    );
  }
}

Verification.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  verify: PropTypes.func.isRequired
};

export default
  connect(
    null,
    { verify, resend }
  )(Verification);
