import React from 'react';
import { Button, Modal } from 'semantic-ui-react';
import {withRouter} from 'react-router-dom';
import { t } from '../../../../lang';

class Choose extends React.Component {

    render() { 
        return ( 
        <React.Fragment>
            <Modal closeIcon onClose={()=>this.props.history.push('/')} style={{width: 'auto'}} centered open={this.props.isModalActive} size='small'>
                <Modal.Header icon='image' content={t('new_form.choose.header')} />
                <Modal.Content style={{display: 'grid', gridRowGap: '10px'}}>
                <Button 
                    color='green'
                    onClick={()=>this.props.onTypeChosen('map')}
                >
                    {t('new_form.choose.map')}
                </Button>
                <Button 
                    color='teal'
                    onClick={()=>this.props.onTypeChosen('kml')}
                >
                    {t('new_form.choose.kml')}
                </Button>
                <Button 
                    color='blue' 
                    onClick={()=>this.props.onTypeChosen('normal')}
                >
                    {t('new_form.choose.coords')}
                </Button>
                </Modal.Content>
            </Modal>
        </React.Fragment> 
        );
    }
}
 
export default withRouter(Choose);