import React, {Component} from 'react';
import { Segment, Dimmer, Loader } from 'semantic-ui-react';
import './Loader.css';
import {t} from '../../../lang';

class LoaderPage extends Component {
    render() {
        return (
            <Segment className="loader-container">
                <Dimmer active>
                    <Loader content={`${t('messages.loading')}...`} />
                </Dimmer>
            </Segment>
        );
    }
}

export default LoaderPage;
