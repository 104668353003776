import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Button, Card, Checkbox, Form, Icon, Radio, Select, TextArea} from "semantic-ui-react";
import {withRouter} from "react-router-dom";
import "./NewForm.css";
import Points from "./components/Points";
import Period from "./components/Period";
import GJV from "geojson-validation";
import Choose from "./components/Choose";
import Draw from "./components/Draw";
import {addToCartNew, calculateNewPrice} from '../../../actions/cart';
import {t} from '../../../lang';
import L from "leaflet";
import Toast from '../../messages/Toast';

class NewForm extends React.Component {
    state = {
        themeOptions: [
            {key: "1", value: "agriculture", text: t('new_form.themes.agriculture')},
            {key: "2", value: "mapping", text: t('new_form.themes.mapping')},
            {key: "3", value: "forestry", text: t('new_form.themes.forestry')},
            {key: "4", value: "sea", text: t('new_form.themes.sea')},
            {
                key: "5",
                value: "resources",
                text: t('new_form.themes.resources')
            },
            {key: "6", value: "threats", text: t('new_form.themes.threats')},
            {key: "7", value: "planning", text: t('new_form.themes.planning')},
            {key: "9", value: "other", text: t('new_form.themes.other')}
        ],
        aquisitionMode: [
            {key: 1, value: "mono", text: t('new_form.mono')},
            {key: 2, value: "stereo", text: t('new_form.stereo')}
        ],
        resolutions: [
            {key: 1, value: "high", text: t('new_form.high')},
            {key: 2, value: "medium", text: t('new_form.medium')},
        ],
        levels: [
            {key: 1, value: "L1", text: "L1"},
            {key: 2, value: "L2", text: "L2"},
            {key: 3, value: "L3", text: "L3"},
            {key: 4, value: "L4", text: "L4"}
        ],
        geoJSON: {
            type: "Feature",
            properties: {},
            geometry: {
                type: "Polygon",
                coordinates: []
            }
        },
        types: [
            {key: 1, value: "normal", text: t('new_form.normal')},
            {key: 2, value: "map", text: t('new_form.map')},
            {key: 3, value: "kml", text: t('new_form.kml')}
        ],
        datePeriods: [],
        periodCounter: 0,
        periods: [],
        selectedTheme: "",
        selectedMode: "",
        isOtherChosen: false,
        points: [],
        selectedResolution: "",
        level: "",
        cloud: 0,
        priority: "standard",
        express: false,
        comments: "",
        modal: true,
        type: "",
        isMapModal: false,
        kmlFile: "",
        address: "",
        polygonCoordinates: [],
        price: 0,
        roll: 0,
        pitch: 0,
        yaw: 0,
        prices: {
            kzt: 0,
            usd: 0
        }
    };

    file = React.createRef();
    toaster = React.createRef();

    themeSelected = (e, data) => {
        if (data.value === "other") {
            this.setState({isOtherChosen: true});
        } else {
            this.setState({isOtherChosen: false});
            this.setState({selectedTheme: data.value});
        }
    };

    setSliderValue = type => {
        this.setState({[type]: document.getElementById(type).value});
    };

    setDateRange = (date, key) => {
        if (this.state.periods[key]) {
            this.state.periods[key] = {start: date.startDate, end: date.endDate};
        } else {
            this.state.periods.push({start: date.startDate, end: date.endDate});
        }
    };
    removeDateRange = key => {
        // if(this.state.periods[key]){
        //     this.state.periods.splice(key, 1)
        //     let p = this.state.datePeriods.splice(key+1, 1)
        //     this.setState({datePeriods: p})
        //     this.setState({state: this.state})
        // }
        this.setState({periodCounter: this.state.periodCounter - 1});
        this.state.periods.pop();
    };

    addPeriod = () => {
        this.state.datePeriods = [];
        for (let i = 0; i < this.state.periodCounter; i++) {
            this.state.datePeriods.push(
                <Period
                    key={i + 1}
                    dateKey={i}
                    onRemoveClicked={this.removeDateRange}
                    onDateChosen={this.setDateRange}
                />
            );
        }
    };

    setCoordinates = coordinates => {
        let coords = [];
        coordinates.forEach(c => {
            if (c.latitude !== "" && c.longitude !== "") {
                coords.push([parseFloat(c.latitude), parseFloat(c.longitude)]);
            }
        });
        this.setState({points: coords});

        coords.push(coords[0]);
        let temp = this.state.geoJSON;
        temp.geometry.coordinates = [coords];
        this.setState({geoJSON: temp});
        // if(this.state.geoJSON && this.state.geoJSON.geometry && this.state.geoJSON.geometry.coordinates && this.state.geoJSON.geometry.coordinates[0].length >= 4 && this.getAreaOfPolygon() > 100000) {
        //     this.toaster.current.configErrorResponse({message: 'TooLargeArea'});
        // }
        // if(this.state.geoJSON && this.state.geoJSON.geometry && this.state.geoJSON.geometry.coordinates && this.state.geoJSON.geometry.coordinates[0].length >= 4 && this.getAreaOfPolygon <=100000){
        //     this.calculatePrice();
        // }

        if(this.state.geoJSON && this.state.geoJSON.geometry && this.state.geoJSON.geometry.coordinates && this.state.geoJSON.geometry.coordinates[0].length >= 4) {
            this.toaster.current.configErrorResponse({message: 'TooLargeArea'});
        }

        if(this.state.geoJSON && this.state.geoJSON.geometry && this.state.geoJSON.geometry.coordinates && this.state.geoJSON.geometry.coordinates[0].length >= 4 ){
            this.calculatePrice();
        }
    };

    setResolution = async (e, data) => {
        await this.setState({selectedResolution: data.value});
        this.calculatePrice();
    };

    setFormType = type => {
        this.setState({type: type, modal: false});
        if (type === "map") {
            this.setState({isMapModal: true});
        }
    };

    reselectType = () => {
        this.setState({isMapModal: false, type: "", modal: true});
    };

    setGeoJSON = geoJSON => {
        this.setState({polygonCoordinates: [], isMapModal: false, geoJSON: geoJSON});
        geoJSON.geometry.coordinates[0].forEach((c, index) => {
            if (index < geoJSON.geometry.coordinates[0].length - 1) {
                this.state.polygonCoordinates.push(
                    <Form.Group
                        key={index}
                        className="min-width"
                        widths="equal"
                        style={{marginBottom: "10px"}}
                    >
                        <Form.Input
                            disabled
                            className="coordinates"
                            type="number"
                            fluid
                            placeholder={t('new_form.latitude')}
                            value={c[0]}
                        />
                        <Form.Input
                            disabled
                            className="coordinates"
                            type="number"
                            fluid
                            placeholder={t('new_form.longitude')}
                            value={c[1]}
                        />
                    </Form.Group>
                );
            }
        });
    };

    setKML = async (e) => {
        if (e.target.files && e.target.files[0] && e.target.files[0].name) {
            document.getElementById("kml-label").innerHTML = e.target.files[0].name;
        }
        await this.setState({kmlFile: e.target.files[0]});
        this.calculatePrice();
    };

    typeChanged = (e, data) => {
        this.setState({type: data.value});
        if (data.value === "map") {
            this.setState({isMapModal: true});
        }
    };

    getForm = () => {
        let formData = new FormData();

        if (this.state.type === "map" && this.checkPolygon()) {
            this.state.geoJSON.geometry.coordinates[0].push(
                this.state.geoJSON.geometry.coordinates[0][0]
            );
        }
        if (this.state.type !== "kml") {
            formData.append("type", "geojson");
            if (
                GJV.isPolygon(this.state.geoJSON.geometry) &&
                GJV.isGeoJSONObject(this.state.geoJSON) 
                //&& this.getAreaOfPolygon() <= 100000
            ) {
                formData.append("geojson", JSON.stringify(this.state.geoJSON));
            }
        } else {
            formData.append("type", "kml");
            if (this.state.kmlFile) {
                formData.append("kml", this.state.kmlFile);
            }
        }

        if (this.state.selectedTheme !== "") {
            formData.append("application_theme", this.state.selectedTheme);
        }
        if (this.state.selectedMode !== "") {
            formData.append("acquisition_mode", this.state.selectedMode);
        }

        if (this.state.roll !== "") {
            formData.append("roll", this.state.roll);
        }

        if (this.state.pitch !== "") {
            formData.append("pitch", this.state.pitch);
        }

        if (this.state.yaw !== "") {
            formData.append("yaw", this.state.yaw);
        }

        if (this.state.periods.length > 0) {
            let periods = this.state.periods;
            periods.forEach(period => {
                period.start = new Date(period.start).toISOString().slice(0, 10);
                period.end = new Date(period.end).toISOString().slice(0, 10);
            });
            formData.append("periods", JSON.stringify(periods));
        }
        if (this.state.selectedResolution !== "") {
            formData.append("resolution", this.state.selectedResolution.toUpperCase());
        }

        if (this.state.level !== "") {
            formData.append("processing_level", this.state.level);
        }

        if (this.state.address !== "") {
            formData.append("address", this.state.address);
        }

        if (this.state.yaw !== "") {
            formData.append("cloud_coverage", this.state.cloud);
        }

        if (this.state.comments !== "") {
            formData.append("wishes", this.state.comments);
        }

        if (this.state.priority !== '') {
            formData.append("priority", this.state.priority);
        }

        if (this.state.express) {
            formData.append("is_express", this.state.express);
        }
        return formData;
    };

    applyForm = () => {
        let formData = this.getForm();
        formData.append("only_calculate", false);
        this.props.addToCartNew(formData)
            .then(res => {
                if (res.success) this.props.history.push('/cart');
            });
    };

    checkPolygon = () => {
        return !GJV.isPolygon(this.state.geoJSON.geometry)
    };

    getAreaOfPolygon = () => {
        return L.GeometryUtil.geodesicArea(L.geoJSON(this.state.geoJSON).getLayers()[0].getLatLngs()[0])/1000000;
    };

    setCloudRange = (e) => {
        this.setState({cloud: parseInt(e.target.value)})
    };

    calculatePrice = () => {
        if(this.state.selectedResolution && this.state.selectedMode && this.state.level && (this.state.kmlFile || (this.state.geoJSON && !this.checkPolygon()))){
            let formData = this.getForm();
            formData.append("only_calculate", true);
            this.props.calculateNewPrice(formData)
                .then(res => {
                    this.setState({prices: res})
                });
        }
    };

    onChange = e => this.setState({...this.state, [e.target.name]: parseInt(e.target.value)});

    render() {
        this.addPeriod();
        return (
            <React.Fragment>
                {this.props.isAuthenticated && (
                    <div>
                        <div>
                            <div id="container">
                                <Card className="form-container" raised centered color="grey">
                                    <Card.Content>
                                        <span style={{fontWeight: "bold", fontSize: "1.3em"}}>
                                          {t('new_form.new_order')}
                                        </span>
                                        <Select

                                            value={this.state.type}
                                            onChange={this.typeChanged}
                                            style={{float: "right", minWidth: "fit-content"}}
                                            options={this.state.types}
                                        />
                                    </Card.Content>
                                    <Card.Content>
                                        <Form>
                                            {this.state.type === "normal" && (
                                                <Form.Field>
                                                    <label>{t('new_form.coordinates')} <strong style={{color: 'red'}}>*</strong></label>
                                                    <Points onCoordinateInserted={this.setCoordinates}/>
                                                </Form.Field>
                                            )}
                                            {this.state.type === "map" &&
                                            this.state.polygonCoordinates.length > 0 && (
                                                <Form.Field>
                                                    <label>{t('new_form.coordinates')}</label>
                                                    {this.state.polygonCoordinates}
                                                </Form.Field>
                                            )}
                                            {this.state.type === "kml" && (
                                                <Form.Field>
                                                    <label
                                                        id="kml-label"
                                                        htmlFor="kml"
                                                        className="file-upload"
                                                    >
                                                        {t('new_form.kml')} <strong style={{color: 'red'}}>*</strong>
                                                    </label>
                                                    <input
                                                        id="kml"
                                                        ref={this.file}
                                                        type="file"
                                                        accept=".kml"
                                                        onChange={e => this.setKML(e)}
                                                    />
                                                </Form.Field>
                                            )}
                                            <Form.Field>
                                                <label>{t('new_form.date_range')}</label>
                                                {this.state.datePeriods}
                                                <Button
                                                    onClick={() =>
                                                        this.setState({
                                                            periodCounter: this.state.periodCounter + 1
                                                        })
                                                    }
                                                    fluid
                                                    icon
                                                >
                                                    <Icon name="plus"/>
                                                    &nbsp; {t('new_form.add_date_range')}
                                                </Button>
                                            </Form.Field>
                                            <Form.Field>
                                                <label>{t('new_form.address')}</label>
                                                <input
                                                    onChange={e =>
                                                        this.setState({address: e.target.value})
                                                    }
                                                    type="text"
                                                    placeholder={t('new_form.address_placeholder')}
                                                />
                                            </Form.Field>
                                            <Form.Field>
                                                <label>{t('new_form.theme')}</label>
                                                <Select
                                                    placeholder={t('new_form.theme_placeholder')}
                                                    onChange={this.themeSelected}
                                                    options={this.state.themeOptions}
                                                />
                                                {this.state.isOtherChosen && (
                                                    <TextArea
                                                        style={{marginTop: "10px"}}
                                                        onChange={(e, data) =>
                                                            this.setState({selectedTheme: data.value})
                                                        }
                                                    />
                                                )}
                                            </Form.Field>
                                            <Form.Field>
                                                <label>{t('new_form.resolution')} <strong style={{color: 'red'}}>*</strong></label>
                                                <Select
                                                    value={this.state.selectedResolution}
                                                    placeholder={t('new_form.resolution_placeholder')}
                                                    onChange={this.setResolution}
                                                    options={this.state.resolutions}
                                                />
                                            </Form.Field>
                                            <Form.Field>
                                                <label>{t('new_form.acquisition')} <strong style={{color: 'red'}}>*</strong></label>
                                                <Select
                                                    value={this.state.selectedMode}
                                                    placeholder={t('new_form.acquisition_mode')}
                                                        onChange={async (e, data) =>{
                                                            await this.setState({selectedMode: data.value});
                                                            await this.calculatePrice();
                                                        }
                                                    }
                                                    options={this.state.aquisitionMode}
                                                />

                                            </Form.Field>
                                            <Form.Field>
                                                <label>{t('new_form.level')} <strong style={{color: 'red'}}>*</strong></label>
                                                <Select
                                                    value={this.state.level}
                                                    placeholder={t('new_form.level_placeholder')}
                                                    onChange={async (e, data) => {
                                                            await this.setState({level: data.value});
                                                            await this.calculatePrice();
                                                        }
                                                    }
                                                    options={this.state.levels}
                                                />
                                            </Form.Field>
                                            <Form.Field>
                                                <label>{`${t('new_form.cloud')}: <${this.state.cloud}%`}</label>
                                                <input onChange={this.setCloudRange} value={this.state.cloud}
                                                       type="range" min="0" max="100" className="slider"/>
                                            </Form.Field>
                                            <Form.Field>
                                                <label>{`${t('new_form.pitch')}: <${this.state.pitch}`}°</label>
                                                <input onChange={this.onChange} value={this.state.pitch} name="pitch"
                                                       type="range" min="-90" max="90" className="angle"/>
                                            </Form.Field>
                                            <Form.Field>
                                                <label>{`${t('new_form.roll')}: <${this.state.roll}`}°</label>
                                                <input onChange={this.onChange} value={this.state.roll} name="roll"
                                                       type="range" min="-90" max="90" className="angle"/>
                                            </Form.Field>
                                            <Form.Field>
                                                <label>{`${t('new_form.yaw')}: <${this.state.yaw}`}°</label>
                                                <input onChange={this.onChange} value={this.state.yaw} name="yaw"
                                                       type="range" min="0" max="360" className="angle"/>
                                            </Form.Field>
                                            <Form.Field>
                                                <label>{t('new_form.order_priority')} <strong style={{color: 'red'}}>*</strong></label>
                                                <Radio
                                                    className='standart'
                                                    label={t('new_form.standard')}
                                                    name="radioGroup"
                                                    value="standard"
                                                    checked={this.state.priority === "standard"}
                                                    onChange={(e, {value}) =>
                                                        this.setState({priority: value})
                                                    }
                                                />
                                                <Radio
                                                    className="checkbox-form"
                                                    label={t('new_form.priority')}
                                                    name="radioGroup"
                                                    value="priority"
                                                    checked={this.state.priority === "priority"}
                                                    onChange={(e, {value}) =>
                                                        this.setState({priority: value})
                                                    }
                                                />
                                            </Form.Field>
                                            <Form.Field>
                                                <Checkbox
                                                    style={{paddingLeft: "0"}}
                                                    label={t('new_form.express')}
                                                    onChange={() =>
                                                        this.setState(prevState => ({
                                                            express: !prevState.express
                                                        }))
                                                    }
                                                    checked={this.state.express}
                                                />
                                            </Form.Field>
                                            <Form.Field>
                                                <label>{t('new_form.wishes')}</label>
                                                <TextArea
                                                    onInput={e =>
                                                        this.setState({comments: e.target.value})
                                                    }
                                                    value={this.state.comments}
                                                />
                                            </Form.Field>
                                            <Form.Field>
                                                {this.state.prices.kzt > 0 &&
                                                <p>{t('new_form.price')}: {parseFloat(this.state.prices.kzt).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') + " ₸"}</p>}
                                                {this.state.prices.usd > 0 &&
                                                <p>{t('new_form.price')}: {parseFloat(this.state.prices.usd).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') + " $"}</p>}
                                            </Form.Field>
                                        </Form>
                                    </Card.Content>
                                    <Card.Content extra>
                                        {/*<Button*/}
                                        {/*    color='blue'*/}
                                        {/*    onClick={this.calculatePrice}*/}
                                        {/*    disabled={*/}
                                        {/*        (this.state.type === 'kml' ?*/}
                                        {/*            (this.state.kmlFile === '') :*/}
                                        {/*            ((this.state.geoJSON.geometry.coordinates[0] && this.state.geoJSON.geometry.coordinates[0].length > 3) ? this.checkPolygon() : true)) ||*/}
                                        {/*        this.state.selectedResolution === '' ||*/}
                                        {/*        this.state.selectedMode === '' ||*/}
                                        {/*        this.state.level === ''*/}
                                        {/*    }*/}
                                        {/*>*/}
                                        {/*    {t('new_form.calculate')}*/}
                                        {/*</Button>*/}
                                        <Button
                                            style={{float: 'right'}}
                                            color="green"
                                            onClick={this.applyForm}
                                            disabled={
                                                (this.state.type === 'kml' ?
                                                    (this.state.kmlFile === '') :
                                                    ((this.state.geoJSON.geometry.coordinates[0] && this.state.geoJSON.geometry.coordinates[0].length > 3 /*&& this.getAreaOfPolygon() <= 100000*/) ? this.checkPolygon() : true)) ||
                                                this.state.selectedResolution === '' ||
                                                this.state.selectedMode === '' ||
                                                this.state.level === ''
                                            }
                                        >
                                            <Icon name="checkmark"/> {t('new_form.apply')}
                                        </Button>
                                        <Button
                                            style={{float: 'right'}}
                                            color="red"
                                            onClick={() => this.props.history.goBack()}
                                        >
                                            <Icon name="remove"/> {t('new_form.cancel')}
                                        </Button>
                                    </Card.Content>
                                </Card>
                                {this.state.isMapModal && (
                                    <Draw
                                        onSubmitClicked={this.setGeoJSON}
                                        onCancelClicked={this.reselectType}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                )}
                <Choose
                    isModalActive={this.state.modal}
                    onTypeChosen={this.setFormType}
                />
                <Toast ref={this.toaster} />
            </React.Fragment>
        );
    }
}

NewForm.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    addToCartNew: PropTypes.func.isRequired,
    calculateNewPrice: PropTypes.func.isRequired
}

function mapStateToProps(state) {
    return {
        isAuthenticated: !!state.user.token
    };
}

export default withRouter(connect(mapStateToProps, {addToCartNew, calculateNewPrice})(NewForm));
