import React from "react";
import { Button, Image, LabelDetail, Modal, Table } from "semantic-ui-react";
import { saveAs } from "file-saver";
import "./Info.css";
import { t } from "../../../../../../lang";

class Info extends React.Component {
  state = {
    isModalActive: false,
    layer: {},
  };

  setLayer = (layer) => {
    this.setState({ layer: layer });
  };

  showModal = () => {
    this.setState({ isModalActive: true });
  };

  closeModal = () => {
    this.setState({ isModalActive: false });
    this.setState({ layer: {} });
  };

  toKMLFolder = (layer) => {
    const coorList = layer.geometry.coordinates;
    return `<?xml version="1.0" encoding="UTF-8"?><Folder><name>${
      layer.code
    }</name><description>Date: ${layer.meta_date}, Satellite: ${
      layer.satellite
    }</description><Placemark><Polygon><outerBoundaryIs><LinearRing><coordinates>${
      coorList[1]
    },${coorList[0]},0 ${coorList[7]},${coorList[6]},0 ${coorList[5]},${
      coorList[4]
    },0 ${coorList[3]},${coorList[2]},0 ${coorList[1]},${
      coorList[0]
    },0</coordinates></LinearRing></outerBoundaryIs></Polygon></Placemark><GroundOverlay><Icon><href>${window
      .location.href +
      layer.quicklook_url}</href></Icon><gx:LatLonQuad xmlns:gx="http://www.google.com/kml/ext/2.2"><coordinates>${
      coorList[1]
    },${coorList[0]},0 ${coorList[7]},${coorList[6]},0 ${coorList[5]},${
      coorList[4]
    },0 ${coorList[3]},${coorList[2]},0 ${coorList[1]},${
      coorList[0]
    },0</coordinates></gx:LatLonQuad></GroundOverlay></Folder>`;
  };

  downloadKml = (layer) => {
    console.log(layer);
    const kml = this.toKMLFolder(layer);
    const file = new Blob([kml], { type: "text/xml" });
    saveAs(file, `${layer.code}.kml`);
  };

  render() {
    return (
      <Modal
        open={this.state.isModalActive}
        onClose={this.props.onModalClosed}
        style={{ width: "auto" }}
      >
        <Modal.Header>{this.state.layer.code}</Modal.Header>
        <Modal.Content image>
          <Image wrapped size="medium" src={ this.state.layer.quicklook_url } onError="this.src='images/not_found.png';" />
          <Modal.Description>
            <Table basic="very">
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <LabelDetail>
                      <strong>{t("sidebar.info.description")}</strong>
                    </LabelDetail>
                  </Table.Cell>
                  <Table.Cell>{this.state.layer.satellite}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <strong>{t("sidebar.info.date")}</strong>
                  </Table.Cell>
                  <Table.Cell>{this.state.layer.meta_date}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <strong>Url</strong>
                  </Table.Cell>
                  <Table.Cell>
                    <a
                      href={this.state.layer.quicklook_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t("sidebar.info.link")}
                    </a>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <strong>{t("sidebar.info.pitch")}</strong>
                  </Table.Cell>
                  <Table.Cell>{this.state.layer.pitch}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <strong>{t("sidebar.info.roll")}</strong>
                  </Table.Cell>
                  <Table.Cell>{this.state.layer.roll}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <strong>{t("sidebar.info.yaw")}</strong>
                  </Table.Cell>
                  <Table.Cell>{this.state.layer.yaw}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <strong>{t("sidebar.info.cloud")}</strong>
                  </Table.Cell>
                  <Table.Cell>
                    {this.state.layer.cloud_coverage
                      ? this.state.layer.cloud_coverage
                      : t("orders.not_defined")}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            <Button
              icon="download"
              fluid
              positive
              content={t("sidebar.info.download")}
              onClick={() => this.downloadKml(this.state.layer)}
            />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            icon="sign-out"
            primary
            content={t("sidebar.info.close")}
            onClick={this.props.onModalClosed}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

export default Info;
