import React, { Component } from "react";
import { connect } from "react-redux";
import Slider from "./Components/Slider";
import "react-input-range/lib/css/index.css";
import DatePicker from "./Components/DatePicker";
import PropTypes from "prop-types";
import { List, Dropdown, Button, Icon } from "semantic-ui-react";
import "./Filter.css";
import { t } from "../../../../../lang";

class Filter extends Component {
  state = {
    cloud: 0,
    pitch: 0,
    roll: 0,
    yaw: 0,
    sun: 0,
    type: "",
    advanced: false,
    advanced_icon: "chevron down",
  };

  setAngle = (data, type) => {
    this.setState({ [type]: data });
    switch (type) {
      case "cloud":
        this.props.onCloudChosen(data);
        break;
      case "sun":
        this.props.onSunChosen(data);
        break;
      case "pitch":
        this.props.onPitchChosen(data);
        break;
      case "roll":
        this.props.onRollChosen(data);
        break;
      case "yaw":
        this.props.onYawChosen(data);
        break;
      default:
        break;
    }
  };

  setType = (e, { value }) => {
    this.props.onTypeChosen(value);
    if (value === "KazEOSat-1") {
      this.props.onSunChosen(0);
      this.props.onPitchChosen(0);
      this.props.onRollChosen(0);
      this.props.onYawChosen(0);
    }
    // this.setState({type: value})
  };

  toggleAdvancedSearch = () => {
    const { advanced, advanced_icon } = this.state;
    this.setState({
      advanced: !advanced,
      advanced_icon:
        advanced_icon === "chevron down" ? "chevron up" : "chevron down",
    });

    if (advanced) {
      this.setAngle(0, "cloud");
      this.setAngle(0, "sun");
      this.setAngle(0, "pitch");
      this.setAngle(0, "roll");
      this.setAngle(0, "yaw");
    }
  };

  render() {
    return (
      <List className="list-container">
        <List.Item>
          <label htmlFor="type">{t("sidebar.filter.type")}:</label>
          <Dropdown
            id="type"
            fluid
            onChange={this.setType}
            selection
            defaultValue={this.props.satellite}
            options={[
              { text: "KazEOSat-1", key: "KazEOSat-1", value: "KazEOSat-1" },
              { text: "KazEOSat-2", key: "KazEOSat-2", value: "KazEOSat-2" },
            ]}
          />
        </List.Item>
        <List.Item>
          <label htmlFor="data">{t("sidebar.filter.date")}:</label>
          <DatePicker
            id="date"
            dateFrom={this.props.dateFrom}
            dateTo={this.props.dateTo}
            onDateChosen={this.props.onDateChosen}
          />
        </List.Item>
        <List.Item
          disabled={!this.props.isAuthenticated}
          className="advanced"
          onClick={this.toggleAdvancedSearch}
        >
          <label>
            {t("sidebar.filter.advanced")}{" "}
            <Icon name={this.state.advanced_icon} />
          </label>
        </List.Item>
        {this.state.advanced && (
          <>
            <List.Item disabled={!this.props.isAuthenticated}>
              <label>
                {t("sidebar.filter.cloud")}: {this.props.cloud} %
              </label>
              <Slider
                value={this.props.cloud}
                min="0"
                max="100"
                type="cloud"
                onAngleChange={(data) => this.setAngle(data, "cloud")}
              />
            </List.Item>
            <List.Item
              disabled={
                !this.props.isAuthenticated ||
                this.props.satellite === "KazEOSat-1"
              }
            >
              <label>
                {t("sidebar.filter.pitch")}: {this.props.pitch} °{" "}
                {this.props.satellite === "KazEOSat-1"
                  ? t("sidebar.filter.not_for_kazeosat_1")
                  : ""}
              </label>
              <Slider
                value={this.props.pitch}
                min="-90"
                max="90"
                type="angle"
                onAngleChange={(data) => this.setAngle(data, "pitch")}
              />
            </List.Item>
            <List.Item
              disabled={
                !this.props.isAuthenticated ||
                this.props.satellite === "KazEOSat-1"
              }
            >
              <label>
                {t("sidebar.filter.roll")}: {this.props.roll} °{" "}
                {this.props.satellite === "KazEOSat-1"
                  ? t("sidebar.filter.not_for_kazeosat_1")
                  : ""}
              </label>
              <Slider
                value={this.props.roll}
                min="-90"
                max="90"
                type="angle"
                onAngleChange={(data) => this.setAngle(data, "roll")}
              />
            </List.Item>
            <List.Item
              disabled={
                !this.props.isAuthenticated ||
                this.props.satellite === "KazEOSat-1"
              }
            >
              <label>
                {t("sidebar.filter.yaw")}: {this.props.yaw} °{" "}
                {this.props.satellite === "KazEOSat-1"
                  ? t("sidebar.filter.not_for_kazeosat_1")
                  : ""}
              </label>
              <Slider
                value={this.props.yaw}
                min="0"
                max="360"
                type="angle"
                onAngleChange={(data) => this.setAngle(data, "yaw")}
              />
            </List.Item>
            <List.Item
              disabled={
                !this.props.isAuthenticated ||
                this.props.satellite === "KazEOSat-1"
              }
            >
              <label>
                {t("sidebar.filter.sun")}: {this.props.sun} °{" "}
                {this.props.satellite === "KazEOSat-1"
                  ? t("sidebar.filter.not_for_kazeosat_1")
                  : ""}
              </label>
              <Slider
                value={this.props.sun}
                min="0"
                max="100"
                type="sun"
                onAngleChange={(data) => this.setAngle(data, "sun")}
              />
            </List.Item>
          </>
        )}
        <List.Item className="buttons">
          <Button fluid primary onClick={this.props.onResetClicked}>
            {t("sidebar.filter.reset")}
          </Button>
        </List.Item>
      </List>
    );
  }
}

Filter.propTypes = {
  onTypeChosen: PropTypes.func.isRequired,
  onResetClicked: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    isAuthenticated: !!state.user.token,
    user: state.user,
    quicklooks: state.quicklooks,
  };
}

export default connect(
  mapStateToProps,
  null
)(Filter);
