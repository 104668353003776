import React, { Component } from "react";
import { Input, Modal } from "semantic-ui-react";
import { t } from "../../../../../../lang";

class Link extends Component {
  state = {
    isOpen: false,
  };

  copyClicked = (e) => {
    const copyText = document.getElementById("copy-input");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
  };

  showModal = () => {
    this.setState({ isOpen: true });
  };

  hideModal = () => {
    this.setState({ isOpen: false });
    this.props.onShareModalClose();
  };

  render() {
    return (
      <Modal
        size="small"
        open={this.state.isOpen}
        onClose={() => this.hideModal()}
      >
        <Modal.Header>{t("sidebar.layers.copy_link")}</Modal.Header>
        <Modal.Content>
          <Input
            id="copy-input"
            fluid
            action={{
              color: "green",
              icon: "copy",
              onClick: (e) => this.copyClicked(e),
            }}
            value={this.props.url}
          />
        </Modal.Content>
      </Modal>
    );
  }
}

export default Link;
