import React from 'react';
import { Button, Card } from 'semantic-ui-react';
import L from 'leaflet';
import 'leaflet-mouse-position'
import './Draw.css'
import { leaflet_russian, leaflet_english } from '../../../../assets/js/leaflet.locale.js';
import {t, getLanguage} from "../../../../lang";
import Toast from '../../../messages/Toast';

class Draw extends React.Component {
    state = { 
        map: null,
        latlng: [47.2286086, 65.2093197],
        zoom: 4,
        polygon: new L.FeatureGroup(),
        control: {},
        polygonCreated: false
    };

    toaster = React.createRef();

    componentDidMount() {
       this.initMap();
        if(getLanguage() === 'ru') {
            leaflet_russian(L);
        } else {
            leaflet_english(L);
        }
    }

    initMap = () => {
        this.state.map = L.map("map-polygon", {
            minZoom: 5,
            maxZoom: 18
        }).setView(this.state.latlng, this.state.zoom);
        L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="http://osm.org/copyright">OSM</a>'
        }).addTo(this.state.map);
        this.state.polygon.addTo(this.state.map);
        L.control.mousePosition().addTo(this.state.map);
        this.state.control = new L.Control.Draw({
            position: 'topright',
            draw: {
                circlemarker: false,
                circle: false,
                polyline: false,
                rectangle: false,
                marker: false,
                polygon: {
                    shapeOptions: {
                        editing: {
                            className: ''
                        }
                    },
                    allowIntersection: false
                }
            },
            edit: {
                featureGroup: this.state.polygon,
                edit: true,
                remove: true 
            }
        });
        this.state.map.addControl(this.state.control);

        this.state.map.on("draw:created", (e) => {
            let layer = e.layer;
            // if(L.GeometryUtil.geodesicArea(layer.getLatLngs()[0])/1000000 <= 100000) {
                layer.addTo(this.state.polygon);
                this.state.control.setDrawingOptions({
                    polygon: false
                });
                this.state.map.removeControl(this.state.control);
                this.state.map.addControl(this.state.control);
                this.setState({polygonCreated: true})
            // } else {
            //     this.toaster.current.configErrorResponse({message: 'TooLargeArea'});
            // }
        });

        this.state.map.on("draw:deleted", (e) => {
            this.state.control.setDrawingOptions({
                polygon: true
            });
            this.state.map.removeControl(this.state.control); 
            this.state.map.addControl(this.state.control);
            this.setState({polygonCreated: false})
        });
    };

    submitClicked = () => {
        this.props.onSubmitClicked(this.state.polygon.toGeoJSON().features[0]);
    };

    render() { 
        return ( 
            <div id='map-polygon-wrapper'>
                <div id='map-polygon-modal'>
                    <Card fluid>
                    <Card.Content>
                        <Card.Header>{t('new_form.title')}</Card.Header>
                    </Card.Content>
                    <Card.Content id='map-polygon-content'>
                        <div id='map-polygon'/>
                    </Card.Content>
                    <Card.Content extra style={{textAlign: 'right'}}>
                        <Button color='red' onClick={this.props.onCancelClicked}>
                            {t('new_form.cancel')}
                        </Button>
                        <Button disabled={!this.state.polygonCreated} color='green' onClick={this.submitClicked}>
                            {t('new_form.apply')}
                        </Button>
                    </Card.Content>
                    </Card>
                </div>
                <Toast ref={this.toaster} />
            </div>
        );
    }
}
 
export default Draw;