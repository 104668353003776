import React, { Component } from "react";
import { Button, Modal, Form, Icon } from "semantic-ui-react";
import InputPhone from "react-phone-number-input/input";
import { t } from "../../lang";
import InlineError from "../messages/InlineError";
import "./UserModal.css";

class UserModal extends Component {
  state = {
    isActive: false,
    isEdit: false,
    user: {
      email: "",
      phone: "",
      first_name: "",
      last_name: "",
      company: "",
      purpose: "",
      password: "",
    },
    roles: [
      { key: 1, value: "admin", text: t("signup.roles.admin") },
      { key: 2, value: "manager", text: t("signup.roles.manager") },
      { key: 3, value: "handler", text: t("signup.roles.handler") },
      { key: 4, value: "user", text: t("signup.roles.user") },
    ],
    genders: [
      { key: 1, value: "male", text: t("signup.gender.male") },
      { key: 2, value: "female", text: t("signup.gender.female") },
      { key: 3, value: null, text: t("signup.gender.unclear") },
    ],
    repassword: "",
    eye: "eye",
    passwordType: "password",
    errors: null,
  };

  showModal = (user = null) => {
    if (user) {
      this.setState({ user: user, isEdit: true });
    }
    this.setState({ isActive: true });
  };

  hideModal = () => {
    this.setState({
      isActive: false,
      user: {
        email: "",
        phone: "",
        first_name: "",
        last_name: "",
        company: "",
        purpose: "",
        password: "",
      },
      repassword: "",
      errors: [],
    });
  };

  handleChange = (e) => {
    this.setState({
      user: { ...this.state.user, [e.target.name]: e.target.value },
    });
  };

  handleRoleChange = (e, data) => {
    this.setState({ user: { ...this.state.user, role: data.value } });
  };

  handleGenderChange = (e, data) => {
    this.setState({ user: { ...this.state.user, gender: data.value } });
  };

  onPhoneChange = (e) => {
    if (e) {
      this.setState({
        errors: { ...this.state.errors, phone: null },
        user: { ...this.state.user, phone: e },
      });
    }
  };

  togglePassword = () => {
    if (this.state.eye === "eye") {
      this.setState({ eye: "eye slash", passwordType: "text" });
    } else {
      this.setState({ eye: "eye", passwordType: "password" });
    }
  };

  handlePassword = (e) => {
    this.setState({
      errors: { ...this.state.errors, [e.target.name]: null },
    });
    this.setState({ repassword: e.target.value });
  };

  handleSubmit = () => {
    const errors = this.validate();
    this.setState({ errors });

    if (Object.keys(errors).length === 0) {
      this.props.onConfirm(this.state.user);
    }
  };

  onFocus = (e) => {
    if (this.state.errors) {
      this.setState({ errors: {} });
    }
  };

  validate = () => {
    const errors = {};
    if (!this.state.isEdit) {
      if (
        this.state.user.email === "" ||
        this.state.user.email === null ||
        this.state.user.email === undefined
      ) {
        errors.email = "Email is empty";
      }
      if (
        this.state.user.password === "" ||
        this.state.user.password === null ||
        this.state.user.password === undefined
      ) {
        errors.password = "Password is empty";
      }
      if (
        this.state.repassword === "" ||
        this.state.repassword === null ||
        this.state.repassword === undefined
      ) {
        errors.password = "Password is empty";
      }
    }
    if (
      this.state.user.first_name === "" ||
      this.state.user.first_name === null ||
      this.state.user.first_name === undefined
    ) {
      errors.first_name = "First name is empty";
    }
    if (
      this.state.user.last_name === "" ||
      this.state.user.last_name === null ||
      this.state.user.last_name === undefined
    ) {
      errors.last_name = "Last name is empty";
    }
    if (
      this.state.user.company === "" ||
      this.state.user.company === null ||
      this.state.user.company === undefined
    ) {
      errors.company = "Company is empty";
    }
    if (
      this.state.user.purpose === "" ||
      this.state.user.purpose === null ||
      this.state.user.purpose === undefined
    ) {
      errors.purpose = "Puspose is empty";
    }
    if (
      this.state.user.phone === "" ||
      this.state.user.phone === null ||
      this.state.user.phone === undefined
    ) {
      errors.phone = "Phone is empty";
    }

    let emailPattern = new RegExp(
      "^[a-z0-9]+(.[_a-z0-9]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,15})$",
      "i"
    );
    let passwordPattern = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})",
      "i"
    );
    let phonePattern = new RegExp("^[0-9]{11}$", "i");

    if (!this.state.isEdit) {
      let email = this.state.user.email.toLowerCase();
      if (
        this.state.user.email &&
        this.state.user.email &&
        !emailPattern.test(email)
      ) {
        errors.email = t("signup.errors.valid.email");
      }
      if (
        this.state.user.password &&
        !passwordPattern.test(this.state.user.password)
      ) {
        errors.password = t("signup.errors.valid.password");
      }

      if (
        this.state.repassword &&
        !passwordPattern.test(this.state.repassword)
      ) {
        errors.repassword = t("signup.errors.valid.password");
      }
    }

    let phone;
    if (this.state.user.phone)
      phone = this.state.user.phone.replace(" ", "").replace("+", "");

    if (phone && !phonePattern.test(phone)) {
      errors.phone = t("signup.errors.valid.phone");
    }

    if (!this.state.isEdit) {
      if (this.state.user.password && this.state.user.password.length < 8) {
        errors.password = t("signup.errors.min_password");
      }

      if (this.state.repassword && this.state.repassword.length < 8) {
        errors.repassword = t("signup.errors.min_password");
      }

      if (this.state.user.password !== this.state.repassword) {
        errors.password = t("signup.errors.match");
        errors.repassword = t("signup.errors.match");
      }
    }

    if (this.state.user.first_name && this.state.user.first_name.length > 32) {
      errors.first_name = t("signup.errors.max.name");
    }

    if (this.state.user.last_name && this.state.user.last_name.length > 32) {
      errors.last_name = t("signup.errors.max.name");
    }

    if (this.state.user.company && this.state.user.company.length > 64) {
      errors.company = t("signup.errors.max.company");
    }

    if (this.state.user.purpose && this.state.user.purpose.length > 500) {
      errors.purpose = t("signup.errors.max.purpose");
    }

    return errors;
  };

  render() {
    const {
      user,
      isEdit,
      isActive,
      repassword,
      passwordType,
      eye,
    } = this.state;
    return (
      <div>
        <Modal size="small" open={isActive} onClose={this.props.onCancel}>
          <Modal.Header>
            {isEdit ? t("signup.edit") : t("signup.add")} {t("signup.user")}
          </Modal.Header>
          <Modal.Content>
            <Form>
              {!isEdit && (
                <Form.Field
                  error={this.state.errors && !!this.state.errors.email}
                >
                  <Form.Input
                    name="email"
                    type="email"
                    value={user.email}
                    onFocus={this.onFocus}
                    onChange={this.handleChange}
                    label={
                      <label>
                        {t("signup.email")} <span className="star">*</span>
                      </label>
                    }
                    placeholder="example@example.com"
                  />
                  {this.state.errors && this.state.errors.email && (
                    <InlineError text={this.state.errors.email} />
                  )}
                </Form.Field>
              )}
              <Form.Field
                error={this.state.errors && !!this.state.errors.phone}
              >
                <label>
                  {t("signup.phone")} <span className="star">*</span>
                </label>
                <InputPhone
                  id="phone"
                  name="phone"
                  placeholder="+77000000000"
                  value={user.phone}
                  onFocus={this.onFocus}
                  onChange={this.onPhoneChange}
                />
                {this.state.errors && this.state.errors.phone && (
                  <InlineError text={this.state.errors.phone} />
                )}
              </Form.Field>
              <Form.Field
                error={this.state.errors && !!this.state.errors.first_name}
              >
                <Form.Input
                  type="text"
                  name="first_name"
                  onFocus={this.onFocus}
                  value={user.first_name}
                  onChange={this.handleChange}
                  label={
                    <label>
                      {t("signup.first_name")} <span className="star">*</span>
                    </label>
                  }
                  placeholder="First name"
                />
                {this.state.errors && this.state.errors.first_name && (
                  <InlineError text={this.state.errors.first_name} />
                )}
              </Form.Field>
              <Form.Field
                error={this.state.errors && !!this.state.errors.last_name}
              >
                <Form.Input
                  type="text"
                  name="last_name"
                  value={user.last_name}
                  onFocus={this.onFocus}
                  onChange={this.handleChange}
                  label={
                    <label>
                      {t("signup.last_name")} <span className="star">*</span>
                    </label>
                  }
                  placeholder="Last name"
                />
                {this.state.errors && this.state.errors.last_name && (
                  <InlineError text={this.state.errors.last_name} />
                )}
              </Form.Field>
              <Form.Field>
                <Form.Select
                  name="gender"
                  value={user.gender}
                  onFocus={this.onFocus}
                  options={this.state.genders}
                  onChange={this.handleGenderChange}
                  label={<label>{t("signup.gender.label")}</label>}
                />
              </Form.Field>
              <Form.Field
                error={this.state.errors && !!this.state.errors.company}
              >
                <Form.Input
                  type="text"
                  name="company"
                  value={user.company}
                  onFocus={this.onFocus}
                  onChange={this.handleChange}
                  label={
                    <label>
                      {t("signup.company")} <span className="star">*</span>
                    </label>
                  }
                  placeholder="Google Inc."
                />
                {this.state.errors && this.state.errors.company && (
                  <InlineError text={this.state.errors.company} />
                )}
              </Form.Field>
              <Form.Field
                error={this.state.errors && !!this.state.errors.purpose}
              >
                <Form.TextArea
                  type="text"
                  name="purpose"
                  value={user.purpose}
                  onFocus={this.onFocus}
                  onChange={this.handleChange}
                  label={
                    <label>
                      {t("signup.purpose")} <span className="star">*</span>
                    </label>
                  }
                  placeholder="My purpose is..."
                />
                {this.state.errors && this.state.errors.purpose && (
                  <InlineError text={this.state.errors.purpose} />
                )}
              </Form.Field>
              {isEdit && this.props.isAdmin && (
                <Form.Field>
                  <Form.Select
                    name="role"
                    value={user.role}
                    onFocus={this.onFocus}
                    options={this.state.roles}
                    onChange={this.handleRoleChange}
                    label={
                      <label>
                        {t("signup.role")} <span className="star">*</span>
                      </label>
                    }
                  />
                </Form.Field>
              )}
              {!isEdit && (
                <Form.Field
                  error={this.state.errors && !!this.state.errors.password}
                >
                  <Form.Input
                    type={passwordType}
                    name="password"
                    value={user.password}
                    onFocus={this.onFocus}
                    onChange={this.handleChange}
                    label={
                      <label>
                        {t("signup.password")} <span className="star">*</span>
                      </label>
                    }
                    icon={
                      <Icon name={eye} link onClick={this.togglePassword} />
                    }
                  />
                  {this.state.errors && this.state.errors.password && (
                    <InlineError text={this.state.errors.password} />
                  )}
                </Form.Field>
              )}
              {!isEdit && (
                <Form.Field
                  error={this.state.errors && !!this.state.errors.repassword}
                >
                  <Form.Input
                    type={passwordType}
                    name="repassword"
                    value={repassword}
                    onFocus={this.onFocus}
                    onChange={this.handlePassword}
                    label={
                      <label>
                        {t("signup.repeat_password")}{" "}
                        <span className="star">*</span>
                      </label>
                    }
                    icon={
                      <Icon name={eye} link onClick={this.togglePassword} />
                    }
                  />
                  {this.state.errors && this.state.errors.repassword && (
                    <InlineError text={this.state.errors.repassword} />
                  )}
                </Form.Field>
              )}
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button
              negative
              content={t("signup.cancel")}
              onClick={this.props.onCancel}
            />
            <Button
              positive
              content={isEdit ? t("signup.edit") : t("signup.add")}
              onClick={this.handleSubmit}
            />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

UserModal.propTypes = {};

export default UserModal;
