export default {
    en: {
        type: `Type`,
        acquisition: `Acquisition`,
        level: `Level`,
        area: `Area`,
        priority: `Prority`,
        price: `Price`,
        date: `Date`,
        delete: `Delete`,
        archive: `Archive`,
        new: `New`,
        total: `Total price`,
        checkout: `Checkout`,
        nothing: `Your cart is empty`,
        header: `Delete item`,
        content: `Are you sure you want to delete this item`
    },
    ru: {
        type: `Тип`,
        acquisition: `Режим съемки`,
        level: `Уровень`,
        area: `Площадь`,
        priority: `Приоритет`,
        price: `Цена`,
        date: `Дата`,
        delete: `Удалить`,
        archive: `Архивная`,
        new: `Новая`,
        total: `Общая сумма`,
        checkout: `Купить`,
        nothing: `Ваша корзина пустая`,
        header: `Удалить объект`,
        content: `Вы уверены, что хотите удалить этот элемент`
    }
};
