import data from "./data";

let language = "ru";

export const t = key => {
    const dataObject = data.languages[language];
    const tokens = key.split(".");
    let pointer = dataObject;
    while (tokens.length > 0) {
        const token = tokens.shift();
        if (!pointer.hasOwnProperty(token)) {
            throw new Error(`No such key: ${key}`);
        }
        pointer = pointer[token];
    }
    return pointer;
};

export const changeLanguage = lang => {
    language = lang;
};

export const toggleLanguage = () => {
    language = language==='ru'? 'en' : 'ru';
    return language;
};

export const getLanguage = () => {
    return language;
};