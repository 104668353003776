import { USERS_GOTTEN, ME_UPDATED } from '../types';
import api from '../api';
import { requestHasError } from './error';
import { myselfUpdated } from './auth';

export const usersGotten = (data) => ({
    type: USERS_GOTTEN,
    users: data.users,
});

export const getUsers = (data) => dispatch =>  {
    return api.user.users(data).then(res => {
        if(!res.success) dispatch(requestHasError(res));
        if(res.success) dispatch(usersGotten(res));
        return res;
    });
};

export const getUser = (data) => dispatch =>  {
    return api.user.user(data).then(res => {
        if(!res.success) dispatch(requestHasError(res));
        return res;
    });
};

export const deleteUser = (data) => dispatch =>  {
    return api.user.delete(data).then(res => {
        if(!res.success) dispatch(requestHasError(res));
        return res;
    });
};

export const updateUser = (data) => dispatch =>  {
    return api.user.update(data).then(res => {
        if(!res.success) dispatch(requestHasError(res));
        return res;
    });
};

export const getMyself = (data) => dispatch =>  {
    return api.user.me_get(data).then(res => {
        if(!res.success) dispatch(requestHasError(res));
        return res;
    });
};

export const updateMyself = (data) => dispatch =>  {
    return api.user.me_update(data).then(res => {
        if(!res.success) dispatch(requestHasError(res));
        if(res.success) {
            const {user} = res;
            if(localStorage.user) {
                user.token = localStorage.token;
                localStorage.user = JSON.stringify(res.user);
            } else if(sessionStorage.user) {
                user.token = sessionStorage.token;
                sessionStorage.user = JSON.stringify(res.user);
            }
            dispatch(myselfUpdated(user));
        }
        return res;
    });
};

export const updateMyImage = (data) => dispatch =>  {
    return api.user.me_update_image(data).then(res => {
        if(!res.success) dispatch(requestHasError(res));
        if(res.success) {
            const {user} = res;
            if(localStorage.user) {
                user.token = localStorage.token;
                localStorage.user = JSON.stringify(res.user);
            } else if(sessionStorage.user) {
                user.token = sessionStorage.token;
                sessionStorage.user = JSON.stringify(res.user);
            }
            dispatch(myselfUpdated(user));
        }
        return res;
    });
};

export const deleteMyImage = (data) => dispatch =>  {
    return api.user.me_delete_image(data).then(res => {
        if(!res.success) dispatch(requestHasError(res));
        if(res.success) {
            const {user} = res;
            if(localStorage.user) {
                user.token = localStorage.token;
                localStorage.user = JSON.stringify(res.user);
            } else if(sessionStorage.user) {
                user.token = sessionStorage.token;
                sessionStorage.user = JSON.stringify(res.user);
            }
            dispatch(myselfUpdated(user));
        }
        return res;
    });
};