import { ORDERS_GOTTEN, ORDER_GOTTEN, ORDER_CANCELLED } from "../types";
import api from "../api";
import { requestHasError } from "./error";

export const ordersGotten = (data) => ({
  type: ORDERS_GOTTEN,
  orders: data.orders,
});

export const orderGotten = (data) => ({
  type: ORDER_GOTTEN,
});

export const orderCancel = (data) => ({
  type: ORDER_CANCELLED,
});

export const getOrders = (data) => (dispatch) => {
  return api.order.get_orders(data).then((res) => {
    if (!res.success) dispatch(requestHasError(res));
    if (res.success) dispatch(ordersGotten(res));
    return res;
  });
};

export const getAllOrders = (data) => (dispatch) => {
  return api.order.get_orders_all(data).then((res) => {
    if (!res.success) dispatch(requestHasError(res));
    if (res.success) dispatch(ordersGotten(res));
    return res;
  });
};
export const cancelOrder = (data) => (dispatch) => {
  return api.order.cancel_order(data.id).then((res) => {
    if (!res.success) dispatch(requestHasError(res));
    if (res.success) dispatch(orderCancel());
    if (res.success) dispatch(getOrders(data.params));
    return res;
  });
};

export const getUserOrder = (data) => (dispatch) => {
  return api.order.get_user_order(data).then((res) => {
    if (!res.success) dispatch(requestHasError(res));
    if (res.success) dispatch(orderGotten(res));
    return res;
  });
};

export const getOrder = (data) => (dispatch) => {
  return api.order.get_order(data).then((res) => {
    if (!res.success) dispatch(requestHasError(res));
    if (res.success) dispatch(orderGotten(res));
    return res;
  });
};

export const cancelManagerOrder = (data) => (dispatch) => {
  return api.order.cancel_manager_order(data).then((res) => {
    if (!res.success) dispatch(requestHasError(res));
    return res;
  });
};

export const approveManagerOrder = (data) => (dispatch) => {
  return api.order.approve_manager_order(data).then((res) => {
    if (!res.success) dispatch(requestHasError(res));
    return res;
  });
};

export const addDownload = (data) => (dispatch) => {
  return api.order.add_download(data).then((res) => {
    if (!res.success) dispatch(requestHasError(res));
    return res;
  });
};
