export default {
    en: {
        filter: `Filter`,
        area: `Area`,
        price: `Price`,
        status: `Status`,
        date: `Date`,
        total: `Total`,
        done: `Done`,
        cancelled: `Cancelled`,
        processing: `Processing`,
        all: `All`,
        order_id: `Order Id`,
        user: `User`,
        reason: `Reason`,
        response: `Response`,
        placeholder: `Please enter a reason/response for canceling/approving this order`,
        cancel_order: `Cancel order`,
        approve_order: `Approve order`,
        images: `Images`,
        type: `Type`,
        acquisition: `Acquisition`,
        level: `Level`,
        priority: `Priority`,
        delete: `Delete`,
        archive: `Archive`,
        new: `New`,
        resolution: `Resolution`,
        address: `Address`,
        theme: `Theme`,
        wishes: `Wishes`,
        not_defined: `Not defined`,
        cancel_title: `Cancel order`,
        cancel_body: `Are you sure you want to cancel order`,
        approve_title: `Approve order`,
        approve_body: `Are you sure you want to approve order`
    },
    ru: {
        filter: `Фильтр`,
        area: `Площадь`,
        price: `Цена`,
        status: `Статус`,
        date: `Дата`,
        total: `Всего`,
        done: `Выполнено`,
        cancelled: `Отменено`,
        processing: `В обработке`,
        all: `Все`,
        order_id: `Id Заказа`,
        user: `Пользовать`,
        reason: `Причина`,
        response: `Ответ`,
        placeholder: `Пожалуйста, введите причину/ответ для отмены/одобрения этого заказа`,
        cancel_order: `Отменить заказ`,
        approve_order: `Утвердить заказ`,
        images: `Снимки`,
        type: `Тип`,
        acquisition: `Режим съемки`,
        level: `Уровень`,
        priority: `Приоритет`,
        delete: `Удалить`,
        archive: `Архивный`,
        new: `Новый`,
        resolution: `Разрешение`,
        address: `Адрес`,
        theme: `Тема`,
        wishes: `Пожелание`,
        not_defined: `Не указан`,
        cancel_title: `Отменить заказ`,
        cancel_body: `Вы уверены, что хотите отменить заказ`,
        approve_title: `Подтвердить заказ`,
        approve_body: `Вы уверены, что хотите подтвердить заказ`
    }
};
