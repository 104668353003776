import React, { Component } from 'react';
import { FaRegDotCircle, FaDrawPolygon, FaRegObjectUngroup, FaUpload } from "react-icons/fa"
import { List, Icon } from 'semantic-ui-react';
import { connect } from "react-redux";
import { t } from '../../../../../lang';

class Tools extends Component {
    
    render() {
        return (
            <List divided relaxed selection>
                <List.Item onClick={() => this.props.onDrawShape('Point')}>
                    <Icon>
                        <FaRegDotCircle/>
                    </Icon>
                    <List.Content>
                        <List.Header>
                            {t('sidebar.tools.point')}
                        </List.Header>
                    </List.Content>
                </List.Item>
                {/* <List.Item onClick={() => this.props.onDrawShape('Square')}>
                    <Icon>
                        <FaVectorSquare/>
                    </Icon>
                    <List.Content>
                        <List.Header>
                            {t('sidebar.tools.square')}
                        </List.Header>
                    </List.Content>
                </List.Item> */}
                <List.Item onClick={() => this.props.onDrawShape('Rectangle')}>
                    <Icon>
                        <FaRegObjectUngroup/>
                    </Icon>
                    <List.Content>
                        <List.Header>
                            {t('sidebar.tools.rectangle')}
                        </List.Header>
                    </List.Content>
                </List.Item>
                <List.Item onClick={() => this.props.onDrawShape('Polygon')}>
                    <Icon>
                        <FaDrawPolygon/>
                    </Icon>
                    <List.Content>
                        <List.Header>
                            {t('sidebar.tools.polygon')}
                        </List.Header>
                    </List.Content>
                </List.Item>
                <List.Item disabled={!this.props.isAuthenticated} onClick={() => this.props.onDrawShape('Upload')} >
                    <Icon style={{'color': !this.props.isAuthenticated? 'lightgray':'black'}}>
                        <FaUpload/>
                    </Icon>
                    <List.Content>
                        <List.Header style={{'color': !this.props.isAuthenticated? 'lightgray':'black'}}>
                            {t('sidebar.tools.upload')}
                        </List.Header>
                    </List.Content>
                </List.Item>
            </List>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: !!state.user.token
    };
}

export default connect(mapStateToProps)(Tools);